/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { useSelector,connect } from "react-redux";

import BackButton from '../../components/BackButton/BackButton';
import styles from './AddVCBCPagePV.module.css';
// eslint-disable-next-line no-unused-vars
 import  VCBCAddFromPV  from '../../components/VCBCAddFromPV/VCBCAddFromPV';
 import StepBar1 from '../../components/StepBar1/StepBar';


import { CustomTab } from '../../atomicComponents';

function AddVCBCPagePV() {
  const baseUrl = useSelector((state) => state.counter.baseUrl); // useSelector

  const history = useHistory();
  const redirectToProcessPage = () => {
    history.push('/');
  };
  const [datesData, setDates] = useState({
    endDate:"",
    startDate:"",
  });
  useEffect(() => {
    getdate();
  }, []);
  const getdate = () => {
  
    const headerss = new Headers();
    headerss.append("Content-Type", "application/json");
    fetch(`${baseUrl}Milestone_calendar_PV_View/`, {
      method: "GET",
      headers: headerss,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const endDate = responseJson[0].Qualification_of_VCs_eligible_for_PLI_EndDate;
        const startDate = responseJson[0].Qualification_of_VCs_eligible_for_PLI_StartDate;
        setDates((prevData)=>({...prevData,endDate,startDate}));
       
      })
      .catch((error) => {});
  };

  return (
    <>
      <div className={styles.container} style={{ boxSizing: 'border-box' }}>
      <div className={styles.backButtonLastRefreshedDateWrapper}>
          <BackButton
            action="Home"
            handleClick={redirectToProcessPage}
          />
             <div className={styles.topBar} style={{}}>
                <div className={styles.divspan}>Qualification Start Date:<span>{datesData.startDate}</span></div>
                <div className={styles.divspan}>Qualification End Date:<span>{datesData.endDate}</span></div>
              </div>  
        </div>
        <div className={styles.processHeader}>
          <div style={{ width: '20%' }}>
            <CustomTab title="VC Declaration-PV" withOutCount isSelected />
          </div>
       
              <StepBar1 enable={2} />

    
        </div>
     
       <VCBCAddFromPV redirectToProcessPage={redirectToProcessPage}/>  

      </div>
    </>
  )
}


export default AddVCBCPagePV;
