import React from 'react';
import { SvgIcon } from '@material-ui/core';

const SortAscendingIcon = props => (
    <SvgIcon viewBox="0 0 16.395 14.345" {...props}>
      <path d="M7.685 2.05h2.049a.512.512 0 00.512-.512V.512A.512.512 0 009.734 0H7.685a.512.512 0 00-.512.512v1.025a.512.512 0 00.512.513zm0 4.1h4.1a.512.512 0 00.512-.512V4.611a.512.512 0 00-.512-.512h-4.1a.512.512 0 00-.512.512v1.025a.512.512 0 00.512.514zm8.2 6.148h-8.2a.512.512 0 00-.512.512v1.025a.512.512 0 00.512.512h8.2a.512.512 0 00.512-.512v-1.027a.512.512 0 00-.515-.512zm-8.2-2.048h6.148a.512.512 0 00.512-.512V8.71a.512.512 0 00-.512-.512H7.685a.512.512 0 00-.512.512v1.025a.512.512 0 00.512.515zm-2.049 0H4.1V.512A.512.512 0 003.588 0H2.562a.512.512 0 00-.512.512v9.738H.512a.512.512 0 00-.362.874l2.562 3.074a.512.512 0 00.724 0L6 11.121a.513.513 0 00-.364-.871z" />
    </SvgIcon>
  );

export default SortAscendingIcon;
