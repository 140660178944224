/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'

import {
  // Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // Form,
  // FormGroup,
  Grid,
  // IconButton,
  // Modal,
  // TextField
} from '@material-ui/core'
import WarningIcon from '@mui/icons-material/Warning'
import FormLabel from '@mui/material/FormLabel'
// import $ from 'jquery';
import clsx from 'clsx'
import * as FileSaver from 'file-saver'
import { useHistory } from 'react-router-dom'
import XLSX from 'sheetjs-style'
// import { TrendingUpOutlined } from '@material-ui/icons';
import { AuthChecker, Button, CustomTab } from '../../atomicComponents'
import BackButton from '../../components/BackButton/BackButton'
import { CustomFormGroup, CustomSelect } from '../../components/FormComponents'
import Table from '../../components/Table/Table'
import {
  // API_RESOURCE_URLS,
  // DATE_FORMAT,
  // DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  RESOURCE_TYPE,
  // Regex,
  // USER_OPERATIONS
} from '../../constants'
import { withAllowedOperationsProvider } from '../../hocs'
import styles from './VendorPartData.module.css'

import { usePopupManager } from '../../providers/PopupManager/PopupManager'
// import StepBar1 from '../../components/StepBar1/StepBar';

const VcBomData = () => {
  const history = useHistory()
  const { showPopup } = usePopupManager()
  // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector(state => state.counter.baseUrl) // useSelector
  const auth = useSelector(state => state.auth) // useSelector
  const { userRole, userID } = auth

  const [checkDisable, setDisable] = useState(true)
  // const [dynamicColumns, setDynamicColumns] = useState([]);
  // const [dynamicColumns1, setDynamicColumns1] = useState([]);
  const [plant, setPlant] = useState(null)
  const [flag, show] = useState(false)

  const [Bc, setBC] = useState(null)
  const [plantNo, setPlantNo] = useState(null)
  const [partNo, setPartNo] = useState(null)

  const [bcArray, setBcArray] = useState([])
  // const [plantArray, setPlantArray] = useState([]);
  const [tableArray, setTableArray] = useState([])
  const [loader, setLoader] = useState(true)
  const [asnCount, setAsnCount] = useState(0)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const handleOpen = () => setIsPopupOpen(true)
  const [datesData, setDates] = useState({
    endDate: '',
    startDate: ''
  })

  //
  const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false)
  const handleOpenFormModal = () => {
    setPopupOpenFormModal(true)
  }
  const handleCloseFormModal = () => setPopupOpenFormModal(false)

  //

  //
  const [reseVC, setReseVC] = useState(false)
  const handleOpenVCResetModal = () => {
    setReseVC(true)
  }
  const handleCloseVCResetModal = () => setReseVC(false)

  //

  const handleClose = () => setIsPopupOpen(false)
  // start
  const [selectedRow, setSelectedRow] = useState([])
  const handleOpen1 = selected => {
    setIsPopupOpen1(true)
    setSelectedRow(selected)
  }
  const [isPopupOpen1, setIsPopupOpen1] = useState(false)
  const handleClose1 = () => setIsPopupOpen1(false)
  const goNext1 = () => {
    actionBaseVC()
  }
  const actionBaseVC = () => {
    let ids = selectedRow.map(({ dbId }) => dbId)
    const remainingData = tableArray.filter(({ dbId }) => !ids.includes(dbId))
    setTableArray(remainingData)
    ids = ids.join().split(',')

    const apiFormatedData = {
      id: ids
    }

    var apiFormatedDataJson = JSON.stringify(apiFormatedData)
    console.log('apiFormatedDataJson>>>', apiFormatedDataJson)
    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    fetch(`${baseUrl}Show_Base_Vc_Data/`, {
      method: 'DELETE',
      // headers: headerss,
      body: apiFormatedDataJson
    })
      .then(response => response.json())
      .then(responseJson => {
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: 'Data is Succesfully Deleted'
        })
      })
      .catch(error => {})
  }

  // end

  const goNext = () => {
    uploadFileData()
  }

  const ROW_HEIGHT = 38
  const redirectToApplicationLandingPage = () => {
    history.push('/')
  }

  const setCount = count => {
    setAsnCount(count)
  }

  useEffect(() => {
    getVendorCodes()
    getData()
    getdate()
  }, [])

  const getVendorCodes = () => {
    let headerss = new Headers()
    // let { userRole, userID } = auth
    console.log('Bc? getData', Bc?.value)
    console.log('userRole in getData', userRole)
    headerss.append('Content-Type', 'application/json')
    // if(userRole.includes('Vendor')){
    //   console.log('inside vendor if');
    //   setBC(userID)
    // }
    fetch(`${baseUrl}Supplier_data_details_CV_for_TierI/`, {
      method: 'GET',
      headers: headerss
    })
      .then(response => response.json())
      .then(responseJson => {
        console.log('responseJson', responseJson)
        const ListArray = responseJson.map(({ Vendor_Code }) => ({
          value: Vendor_Code,
          label: Vendor_Code
        }))
        setBcArray(ListArray)
        // console.log('ListArray in response', ListArray);
        // console.log('userRole in response', userRole);
        // console.log('userRole userID in response', userID);
        // console.log('userRole auth', auth);

        if (
          userRole.includes('Vendor-CV') ||
          userRole.includes('Vendor-PV') ||
          userRole.includes('Vendor')
        ) {
          console.log('inside userRole if', userRole)

          const singleVendor = ListArray.filter(
            element => element.value == userID
          )
          console.log('singleVEndor', singleVendor)
          if (singleVendor.length >= 1) {
            console.log('singleVendor inside null check', singleVendor)
            setBC(singleVendor[0])
            console.log('Bc effect Bc', Bc)
            setDisable(true)
            show(true) // table show
            console.log('singleVendor', singleVendor[0].value)
            console.log('Bc before getTableData1 API call', Bc)
            // getTableData1(singleVendor[0].value)
          }
          // setBC(singleVendor[0].value)
        } else {
          setDisable(false)
        }
        console.log('Bc effect after', Bc)
      })
      .catch(error => {})
  }

  const getdate = () => {
    const headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    fetch(`${baseUrl}Milestone_calendarView/`, {
      method: 'GET',
      headers: headerss
    })
      .then(response => response.json())
      .then(responseJson => {
        const endDate = responseJson[0].Part_Details_correction_StartDate
        const startDate = responseJson[0].Part_Details_correction_EndDate
        setDates(prevData => ({ ...prevData, endDate, startDate }))
      })
      .catch(error => {})
  }
  const showTable = () => {
    getTableData('Yes')
    show(true)
  }

  const handleSelect = (selectedOption, name) => {
    if (name == 'baseVC') {
      show(false)

      setBC(selectedOption)
      // getPlantData(selectedOption)
    } else {
      setPlant(selectedOption)
    }

    if (name == 'VC') {
      show(false)
      setBC(selectedOption)
    } else if(name === 'plantNo') {
      setPlantNo(selectedOption)
    } else {
      setPartNo(selectedOption)
    }
  }

  const getTableData = flagParam => {
    const apiFormatedData = {
      VC: Bc.value
    }

    var apiFormatedDataJson = JSON.stringify(apiFormatedData)
    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    // Show_Base_Vc_Data_for_PV
    fetch(`${baseUrl}VendorPartWiseData/`, {
      method: 'POST',
      headers: headerss,
      body: apiFormatedDataJson
    })
      .then(response => response.json())
      .then(responseJson => {
        const tableData = responseJson.data
        const excelData = tableData.map(
          (
            {
              id,
              Plant_code,
              Part_number,
              Part_Description,
              Supplier_Code,
              Supplier_Name,
              AppliedForPLI,
              Part_UOM,
              GR_No,
              GR_date,
              SOB,
              ImportOrDomestic,
              Invoice_No,
              Invoice_Date,
              Supplementary_Invoice_No,
              Supplementary_Invoice_Date,
              Price_Per_Part_Regular_A,
              Price_Per_Part_Supplementary_B,
              Total_Part_Price_C,
              SPOC,
              CTL,
              Availing_PLI_Incentive,
              TA_certified_DVA_percentage,
              PLI_claim_effective_date,
              Invoice_Selling_price,
              Value_of_Direct_Import_by_us,
              Broad_Description_of_parts_imported_by_us,
              Value_of_parts_imported_by_our_suppliers,
              Broad_Description_of_parts_imported_by_our_suppliers,
              Local_Content,
              DVAPercentage,
              Currency_Name,
              Reference_Date,
              Foreign_Exchange_Rate
            },
            indexx
          ) => {
            let obj = {
              id: indexx,
              dbId: id,
              Plant_code,
              Part_number,
              Part_Description,
              Supplier_Code,
              Supplier_Name,
              AppliedForPLI,
              Part_UOM,
              GR_No,
              GR_date,
              SOB,
              ImportOrDomestic,
              Invoice_No,
              Invoice_Date,
              Supplementary_Invoice_No,
              Supplementary_Invoice_Date,
              Price_Per_Part_Regular_A,
              Price_Per_Part_Supplementary_B,
              Total_Part_Price_C,
              SPOC,
              CTL,
              Availing_PLI_Incentive,
              TA_certified_DVA_percentage,
              PLI_claim_effective_date,
              Invoice_Selling_price,
              Value_of_Direct_Import_by_us,
              Broad_Description_of_parts_imported_by_us,
              Value_of_parts_imported_by_our_suppliers,
              Broad_Description_of_parts_imported_by_our_suppliers,
              Local_Content,
              DVAPercentage,
              Currency_Name,
              Reference_Date,
              Foreign_Exchange_Rate
            }

            return obj
          }
        )
        setTableArray(excelData)
        if (flagParam == 'Yes') {
          //       exportExcel(excelData);
        }
        setLoader(false)
      })
      .catch(error => {})
  }

  const goNextForm = () => {
    var url
    var urlMethod

    // let { userRole, userID } = auth;

    if (userRole.includes('GDC')) {
      url = 'ColourVcMISProgress'
      urlMethod = 'POST'
    } else if (
      userRole.includes('Purchase') ||
      userRole.includes('Purchase-CV')
    ) {
      url = 'ColourVcMISProgress'
      urlMethod = 'PUT'
    } else {
      url = 'ColourVcMISProgress'
      urlMethod = 'POST'
    }
    const apiFormatedData = {
      VC: Bc.value
    }

    var apiFormatedDataJson = JSON.stringify(apiFormatedData)
    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    fetch(`${baseUrl}${url}/`, {
      method: urlMethod,
      headers: headerss,
      body: apiFormatedDataJson
    })
      .then(response => response.json())
      .then(responseJson => {
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: 'Data is Succesfully Saved'
        })
        history.push('/')
      })
      .catch(error => {})
  }

  const resetVCCall = () => {
    const apiFormatedData = {
      VC: Bc.value
    }

    var apiFormatedDataJson = JSON.stringify(apiFormatedData)
    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    fetch(`${baseUrl}Show_Base_Vc_Data/`, {
      method: 'PATCH',
      headers: headerss,
      body: apiFormatedDataJson
    })
      .then(response => response.json())
      .then(responseJson => {
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: 'Data is succesfully reset'
        })
      })
      .catch(error => {})
  }

  const getData = () => {
    const apiFormatedData = {
      BascVC: '',
      Mfg_plant: ''
    }

    var apiFormatedDataJson = JSON.stringify(apiFormatedData)
    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    fetch(`${baseUrl}BaseSheetVcNameData/`, {
      method: 'GET',
      headers: headerss
      //    body: apiFormatedDataJson
    })
      .then(response => response.json())
      .then(responseJson => {
        const ListArray = responseJson.map(({ Supplier_Code }) => ({
          value: Supplier_Code,
          label: Supplier_Code
        }))
        // setBcArray(ListArray)
      })
      .catch(error => {})
  }

  const uploadFileData = () => {
    var input = document.createElement('input')
    input.type = 'file'
    input.setAttribute(
      'accept',
      '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
    )
    input.click()
    input.onchange = e => {
      setLoader(true)
      show(true)

      const formData = new FormData()
      formData.append('file', e.target.files[0])
      fetch(`${baseUrl}Show_Base_Vc_Data/`, {
        method: 'PUT',
        body: formData
      })
        .then(response => {
          // eslint-disable-next-line radix
          let status1 = parseInt(response.status)
          if (status1 == '201') {
            //   document.removeChild(input);

            getTableData('No')
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: 'file uploaded sucessfully'
            })
          } else {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: 'file uploaded failed'
            })
          }
        })

        .catch(error => {})
    }
  }

  const exportExcel = async excelData => {
    var fileTypel =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    var fileExt = '.xlsx'
    const ws = XLSX.utils.json_to_sheet(excelData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileTypel })
    // FileSaver.saveAs(data, `${Bc.value}${fileExt}`);
    FileSaver.saveAs(data, `${Bc.value}${fileExt}`)
  }
  
  const filterExcel = async excelData => {
    var fileTypel =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    var fileExt = '.xlsx'
    const ws = XLSX.utils.json_to_sheet(excelData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileTypel })
    FileSaver.saveAs(data, `${Bc.value}${fileExt}`)
  }

  const baseDefaultColumns = [
    {
      width: 130,
      title: 'Plant Code',
      field: 'Plant_code',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Part Number',
      field: 'Part_number',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'Part Description',
      field: 'Part_Description',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Supplier Code',
      field: 'Supplier_Code',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'Supplier Name',
      field: 'Supplier_Name',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Applied For PLI(Y/N)',
      field: 'AppliedForPLI',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Part UOM',
      field: 'Part_UOM',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'GR No',
      field: 'GR_No',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'GR Date',
      field: 'GR_date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'SOB',
      field: 'SOB',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Import / Domestic',
      field: 'ImportOrDomestic',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Invoice No',
      field: 'Invoice_No',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Invoice Date',
      field: 'Invoice_Date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Supplementary Invoice No',
      field: 'Supplementary_Invoice_No',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Supplementary Invoice Date',
      field: 'Supplementary_Invoice_Date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Price/Part Regular A',
      field: 'Price_Per_Part_Regular_A',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Price/Part Supplementary B',
      field: 'Price_Per_Part_Supplementary_B',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Total Part Price C',
      field: 'Total_Part_Price_C',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'SPOC',
      field: 'SPOC',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'CTL',
      field: 'CTL',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Availing PLI Incentive',
      field: 'Availing_PLI_Incentive',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'TA certified DVA %',
      field: 'TA_certified_DVA_percentage',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'PLI claim effective date',
      field: 'PLI_claim_effective_date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Selling price',
      field: 'Invoice_Selling_price',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Value of Direct Import by us (in INR)',
      field: 'Value_of_Direct_Import_by_us',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Broad Description of parts imported by us',
      field: 'Broad_Description_of_parts_imported_by_us',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Value of parts imported by our suppliers (in INR)',
      field: 'Value_of_parts_imported_by_our_suppliers',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Broad Description of parts imported by our suppliers ',
      field: 'Broad_Description_of_parts_imported_by_our_suppliers',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Local Content',
      field: 'Local_Content',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'DVA %',
      field: 'DVAPercentage',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Currency Name',
      field: 'Currency_Name',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Reference Date',
      field: 'Reference_Date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Foreign Exchange Rate',
      field: 'Foreign_Exchange_Rate',
      enableSearch: true,
      enableFilter: true
    }
  ]

  const redirectToCreateProjectPage = () => history.push('/')
  const redirectToProjectEditPage = id => history.push(`/`)
  const redirectToProjectDetailsPage = id => history.push(`/`)

  // const renderHTML = () => (
  //   <div className={styles.formGroup}>
  //     <Grid
  //       container
  //       spacing={1}
  //       style={{
  //         display: 'flex',
  //         justifyContent: 'left',
  //         marginTop: '5px',
  //         marginBottom: '1px'
  //       }}
  //       columns={6}
  //     >
  //       <Grid item md={9} lg={9}>
  //         <div className={styles.rowDiv}>
  //           {/* <div className={clsx(styles.lableDiv)} style={{ width: '15%' }}>
  //             <label className={styles.label}>Vendor Code</label>
  //           </div> */}
  //           <div className={styles.formDiv}>
  //             {/* <CustomSelect
  //               name='bc'
  //               options={bcArray}
  //               className={clsx(styles.select, styles.sel1)}
  //               value={Bc}
  //               isMulti={false}
  //               style={{ width: '54%' }}
  //               //   isClearable
  //               onChange={e => {
  //                 handleSelect(e, 'baseVC')
  //               }}
  //             /> */}

  //             {/* <div className={styles.formContainer} style={{ display: 'flex' }}> */}
  //           {/* <div className={styles.formGroup}> */}

  //           {/* <div className={styles.formGroupRow}> */}

  //           <div className={styles.formRow}>
  //             <label className={styles.label}>Vendor Code</label>
  //             <CustomSelect
  //               name='vc'
  //               options={bcArray}
  //               // isDisabled={checkDisable}
  //               className={clsx(styles.select, styles.sel1)}
  //               value={Bc}
  //               // eslint-disable-line react/jsx-boolean-value
  //               // isMulti={true}
  //               isClearable
  //               onChange={e => {
  //                 handleSelect(e, 'VC')
  //               }}
  //             // markIfUnselected={}
  //             />
  //           </div>

  //           <div className={styles.formRow}>
  //             <label className={styles.label}>Plant</label>
  //             <CustomSelect
  //               name='plantNo'
  //               options={[
  //                 { value: '001', label: '001' },
  //                 { value: '002', label: '002' },
  //                 { value: '003', label: '003' },
  //                 { value: '004', label: '004' },
  //                 { value: '005', label: '005' },
  //                 { value: '006', label: '006' },
  //                 { value: '007', label: '007' }
  //               ]}
  //               className={clsx(styles.select, styles.sel1)}
  //               value={plantNo}
  //               isMulti={false}
  //               isClearable
  //               onChange={e => {
  //                 handleSelect(e, 'plantNo')
  //               }}
  //             />
  //           </div>

  //           {/* </div> */}
  //           {/* <div className={styles.formGroupRow}> */}

  //           {/* </div> */}
  //           {/* <div className={styles.formGroupRow}> */}
  //           <div className={styles.formRow}>
  //             <label className={styles.label}>Part No.</label>
  //             <CustomSelect
  //               name='partNo'
  //               options={[
  //                 { value: '100001', label: '100001' },
  //                 { value: '100002', label: '100002' },
  //                 { value: '100003', label: '100003' },
  //                 { value: '100004', label: '100004' },
  //                 { value: '100005', label: '100005' },
  //                 { value: '100006', label: '100006' },
  //                 { value: '100007', label: '100007' },
  //                 { value: '100008', label: '100008' },
  //                 { value: '100009', label: '100009' }
  //               ]}
  //               className={clsx(styles.select, styles.sel1)}
  //               value={partNo}
  //               isMulti={false}
  //               isClearable
  //               onChange={e => {
  //                 handleSelect(e, 'partNo')
  //               }}
  //             />
  //           </div>
  //           {/* <div className={styles.formRow}>
  //                 <label className={styles.label}>
  //                   Cut Of Date
  //                 </label>
  //                 <CustomDatePicker

  //                   // markIfUnselected={highlightMandatoryFields}
  //                   autoOk
  //                   inputVariant="outlined"
  //                   // value={invoiceDate}
  //                   // onChange={(e) => { handleDate(e, 'invoiceDate') }}
  //                   inputProps={{
  //                     className: clsx(styles.select, styles.dateInput, styles.sel1),

  //                   }}
  //                   emptyLabel="dd/mm/yyyy"

  //                   format="DD/MM/yyyy"
  //                   data-testid="date-picker-div-1"
  //                   style={{ width: '60%', padding: '10px 0px 10px 10px' }}
  //                   maxDate={moment().toDate()}
  //                 />

  //               </div> */}
  //           {/* </div> */}
  //           <div className={styles.formGroupRow}>
  //             <div
  //               className={styles.formRow}
  //               style={{ width: '35%', marginLeft: '20px' }}
  //             >
  //               <Button
  //                 className={clsx(
  //                   styles.actionButton,
  //                   styles.primaryActionButton
  //                 )}
  //                 variant='primary'
  //                 onClick={showTable}
  //                 // eslint-disable-line react/jsx-boolean-value
  //                 // eslint-disable-next-line no-unneeded-ternary
  //                 disabled={!Bc && !plantNo && !partNo && true}
  //               >
  //                 Search
  //               </Button>
  //             </div>
  //           {/* </div> */}
  //           {/* </div> */}
  //         </div>

  //             <div
  //               style={{ marginLeft: '5px', marginTop: '1px', display: 'flex' }}
  //             >
  //               <AuthChecker operation='search'>
  //                 {isAuthorized => (
  //                   <Button
  //                     className={clsx(
  //                       styles.actionButton,
  //                       styles.primaryActionButton
  //                     )}
  //                     variant='primary'
  //                     //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
  //                     onClick={showTable}
  //                     disabled={!isAuthorized}
  //                     data-testid='confirm-action'
  //                   >
  //                     Show Data
  //                   </Button>
  //                 )}
  //               </AuthChecker>

  //               {loader == false && (
  //                 <>
  //                   <AuthChecker operation='download'>
  //                     {isAuthorized => (
  //                       <Button
  //                         className={clsx(
  //                           styles.actionButton,
  //                           styles.primaryActionButton
  //                         )}
  //                         variant='primary'
  //                         //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
  //                         onClick={() => {
  //                           if (tableArray.length >= 1) {
  //                             exportExcel(tableArray)
  //                           }
  //                         }}
  //                         disabled={!isAuthorized}
  //                         data-testid='confirm-action'
  //                         style={{ marginLeft: '5px' }}
  //                       >
  //                         download
  //                       </Button>
  //                     )}
  //                   </AuthChecker>
  //                   <AuthChecker operation='search'>
  //                     {isAuthorized => (
  //                       <Button
  //                         //  className={clsx(styles.actionButton, styles.resetButton)}
  //                         //  variant="tertiary"
  //                         style={{ marginLeft: '5px' }}
  //                         className={clsx(
  //                           styles.actionButton,
  //                           styles.resetButton
  //                         )}
  //                         variant='tertiary'
  //                         onClick={handleOpenVCResetModal}
  //                         disabled={!isAuthorized}
  //                         data-testid='confirm-action'
  //                       >
  //                         reset
  //                       </Button>
  //                     )}
  //                   </AuthChecker>
  //                 </>
  //               )}
  //             </div>
  //           </div>
  //         </div>
  //       </Grid>
  //     </Grid>

  //     {/* <div className={styles.formGroup}>

  //       <div className={styles.formGroupRow}>
  //         <div className={styles.formRow}>
  //           <label className={styles.label}>
  //             VC
  //           </label>
  //           <CustomSelect
  //             name="bc"
  //             options={bcArray}
  //             className={clsx(styles.select, styles.sel1)}
  //             value={Bc}
  //             isMulti={false}
  //             isClearable
  //             onChange={(e) => { handleSelect(e, 'baseVC') }}

  //           />

  //         </div>
  //         <div className={styles.formRow}>
  //           <label className={styles.label}>
  //             <Button className={clsx(
  //               styles.actionButton,
  //               styles.primaryActionButton,
  //             )}
  //               variant="primary"
  //               onClick={showTable}>
  //               Get Information

  //             </Button>
  //           </label>

  //         </div> 
  //       </div> 
  //     </div> */}
  //   </div>
  // )

  return (
    <>
      <div className={styles.container} style={{ boxSizing: 'border-box' }}>
        <div>
          <div
            className={styles.backButtonLastRefreshedDateWrapper}
            style={{ marginTop: '10px' }}
          >
            <BackButton
              action='Back'
              handleClick={redirectToApplicationLandingPage}
              style={{ marginBottom: '0px' }}
            />
            {/* <div className={styles.topBar} style={{}}>
              <div className={styles.divspan}></div>
              <div className={styles.divspan}></div>
            </div> */}

            <div className={styles.topDiv}>
              <div className={styles.processHeader}>
                <div style={{ width: '100%' }}>
                  <CustomTab
                    title='Vendor Part Level Data (CV)'
                    withOutCount
                    isSelected
                  />
                </div>

                {/* <StepBar1 enable={4} /> */}
              </div>
            </div>
          </div>

          <div className={styles.formContainer} style={{ display: 'flex' }}>
            {/* <div className={styles.formGroup}> */}

            {/* <div className={styles.formGroupRow}> */}

            <div className={styles.formRow}>
              <label className={styles.label}>Vendor Code</label>
              <CustomSelect
                name='vc'
                options={bcArray}
                // isDisabled={checkDisable}
                className={clsx(styles.select, styles.sel1)}
                value={Bc}
                // eslint-disable-line react/jsx-boolean-value
                // isMulti={true}
                isClearable
                onChange={e => {
                  handleSelect(e, 'VC')
                }}
              // markIfUnselected={}
              />
            </div>

            <div className={styles.formRow}>
              <label className={styles.label}>Plant</label>
              <CustomSelect
                name='plantNo'
                options={[
                  { value: '001', label: '001' },
                  { value: '002', label: '002' },
                  { value: '003', label: '003' },
                  { value: '004', label: '004' },
                  { value: '005', label: '005' },
                  { value: '006', label: '006' },
                  { value: '007', label: '007' }
                ]}
                className={clsx(styles.select, styles.sel1)}
                value={plantNo}
                isMulti={false}
                isClearable
                onChange={e => {
                  handleSelect(e, 'plantNo')
                }}
              />
            </div>

            {/* </div> */}
            {/* <div className={styles.formGroupRow}> */}

            {/* </div> */}
            {/* <div className={styles.formGroupRow}> */}
            <div className={styles.formRow}>
              <label className={styles.label}>Part No.</label>
              <CustomSelect
                name='partNo'
                options={[
                  { value: '100001', label: '100001' },
                  { value: '100002', label: '100002' },
                  { value: '100003', label: '100003' },
                  { value: '100004', label: '100004' },
                  { value: '100005', label: '100005' },
                  { value: '100006', label: '100006' },
                  { value: '100007', label: '100007' },
                  { value: '100008', label: '100008' },
                  { value: '100009', label: '100009' }
                ]}
                className={clsx(styles.select, styles.sel1)}
                value={partNo}
                isMulti={false}
                isClearable
                onChange={e => {
                  handleSelect(e, 'partNo')
                }}
              />
            </div>
            {/* <div className={styles.formRow}>
                  <label className={styles.label}>
                    Cut Of Date
                  </label>
                  <CustomDatePicker

                    // markIfUnselected={highlightMandatoryFields}
                    autoOk
                    inputVariant="outlined"
                    // value={invoiceDate}
                    // onChange={(e) => { handleDate(e, 'invoiceDate') }}
                    inputProps={{
                      className: clsx(styles.select, styles.dateInput, styles.sel1),

                    }}
                    emptyLabel="dd/mm/yyyy"

                    format="DD/MM/yyyy"
                    data-testid="date-picker-div-1"
                    style={{ width: '60%', padding: '10px 0px 10px 10px' }}
                    maxDate={moment().toDate()}
                  />

                </div> */}
            {/* </div> */}
            <div className={styles.formGroupRow}>
              <div
                className={styles.formRow}
                style={{ width: '35%', marginLeft: '20px' }}
              >
                <Button
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  variant='primary'
                  onClick={showTable}
                  // eslint-disable-line react/jsx-boolean-value
                  // eslint-disable-next-line no-unneeded-ternary
                  disabled={!Bc && !plantNo && !partNo && true}
                >
                  Search
                </Button>
              </div>
            </div>
            {/* </div> */}
          </div>

        </div>

        {/* <div>
          <div className={styles.formContainer}>
            <CustomFormGroup body={renderHTML()} />
          </div>
        </div> */}
        {flag && (
          <div
            className={styles.tableView}
            style={{ height: '86%', padding: '0 2em' }}
          >
            <Table
              columns={[...baseDefaultColumns]}
              defaultColumns={[...baseDefaultColumns]}
              rowHeight={38}
              rows={tableArray}
              primaryAction={{
                name: 'Submit Data',
                authOperation: 'submit',
                shouldEnable: () => true,
                actionFn: handleOpenFormModal
              }}
              secondaryActions={[
                // {
                //   name: 'Download Data',
                //   authOperation: 'create-project',
                //   shouldEnable: selected => true,
                //   actionFn: selected => {},
                //   customClass:""
                //  customClass:"btnDelete"

                // },

                {
                  name: 'Upload Data',
                  authOperation: 'upload',
                  // shouldEnable: selected => selected.length === 1,
                  shouldEnable: () => true,
                  actionFn: selected => {
                    handleOpen()
                  },
                  customClass: ''
                  //  customClass:"btnDelete"
                },
                {
                  name: 'Delete',
                  authOperation: 'delete',
                  shouldEnable: selected => selected.length >= 1,
                  actionFn: selected => {
                    handleOpen1(selected)
                  },
                  customClass: 'btnDelete'
                }
              ]}
              setAsnCount={setCount}
              isDataLoading={loader}
              actionButtons
              onRowSelect={e => {}}
              // removeRowSelection
              filterDownload={filterExcel}
              filterDownloadBtn
            />
          </div>
        )}
      </div>
      <ResetModalVC
        isPopupOpen={reseVC}
        handleCloseVCModal={handleCloseVCResetModal}
        resetVCCall={resetVCCall}
      />

      <ResetModalF
        isPopupOpen={isPopupOpenFormModal}
        handleCloseFormModal={handleCloseFormModal}
        goNextForm={goNextForm}
      />
      <ResetModal
        isPopupOpen={isPopupOpen}
        handleClose={handleClose}
        goNext={goNext}
      />

      <ResetModal2
        isPopupOpen1={isPopupOpen1}
        handleClose1={handleClose1}
        goNext1={goNext1}
      />

      {/* <formModal
        isPopupOpen={isPopupOpenFormModal}
        handleCloseFormModal={handleCloseFormModal}
        goNextForm={goNextForm}

      /> */}
    </>
  )
}
function ResetModalVC ({ isPopupOpen, handleCloseVCModal, resetVCCall }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm Reset
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>
              On confirmation, Selected VC BoM will get reset and all changes
              made will be lost
            </p>

            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleCloseVCModal}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              handleCloseVCModal()
              resetVCCall()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function ResetModalF ({ isPopupOpen, handleCloseFormModal, goNextForm }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm Submit
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation ,VC BoM data will submit</p>

            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleCloseFormModal}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              handleCloseFormModal()
              goNextForm()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

function ResetModal ({ isPopupOpen, handleClose, goNext }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm file Upload
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation ,Records will be appended.</p>

            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              handleClose()
              goNext()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

function ResetModal2 ({ isPopupOpen1, handleClose1, goNext1 }) {
  return (
    <Dialog
      open={isPopupOpen1}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Selected data will be deleted
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p> On confirmation, Selected data will be deleted.</p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleClose1}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              goNext1()
              handleClose1()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired
}
ResetModalF.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleCloseFormModal: PropTypes.func.isRequired,
  goNextForm: PropTypes.func.isRequired
}
ResetModalVC.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleCloseVCModal: PropTypes.func.isRequired,
  resetVCCall: PropTypes.func.isRequired
}

ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired
}
ResetModal2.propTypes = {
  isPopupOpen1: PropTypes.bool.isRequired,
  handleClose1: PropTypes.func.isRequired,
  goNext1: PropTypes.func.isRequired
}

export default withAllowedOperationsProvider(VcBomData, RESOURCE_TYPE.PROJECT)
