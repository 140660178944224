/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
/* eslint-disable func-names */
/* eslint-disable one-var */
/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// for print user in consol
import jwt_decode from "jwt-decode";

// for print user in consol

import { useHistory } from "react-router-dom";
import Table from "../../components/Table/Table";
import styles from "./ColourVCMISPV.module.css"
import BackButton from "../../components/BackButton/BackButton";
import { CustomTab } from "../../atomicComponents";

import StepBar1 from "../../components/StepBar1/StepBar";


const ColourVcMISPV = () => {


  const history = useHistory();

  // const baseUrl = 'http://127.0.0.1:8000/file/'
  const baseUrl = useSelector((state) => state.counter.baseUrl); // useSelector

  const [tableArray, setTableArray] = useState([]);
  const [datesData, setDates] = useState({
    endDate: "",
    startDate: "",
  });

  const ROW_HEIGHT = 38;
  const redirectToApplicationLandingPage = () => {
    history.push("/");
  };

  useEffect(() => {
    getTableData();
    getdate();
  }, []);
  const getdate = () => {

    let headerss = new Headers();
    headerss.append("Content-Type", "application/json");
    fetch(`${baseUrl}Milestone_calendar_PV_View/`, {
      method: "GET",
      headers: headerss,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const endDate = responseJson[0].Part_Details_correction_EndDate;
        const startDate = responseJson[0].Part_Details_correction_StartDate;
        setDates((prevData) => ({ ...prevData, endDate, startDate }));

      })
      .catch((error) => { });
  };

  const getTableData = (param) => {
    let headerss = new Headers();
    headerss.append("Content-Type", "application/json");
    fetch(`${baseUrl}ShowColourVcMisDatePV/`, {
      method: "GET",
      headers: headerss,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const tableData = responseJson;
        const tableData1=   tableData.map((item,id)=>{
          let ColourVcValidationByTMLBSL;
          if(item.ColourVcValidationByTMLBSL==true)
          {
            ColourVcValidationByTMLBSL="Yes";
          } else {
            ColourVcValidationByTMLBSL="No";
          }
          let ColourVcValidationByPurchase;
          if(item.ColourVcValidationByPurchase==true)
          {
            ColourVcValidationByPurchase="Yes";
          } else {
            ColourVcValidationByPurchase="No";
          }
          let obj={
            id,
            VC:item.VC,
            ColourVcValidationByTMLBSL,
            ColourVcValidationByPurchase,
            ValidVcConfirmationBySparqplug:item.ValidVcConfirmationBySparqplug,
            Valid_VC:item.Valid_VC
          }
          return obj;

        });
        setTableArray(tableData1);
      })
      .catch((error) => { });
  };

  const baseDefaultColumns = [
    // {
    //   width: 130,
    //   title: "Product Id",
    //   field: "Product_Id",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 150,
      title: "Colour VC",
      field: "VC",
      enableSearch: true,
      enableFilter: true,
    },

    {
      width: 150,
      title: "Valid VC",
      field: "ValidVcConfirmationBySparqplug",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 200,
      title: "Validated By TMLBSL",
      field: "ColourVcValidationByTMLBSL",
      render: (row) => (row.ColourVcValidationByTMLBSL == "Yes" ? <div><span className={styles.colorYes}>{row.ColourVcValidationByTMLBSL}</span></div> : <span className={styles.colorNo}>{row.ColourVcValidationByTMLBSL}</span>),

      enableSearch: true,
      enableFilter: true,

    },
    {
      width: 200,
      title: "Validated By Purchaser",
      field: "ColourVcValidationByPurchase",
      render: (row) => (row.ColourVcValidationByPurchase == "Yes"? <div><span className={styles.colorYes}>{row.ColourVcValidationByPurchase}</span></div> : <span className={styles.colorNo}>{row.ColourVcValidationByPurchase}</span>),


      enableSearch: true,
      enableFilter: true,

    },
  ];


  return (
    <>
      <div className={styles.container} style={{ boxSizing: "border-box" }}>
        <div>
          <div className={styles.backButtonLastRefreshedDateWrapper}>
            <BackButton
              action="Back"
              handleClick={redirectToApplicationLandingPage}
            />
           <div className={styles.topBar} style={{}}>
                <div className={styles.divspan}>BoM Validation Start Date:<span>{datesData.startDate}</span></div>
                <div className={styles.divspan}>BoM Validation End Date:<span>{datesData.endDate}</span></div>
              </div>
          </div>
          <div className={styles.topDiv}>
            <div className={styles.processHeader}>
              <div style={{ width: "15%" }}>
                <CustomTab title="VC MIS PV" withOutCount isSelected />
              </div>
          
              <StepBar1 enable={7} />

            </div>
          </div>
        </div>

        <div
          className={styles.tableView}
          style={{ height: "86%", padding: "0 2em" }}
        >
          <Table
            columns={[...baseDefaultColumns]}
            defaultColumns={[...baseDefaultColumns]}
            rowHeight={38}
            rows={tableArray}
            isDataLoading={false}
            actionButtons
            onRowSelect={(e) => { }}
          />
        </div>
      </div>
    </>
  );
};

export default ColourVcMISPV;
