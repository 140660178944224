/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
/* eslint-disable func-names */
/* eslint-disable one-var */
/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { connect, useDispatch,useSelector } from 'react-redux'
import { Modal, Dialog, DialogTitle, DialogContent, DialogActions, Form, Box,TextField, Grid, FormGroup, IconButton } from '@material-ui/core';
import WarningIcon from '@mui/icons-material/Warning';
import PropTypes from 'prop-types';
import FormLabel from '@mui/material/FormLabel';

// for print user in consol
import jwt_decode from "jwt-decode";
import * as FileSaver from "file-saver"; 
import XLSX from "sheetjs-style"; 
// for print user in consol

import clsx from 'clsx'
import { useHistory } from 'react-router-dom';
import Table from '../../components/Table/Table';
import styles from './MHIDataPV.module.css';
import { CustomFormGroup, CustomSelect } from '../../components/FormComponents'
import BackButton from '../../components/BackButton/BackButton';
import { DATE_FORMAT, API_RESOURCE_URLS, DISPLAY_MESSAGES, MESSAGE_TYPE, RESOURCE_TYPE, USER_OPERATIONS, Regex } from '../../constants'
import { withAllowedOperationsProvider } from '../../hocs';
import { CustomTab, Button } from '../../atomicComponents';

import { usePopupManager } from '../../providers/PopupManager/PopupManager';

import StepBar1 from "../../components/StepBar1/StepBar";


const MHIDataPV = () => {


  const history = useHistory();
  const { showPopup } = usePopupManager();
  // const baseUrl = 'http://127.0.0.1:8000/file/'
  const baseUrl = useSelector(state => state.counter.baseUrl);  // useSelector


  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [dynamicColumns1, setDynamicColumns1] = useState([]);
  const [plant, setPlant] = useState(null);
  const [flag, show] = useState(false);
  const [Bc, setBC] = useState(null);
  const [bcArray, setBcArray] = useState([]);
  const [plantArray, setPlantArray] = useState([]);
  const [tableArray, setTableArray] = useState([]);
  const [asnCount, setAsnCount] = useState(0);
  const [status, setStatus] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false); 
  const [datesData, setDates] = useState({
    endDate:"",
    startDate:"",
  });
  const handleOpen = () => setIsPopupOpen(true);
  const handleClose = () => setIsPopupOpen(false);
  const goNext = () =>{
    uploadFileData()
  };

  const ROW_HEIGHT = 38;
  const redirectToApplicationLandingPage = () => {
    history.push('/');
  };

  const setCount = count => {
    setAsnCount(count);
  };

  useEffect(() => {
    getTableData();
    getdate();

  }, [])

  
  const getdate = () => {
  
    const headerss = new Headers();
    headerss.append("Content-Type", "application/json");
    fetch(`${baseUrl}Milestone_calendar_PV_View/`, {
      method: "GET",
      headers: headerss,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const endDate = responseJson[0].TML_DVA_publish_to_MHI_EndDate; 
        const startDate = responseJson[0].TML_DVA_publish_to_MHI_StartDate;
        setDates((prevData)=>({...prevData,endDate,startDate}));
       
      })
      .catch((error) => {});
  };

  const getTableData = (param) => {

    let headerss = new Headers();
    headerss.append('Content-Type', 'application/json');
    fetch(`${baseUrl}ShowMHIDataPV/`,

      {
        method: 'GET',
        headers: headerss,
      }).then((response) => response.json())
      .then((responseJson) => {
        const tableData = responseJson;
        setTableArray(tableData)

      })
      .catch((error) => {

      });

  };
  const getDataForExcel = (param) => {

    let headerss = new Headers();
    headerss.append('Content-Type', 'application/json');
    fetch(`${baseUrl}Add_Vc_for_PLI/`,
      {
        method: 'GET',
        headers: headerss,
      }).then((response) => response.json())
      .then((responseJson) => {

        exportExcel(responseJson)

      })
      .catch((error) => {

      });

  };


  const uploadFileData = () => {
    var input = document.createElement('input');
    input.type = "file";
    input.id = "file";
    input.setAttribute("accept", ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel");
    input.click();
    input.onchange = function (e) {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      fetch(`${baseUrl}Upload_Vc_list/`,
   //   fetch(`${baseUrl}Add_Vc_for_PLI/`,
        {
          method: 'POST',
          body: formData
        }).then((response) => {
          let status1 = parseInt(response.status)
          if (status1 =="201") {
         //   document.removeChild(input);
            getTableData();
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded sucessfully",

            });

          } else{
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded failed",

            });
          }
 

        })

        .catch((error) => {
        });

    }

  }

  const exportExcel = async (excelData) => {
    var fileTypel = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    var fileExt = ".xlsx"
    const ws = XLSX.utils.json_to_sheet(excelData)
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }; 
    const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileTypel });
    FileSaver.saveAs(data, `PLIDataCV${fileExt}`); 
  }


  const baseDefaultColumns = [
    {
      width: 130,
      title: 'Product Id',
      field: 'Product_Id',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 160,
      title: 'AAT Product Code',
      field: 'Att_Product_Code',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 160,
      title: 'Product Description',
      field: 'Product_Description',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: 'Model',
      field: 'Model',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: 'Variant',
      field: 'Variant',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: 'Plant code',
      field: 'Plant_code',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: 'Colour VC',
      field: 'colour_vc',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: 'Colour VC Desc ',
      field: 'Colour_vc_discription',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 180,
      title: 'DVA Percentage',
      field: 'DVA_Percentage',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: 'Total DVA',
      field: 'Total_DVA',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 180,
      title: 'EX Factory Cost',
      field: 'EX_factory_Cost',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: 'Quantity',
      field: 'Quantity',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: 'Batch',
      field: 'Batch',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 180,
      title: 'MFG Entity Cin',
      field: 'mfg_entity_cin',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: 'Unit of Quantity',
      field: 'unit_of_quantity',
      enableSearch: true,
      enableFilter: true,
    },



  ];


  const redirectToCreateProjectPage = () => history.push('/');
  const redirectToProjectEditPage = (id) => history.push(`/`);
  const redirectToProjectDetailsPage = (id) => history.push(`/`);

  const actionBaseVC = (selected) => {
    const ids = selected.map(({ id }) => (id));
    const apiFormatedData = {
      id: ids,
    }
    var apiFormatedDataJson = JSON.stringify(apiFormatedData);
    let headerss = new Headers();
    headerss.append('Content-Type', 'application/json');
    fetch(`${baseUrl}select_vc/`,
      {
        method: 'PUT',
        // headers: headerss,
        body: apiFormatedDataJson
      }).then((response) => response.json())
      .then((responseJson) => {
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: responseJson.status,

        });
      })
      .catch((error) => {

      });

  };




  return (
    <>
      <div className={styles.container} style={{ boxSizing: 'border-box' }}>
        <div>
          <div className={styles.backButtonLastRefreshedDateWrapper}>
            <BackButton
              action="Back"
              handleClick={redirectToApplicationLandingPage}
            />
             <div className={styles.topBar} style={{}}>
                <div className={styles.divspan}>TML DVA Publish to MHI Start Date:<span>{datesData.startDate}</span></div>
                <div className={styles.divspan}>TML DVA Publish to MHI End Date:<span>{datesData.endDate}</span></div>
              </div>  
          </div>
          <div className={styles.topDiv}>
            <div className={styles.processHeader}>
              <div style={{ width: '15%' }}>
                <CustomTab title="MHI Data (PV)" withOutCount isSelected />
              </div>
         
              <StepBar1 enable={10} />
            </div>
          </div>
        </div>



        <div
          className={styles.tableView}
          style={{ height: '86%', padding: '0 2em' }}
        >


          <Table
            columns={[...baseDefaultColumns]}
            defaultColumns={[...baseDefaultColumns]}
            rowHeight={38}
            rows={tableArray}

            primaryAction={
              {
                name: 'Submit & Publish to MHI',
                authOperation: 'submitMHI',
                shouldEnable: () => true,
                // actionFn: redirectToCreateProjectPage,
                actionFn: selected => {  },

              }


            }

            secondaryActions={[ 
              {
                name: 'submit-level-1',
                authOperation: 'submit-1',
                shouldEnable: selected => true,
                actionFn: selected => {  },
                customClass: ""

              },
              {
                name: 'submit-level-2',
                authOperation: 'submit-2',
                shouldEnable: selected => true,
                actionFn: selected => { },
                customClass: ""

              }
        
            ]}
            setAsnCount={setCount}
            isDataLoading={false}
            actionButtons
            onRowSelect={(e) => { }}
            removeRowSelection
          />
        </div>

      </div>
      <ResetModal
        isPopupOpen={isPopupOpen}
        handleClose={handleClose}
        goNext={goNext}
      />
    </>)
}
function ResetModal({ isPopupOpen, handleClose, goNext }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{marginLeft:"8px"}} className={styles.txt}>Confirm file Upload</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation ,Records will be appended.</p>
            <p>Are you sure ?</p>

          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleClose}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleClose()
              goNext()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired
}

export default withAllowedOperationsProvider(
  MHIDataPV,
  RESOURCE_TYPE.PROJECT
);