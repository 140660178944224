/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux'

import BackButton from '../../components/BackButton/BackButton';
import styles from './AuditReport.module.css';
// import  ChassisTransactionForm  from '../../components/ChassisTransactionForm/ChassisTransactionForm';
import  Audit  from '../../components/Audit/Audit';
import StepBar1 from "../../components/StepBar1/StepBar";


import { CustomTab } from '../../atomicComponents';

function AuditReport({product}) {
  const history = useHistory();
  const redirectToProcessPage = () => {
    history.push('/');
  };
const chassis=product.chassisData.chassisDetails.chs_no
const materialNo = product.chassisData.chassisDetails.chs_stm_no;

  return (
    <>
      <div className={styles.container} style={{ boxSizing: 'border-box' }}>
      <div className={styles.backButtonLastRefreshedDateWrapper}>
          <BackButton
            action="Home"
            handleClick={redirectToProcessPage}
          />
        </div>
        <div className={styles.processHeader}>
          <div style={{ width: '15%' }}>
            <CustomTab title="Audit Report-CV" withOutCount isSelected />
          </div>
          <StepBar1 enable={11} />

     
        </div>
     
         <Audit redirectToProcessPage={redirectToProcessPage}/> 

      </div>
    </>
  )
}
const mapStateToProps = state => ({
  product: state.product,
  counter: state.counter.value,
  baseUrl: state.counter.baseUrl,
  auth: state.auth
});
// eslint-disable-next-line no-unused-vars
const mapDispatchToProps = (dispatch) => ({

});
AuditReport.propTypes = {

  product: PropTypes.object,

};
export default connect(mapStateToProps, mapDispatchToProps)(AuditReport); 
