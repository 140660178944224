import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    '.makeStyles-root-26 .Mui-disabled': {
      
    },
    overflowY: 'auto',
    'scrollbar-width': 'thin', // Adjust the scrollbar width for Firefox
    // '-moz-scrollbar-width': '5px', // Older Firefox versions
    // '-moz-scrollbar-color': '#ccc transparent',
    "& .MuiOutlinedInput-root": {
      background: '#fff',
      maxHeight: '40px',
    },
    '&::-webkit-scrollbar': {
      width: '5px', // Adjust the width of the scrollbar for WebKit browsers
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E9DEAE"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E9DEAE"
    },
    '&	.Mui-disabled': {
      backgroundColor: "#f3f5f9"
    },
    // position: 'relative',
    // '&::after': {
    //   content: '""',
    //   position: 'absolute',
    //   height: '80%',
    //   width: '1px',
    //   background: '#d5d5d5',  // Adjust the color of the vertical line
    //   right: 35,
    //   top: 4,
    // },
    // scrollableInput: {
    //   maxHeight: '100px', // Set the maximum height before scrolling starts
    //   overflowY: 'auto', // Enable vertical scrolling
    // },
  }
})

const ValidatingTextFieldNoScrollNSymbol = ({
  isDisabled,
  isMandatory,
  markIfEmpty,
  validationFn,
  validationHelperText,
  value,
  resetAllVisitedFields,
  type,
  ...props
}) => {
  const classes = useStyles();

  const [isVisited, setIsVisited] = useState(false);
  const shouldMarkRed =
    ((isMandatory && isVisited) || markIfEmpty) && !value && !isDisabled;
  const isValueValid = validationFn && value ? validationFn(value) : true;

  useEffect(() => {
    if (resetAllVisitedFields && isVisited)
      setIsVisited(false);
  }, [resetAllVisitedFields, isVisited])

  return (
    <TextField
      onBlur={() => setIsVisited(true)}
      error={shouldMarkRed || !isValueValid}
      helperText={!isValueValid ? validationHelperText : null}
      value={value}
      {...props}
      className={classes.root}
      disabled={isDisabled}
      type={type}
    />
  );
};

ValidatingTextFieldNoScrollNSymbol.propTypes = {
  isDisabled: PropTypes.bool,
  isMandatory: PropTypes.bool,
  markIfEmpty: PropTypes.bool,
  validationFn: PropTypes.func.isRequired,
  validationHelperText: PropTypes.string,
  value: PropTypes.string,
  resetAllVisitedFields: PropTypes.bool,
  type: PropTypes.string
};

export default ValidatingTextFieldNoScrollNSymbol;
