
// Action Types
export const INCREMENT_COUNTER = "INCREMENT_COUNTER";
export const DECREMENT_COUNTER = "DECREMENT_COUNTER";
export const STEP1 = "STEP1";
export const STEP2 = "STEP2";
export const STEP3 = "STEP3"; 
export const STEP4 = "STEP4"; 
export const STEP5 = "STEP5"; 
export const SETID = "SETID"; 
export const OFFBOAED = "OFFBOAED"; 
export const REJECTLIST = "REJECTLIST"; 
export const COUNTER = "COUNTER"; 
export const BOXCOUNTER = "COUNTER"; 
export const MANAGERCOUNTER = "MANAGERCOUNTER"; 

export const setId = (data) => ({
    type: SETID,
    payload:{
        requestId:data.requestId,
  
     
    } 
});


// export const STEP4 = "STEP5"; 

// Action Creator
export const incrementCounter = () => ({
   type: INCREMENT_COUNTER
});

export const decrementCounter = () => ({
    type: DECREMENT_COUNTER
});
export const step1 = (data) => ({
    type: STEP1,
    payload:{
        taskIstanceIdStep1:data.taskId,
        processIstanceIdStep1:data.processId,
        step:data.step,
        step1Validate:data.step1Validate, 
        step1Data:data.step1Data,
     
    } 
});
export const step2 = (data) => ({
    type: STEP2,
    payload:{
        taskIstanceIdStep2:data.taskId,
        processIstanceIdStep2:data.processId,
        step:data.step,
        step2Data:data.step2Data,
    } 
});
export const step3 = (data) => ({
    type: STEP3,
    payload:{
        taskIstanceIdStep3:data.taskId,
        processIstanceIdStep3:data.processId,
        step:data.step,
        step3Data:data.step3Data,

        
    } 
});
export const step4 = (data) => ({
    type: STEP4,
    payload:{
        taskIstanceIdStep4:data.taskId,
        processIstanceIdStep4:data.processId,
        step:data.step,
        step4Data:data.step4Data,

        
    } 
});
export const step5 = (data) => ({
    type: STEP5,
    payload:{
        taskIstanceIdStep5:data.taskId,
        processIstanceIdStep5:data.processId,
        step:data.step,
        step5Data:data.step5Data,

        
    } 
});

export const getOffBoardingList = (data) => ({
    type: OFFBOAED,
    payload:{
        dataArray:data.dataArray,  
    } 
});
export const rejectedList = (data) => ({
    type: REJECTLIST,
    payload:{
        dataArray:data.dataArray,  
    } 
});
export const AllCounterr = (data) => ({
    type: COUNTER,
    payload:{
        dataArray:data.dataArray,  
    } 
});
export const dashboardBoxCount = (data) => ({
    type: BOXCOUNTER,
    payload:{
        dataArray:data.countData,  
    } 
});

export const managerBoxCount = (data) => ({
    type: MANAGERCOUNTER,
    payload:{
        dataArray:data.countData,  
    } 
});
