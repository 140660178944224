/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable import/named */
/* eslint-disable prefer-template */
/* eslint-disable one-var */
/* eslint-disable radix */
/* eslint-disable no-else-return */
/* eslint-disable no-shadow */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-var */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable object-shorthand */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable prefer-const */
/* eslint-disable dot-notation */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useReducer } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import FileBase64 from 'react-file-base64';


import PropTypes from 'prop-types';
import clsx from 'clsx'
import moment from 'moment';
// import {  } from "@material-ui/core";
import { Modal, Dialog, DialogTitle, DialogContent, DialogActions, Form, Box, TextField, Grid, FormGroup, IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import { SearchOutlined, Update } from '@material-ui/icons';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ClearIcon from '@mui/icons-material/Clear';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormGroup1 from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

import Select from '@mui/material/Select';
import { MdReplay, MdSystemUpdate } from 'react-icons/md';
import styles from './VCBCAddFrom.module.css'
import { CustomDatePicker, CustomFormGroup, CustomSelect, ValidatingTextField } from '../FormComponents'
import { DATE_FORMAT, API_RESOURCE_URLS, DISPLAY_MESSAGES, MESSAGE_TYPE, RESOURCE_TYPE, USER_OPERATIONS, Regex } from '../../constants'
import { API } from '../../apis/api'
import TextIcon from './TextIcon'
import { buildErrorMessage } from '../../apis/calls'
import { usePopupManager } from '../../providers/PopupManager/PopupManager';
import { AuthChecker, Button } from '../../atomicComponents';
import { withAllowedOperationsProvider } from '../../hocs'
// import { setToast, setPORow, poSearch, fetchChassis, setChassisData, setAllDetails, getInvoice } from '../../redux/actions/productsActions';



const VCBCAddFrom=({ redirectToProcessPage, product, counter, })=> {
  const ROW_HEIGHT = 38;


  const { showInternalError } = usePopupManager();
  const { showPopup } = usePopupManager();
  const dispatch1 = useDispatch();
  const history = useHistory();
  // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector(state => state.counter.baseUrl);  // useSelector
  const auth = useSelector((state) => state.auth); // useSelector






  const [asnCount, setAsnCount] = useState(0);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [confirmModalFlag2, setConfirmModal2] = useState(false);
  const [resetAllVisitedFields, setResetAllVisitedFields] = useState(false);
  const [highlightMandatoryFields, setHighlightMandatoryFields] = useState(false);



  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [transporterListArray, setTransporterListArray] = useState([]);

  const [myState, setState] = React.useState(
    {

      producId: "",
      attProductCode: "",
      productDescription: "",
      model: "",
      variant: "",
      plantCode: null,
      colourVc: "",
      colourVcDiscription: "",
      batch:""

    });





  const resetError = (name) => {
    if (name == "PONo") {
      setState((prevData) => ({ ...prevData, checkPOError: false }));
    }
    else if (name == "vcbcNo") {
      setState((prevData) => ({ ...prevData, checkVCError: false }));
    }

    else if (name == "targetModalDate") {
      setState((prevData) => ({ ...prevData, checkDateError: false }));
    }


  }
  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    // alert(name)
    resetError(name);
    setState((prevData) => ({
      ...prevData, [name]: value
    }));



  }

  const handleDate = (date, name) => {

    setInvoiceDate(date.format(DATE_FORMAT.ISO))
  }
  const handleSelect = (selectedOption, name) => {
    if (selectedOption == null) {
      setState((prevData) => ({
        ...prevData,
        [name]: null
      }));
      return;

    }
    setState((prevData) => ({
      ...prevData,
      [name]: selectedOption
    }));

  };



  const handlePrompt = (param) => {
    handleDeleteParam(param)
    handleOpen1();

  };
  const handlePrompt2 = (param) => {
    handleDeleteParam2(param)
    handleOpen2();

  };


  const handlepopup = (message) =>{
    showPopup({
      type: MESSAGE_TYPE.FAILURE,
      contextText: MESSAGE_TYPE.FAILURE,
      info: message,
  
    });
   }
  const handleSubmit = () => {

    let producId = myState.producId;
    let attProductCode = myState.attProductCode;
    if(myState.batch == "" ||myState.producId == "" || myState.productDescription == "" || myState.model== "" || myState.variant== "" || myState.plantCode == null || myState.colourVc =="" ) {
      handlepopup("Please fill the required Fields*");
        return false
        
    
      }
    let productDescription = myState.productDescription;
    let model = myState.model;
    let variant = myState.variant;
    let plantCode = myState.plantCode.value;
    let colourVc = myState.colourVc;
    let colourVcDiscription = myState.colourVcDiscription;
    let Batch = myState.batch;

    let apiFormatedData = {
      "userID": auth.userID,
      "email": auth.email,
      "id": 3,
      "Product_Id": producId,
      "Att_Product_Code": attProductCode,
      "Product_Description": productDescription,
      "Model": model,
      "Variant": variant,
      "Plant_code": plantCode,
      "colour_vc": colourVc,
      "Colour_vc_discription": colourVcDiscription,
      "Batch": Batch,


    }

    var apiFormatedDataJson = JSON.stringify(apiFormatedData);
    console.log("final", apiFormatedDataJson);
    let headerss = new Headers();
    headerss.append('Content-Type', 'application/json');
    fetch(`${baseUrl}Add_Vc_for_PLI/`,
      {
        method: 'POST',
        headers: headerss,
        body: apiFormatedDataJson
      }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson) {
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: responseJson.status,

          });

          history.push(`/vcbc-list-cv`);

        } else {

          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: "Internal Server Error",

          });
        }
      })
      .catch((error) => {

        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: "Something went wrong",

        });
      });

  };






  const onHover = (name) => {
    setHover({ isHover: true, name: name });
  };

  const onLeave = () => {
    setHover({ isHover: false, name: null });
  };

  const BG_COLOR = '#fffef8';
  const [backgroundColors, setBackgroundColors] = useState({
    processInfoBg: true,
    supplierInfoBg: false,
    poInfoBg: false,
    projectsInfoBg: false
  });
  const { processInfoBg, supplierInfoBg, poInfoBg, projectsInfoBg } = backgroundColors;

  const changeBackgroundColor = (field) =>
    setBackgroundColors(prev => {
      const newBackgroundColors = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(prev)) {
        if (key === field)
          newBackgroundColors[key] = true;
        else
          newBackgroundColors[key] = false;
      }
      return newBackgroundColors
    })



  const handleOpen2 = () => {
    setConfirmModal2(true);


  };
  const handleClose2 = () => setConfirmModal2(false);
  const resetFields = () => setResetAllVisitedFields(true);







  const processPoList = poList => {
    const processedPoList = poList.map(({ ...po }) => {
      const plantName = po.plant.name;
      const partDesc = po.part.description;
      const id = po.number;
      const processCreationDate = moment(po.createdAt).format("DD/MM/YYYY");
      return {
        ...po,
        id,
        plantName,
        partDesc,
        processCreationDate
      }
    });
    return processedPoList;
  }

  const loadPO = async () => {
    try {
      setPOData(prev => ({
        ...prev,
        loading: true,
      }));

      const response = await API.get(
        API_RESOURCE_URLS.PURCHASE_ORDER,
        {
          params:
          {
            supplierId: supplierDetails ? supplierDetails.id : null,
            partNumber: part ? part.value : null,
            plantCode: plant ? plant.value : null,
            page: poData.number,
          }
        }
      );
      const processedPoList = processPoList(response.data.content);

      setPOData(prev => ({
        ...prev,
        data: [...prev.data, ...processedPoList],
        filteredData: [...prev.filteredData, ...processedPoList],
        loading: false,
        last: response.data.last
      })
      );

    } catch (error) {
      showInternalError(error);
      console.error(buildErrorMessage(error));
      setPOData({
        number: 0,
        data: [],
        filteredData: [],
        loading: false,
        last: false
      });
    }
  };

  const handleValidation = (event, field) => {
    let err = errors;
    const { value } = event.target;

    switch (field) {
      case 'purchaseBuyerName':
        err['purchaseBuyerName'] = value ? RegExp(Regex.name).test(value) ? '' : 'Name is invalid' : 'Name is required';
        break;

      case 'suppRepresentativeName':
        err['suppRepresentativeName'] = value ? RegExp(Regex.name).test(value) ? '' : 'Name is invalid' : 'Name is required';
        break;

      case 'suppRepresentativeEmail':
        err['suppRepresentativeEmail'] = value ? RegExp(Regex.email).test(value) ? '' : 'Email is invalid' : 'Email is required';
        break;

      case 'suppRepresentativeContact':
        err['suppRepresentativeContact'] = value ? RegExp(Regex.phone).test(value) ? '' : 'Contact is invalid' : 'Contact is required';
        break;

      default:
        break;
    }
    dispatch({ type: 'update', field: 'errors', value: err });
  }





  const renderOther = () => (
    <div
      className={styles.formGroup}
    >

      <Grid
        container
        spacing={1}

        style={{ display: 'flex', justifyContent: "left", marginTop: "-14px" }}
        columns={12}
      >
        <Grid item md={4} lg={4}>
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)}>
              <label>Product ID<span style={{color:'red'}}>*</span></label>
            </div>
            <div className={styles.formDiv}>

              <ValidatingTextField
                variant="outlined"
                size="small"
                fullWidth
                value={myState.producId}
                onChange={onChangeHandler}
                name="producId"
                // placeholder="Enter Product ID"
                inputProps={{
                  "data-testid": "remark-input",

                }}
                style={{ width: '100%' }}

              />
            </div>
          </div>


        </Grid>
        {/* <Grid item md={4} lg={4}>
        <div className={styles.rowDiv}>
          <div className={clsx(styles.lableDiv)}>
            <label>Invoice Date</label>
          </div>
          <div className={styles.formDiv}>

          <CustomDatePicker
            isDisabled={myState.invoiceDateStatus}

            // markIfUnselected={highlightMandatoryFields}
            autoOk
            inputVariant="outlined"
            value={invoiceDate}
            onChange={(e) => { handleDate(e, 'invoiceDate') }}
            inputProps={{
              className: clsx(styles.select, styles.dateInput),

            }}
            emptyLabel="dd/mm/yyyy"

            format="DD/MM/yyyy"
            data-testid="date-picker-div-1"
            style={{ width: "100%" }}
            maxDate={moment().toDate()}
          />
          </div>
        </div>
      </Grid> */}


      </Grid>
      <Grid
        container
        spacing={1}

        style={{ display: 'flex', justifyContent: "left", marginTop: "10px" }}
        columns={12}
      >
        <Grid item md={4} lg={4}>
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)}>
              <label>AAT Product Code<span style={{color:'red'}}>*</span></label>
            </div>
            <div className={styles.formDiv}>

              <ValidatingTextField
                variant="outlined"
                size="small"
                fullWidth
                multiline

                onChange={onChangeHandler}

                name="attProductCode"
                value={myState.attProductCode}
                // placeholder="Enter ATT Product Code "
                inputProps={{
                  "data-testid": "remark-input",

                }}
                style={{ width: '100%' }}

              />
            </div>
          </div>
        </Grid>



      </Grid>
      <Grid
        container
        spacing={1}

        style={{ display: 'flex', justifyContent: "left", marginTop: "10px" }}
        columns={12}
      >

        <Grid item md={4} lg={4}>
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)}>
              <label>Product Description<span style={{color:'red'}}>*</span></label>
            </div>
            <div className={styles.formDiv}>

              <ValidatingTextField
                variant="outlined"
                size="small"
                fullWidth
                multiline
                onChange={onChangeHandler}
                name="productDescription"
                value={myState.productDescription}
                placeholder=""
                inputProps={{
                  "data-testid": "remark-input",

                }}
                style={{ width: '100%' }}

              />
            </div>
          </div>
        </Grid>


      </Grid>
      <Grid
        container
        spacing={1}

        style={{ display: 'flex', justifyContent: "left", marginTop: "10px" }}
        columns={12}
      >

        <Grid item md={4} lg={4}>
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)}>
              <label>Model<span style={{color:'red'}}>*</span></label>
            </div>
            <div className={styles.formDiv}>

              <ValidatingTextField
                variant="outlined"
                size="small"
                fullWidth
                multiline
                onChange={onChangeHandler}

                name="model"
                value={myState.model}
                placeholder=""
                inputProps={{
                  "data-testid": "remark-input",

                }}
                style={{ width: '100%' }}

              />
            </div>
          </div>
        </Grid>


      </Grid>
      <Grid
        container
        spacing={1}

        style={{ display: 'flex', justifyContent: "left", marginTop: "10px" }}
        columns={12}
      >

        <Grid item md={4} lg={4}>
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)}>
              <label>Variant<span style={{color:'red'}}>*</span></label>
            </div>
            <div className={styles.formDiv}>
              <ValidatingTextField
                variant="outlined"
                size="small"
                fullWidth
                multiline
                onChange={onChangeHandler}

                name="variant"
                value={myState.variant}
                placeholder=""
                inputProps={{
                  "data-testid": "remark-input",

                }}
                style={{ width: '100%' }}

              />

            </div>
          </div>
        </Grid>


      </Grid>



      <Grid
        container
        spacing={1}

        style={{ display: 'flex', justifyContent: "left", marginTop: "10px" }}
        columns={12}
      >

        <Grid item md={4} lg={4}>
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)}>
              <label>Plant code<span style={{color:'red'}}>*</span></label>
            </div>
            <div className={styles.formDiv}>


              <CustomSelect
                isMandatory
                // markIfUnselected={highlightMandatoryFields}
                // resetAllVisitedFields={resetAllVisitedFields}
                className={clsx(styles.select, styles.sel2)}
                isMulti={false}
                isClearable
                value={myState.plantCode}
                options={[
                  { value: "3001", label: "3001" },
                  { value: "1500", label: "1500" },
                  { value: "1001", label: "1001" },
                  { value: "3100", label: "3100" }
                ]}
                onChange={(e) => { handleSelect(e, 'plantCode') }}
                name="plantCode"
              />
            </div>
          </div>
        </Grid>


      </Grid>
      <Grid
        container
        spacing={1}

        style={{ display: 'flex', justifyContent: "left", marginTop: "10px" }}
        columns={12}
      >

        <Grid item md={4} lg={4}>
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)}>
              <label>VC<span style={{color:'red'}}>*</span></label>
            </div>
            <div className={styles.formDiv}>

              <ValidatingTextField
                variant="outlined"
                size="small"
                fullWidth
                multiline
                onChange={onChangeHandler}

                name="colourVc"
                value={myState.colourVc}
                placeholder=""
                inputProps={{
                  "data-testid": "remark-input",

                }}
                style={{ width: '100%' }}

              />
            </div>
          </div>
        </Grid>


      </Grid>
      <Grid
        container
        spacing={1}

        style={{ display: 'flex', justifyContent: "left", marginTop: "10px" }}
        columns={12}
      >

        <Grid item md={4} lg={4}>
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)}>
              <label>Batch<span style={{color:'red'}}>*</span></label>
            </div>
            <div className={styles.formDiv}>

              <ValidatingTextField
                variant="outlined"
                size="small"
                fullWidth
                multiline
                onChange={onChangeHandler}

                name="batch"
                value={myState.batch}
                placeholder=""
                inputProps={{
                  "data-testid": "remark-input",

                }}
                style={{ width: '100%' }}

              />
            </div>
          </div>
        </Grid>


      </Grid>
      <Grid
        container
        spacing={1}

        style={{ display: 'flex', justifyContent: "left", marginTop: "10px" }}
        columns={12}
      >

        <Grid item md={4} lg={4}>
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)}>
              <label>VC Desc</label>
            </div>
            <div className={styles.formDiv}>

              <ValidatingTextField
                variant="outlined"
                size="small"
                fullWidth
                multiline
                rows={2}
                onChange={onChangeHandler}

                name="colourVcDiscription"
                value={myState.colourVcDiscription}
                placeholder=""
                inputProps={{
                  "data-testid": "remark-input",

                }}
                style={{ width: '100%' }}

              />
            </div>
          </div>
        </Grid>


      </Grid>









    </div>
  )





  const getSelectedFields = () => ({
    projectId: selectedProject,
    poNumber: selectedPO,
    commodityGroupId: purchaseCommodity && purchaseCommodity.value,
    aqCommodityGroupId: aqCommodity && aqCommodity.value,
    purchaseBuyerName: inputFields?.purchaseBuyerName,
    kamContactDetails: {
      // id: inputFields,
      name: inputFields?.suppRepresentativeName,
      contactNumber: inputFields?.suppRepresentativeContact,
      emailId: inputFields?.suppRepresentativeEmail
    }
  });

  const validateFields = (fields) => {
    const requiredFields = { ...fields, ...fields.kamContactDetails }
    const hasMissingRequiredFields = Object.values(requiredFields).some(
      (field) => !field
    );

    const hasError = Object.values(errors).some((err) => err);

    if (hasMissingRequiredFields || hasError) {
      return false;
    }
    return true;
  };

  const runPreSubmissionChecks = () => {
    const selectedFields = getSelectedFields();
    console.log("🚀 ~ file: ChassisManufacturingForm.js ~ line 992 ~ runPreSubmissionChecks ~ selectedFields", selectedFields)
    const areFieldsValid = validateFields(selectedFields);

    if (!areFieldsValid) {
      setHighlightMandatoryFields(true);
      // return false;
      return true;
    }
    return true;
  };







  const resetAllInputs = () => {
    const inputs = {
      purchaseBuyerName: '',
      suppRepresentativeName: '',
      suppRepresentativeContact: '',
      suppRepresentativeEmail: ''
    }
    dispatch({ type: 'update', field: 'inputFields', value: inputs });
    dispatch({ type: 'update', field: 'errors', value: {} });
  }

  const resetState = () => {
    dispatch({ type: 'reset' });
    resetAllInputs();
    resetTableData();
    setHighlightMandatoryFields(null);
    setResetAllVisitedFields(false);
    changeBackgroundColor('processInfoBg');
  }



  useEffect(() => {
    if (resetAllVisitedFields)
      resetState();
    console.log('render2');

  }, [resetAllVisitedFields])











  // useEffect(() => {
  //   console.log(myState.rows, "rowsss")
  // }, [myState])
  return (
    <>
      <div className={clsx(styles.scrollContainer, styles.scrollBar, styles.formContainer)}>
        {/* <div className={styles.row}>
          <div className={styles.formTitle}>
            <span className={styles.formSpan}>Invoice Revision</span>
            <span className={styles.reqired}>Note:* Mandatory fields</span>
          </div>
        </div> */}

        {/* <CustomFormGroup header='Chassis Defect Details' body={renderProcessInfo()} />
        <CustomFormGroup header='E-POD Details' body={renderEPOD()} /> */}
        {/* pratik */}
        <CustomFormGroup header='Add VC' body={renderOther()} />






      </div>

      <div className={styles.actionBar}>
        {/* <Button
          className={clsx(styles.actionButton, styles.resetButton)}
          variant="tertiary"
        // onClick={handleOpen}
        >
          RESET
        </Button> */}



        <AuthChecker operation="submit">
          {isAuthorized => (
            <Button
              className={clsx(
                styles.actionButton,
                styles.primaryActionButton,
              )}
              variant="primary"
              //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
              onClick={() => handleSubmit()}
               disabled={!isAuthorized}
              data-testid="confirm-action"
            >
              CONFIRM
            </Button>
          )}

        </AuthChecker>

      </div>
      <ResetModal
        isPopupOpen={isPopupOpen}
        // handleClose={handleClose}
        resetFields={resetFields}
      />

      <ResetModal2
        a={confirmModalFlag2}
        handleCase={handleClose2}
        onDelete={() => onDeleteRow2(deleteParam2)}
      />


    </>
  )
}


function ResetModal2({ a, handleCase, onDelete }) {
  return (
    <Dialog
      open={a}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup1"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <DeleteIcon style={{ height: '18px', width: '18px' }} />
            <span className={styles.txt}>Are you sure ?</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <span>
            Do you want to delete ?
          </span>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleCase}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={onDelete}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function ResetModal1({ a, handleCase, onDelete }) {
  return (
    <Dialog
      open={a}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup1"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <DeleteIcon style={{ height: '18px', width: '18px' }} />
            <span className={styles.txt}>Are you sure ?</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <span>
            Do you want to delete ?
          </span>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleCase}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={onDelete}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

function ResetModal({ isPopupOpen, handleClose, resetFields }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <MdReplay style={{ height: '18px', width: '18px' }} />
            <span className={styles.txt}>Reset</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <span>
            Are you sure you want to reset the form?
          </span>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleClose}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleClose()
              resetFields()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

VCBCAddFrom.propTypes = {
  redirectToProcessPage: PropTypes.func
}

ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  resetFields: PropTypes.func.isRequired
}
ResetModal1.propTypes = {
  a: PropTypes.bool.isRequired,
  handleCase: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}
ResetModal2.propTypes = {
  a: PropTypes.bool.isRequired,
  handleCase: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}


VCBCAddFrom.propTypes = {
  counter: PropTypes.number,
  // baseUrl: PropTypes.string,
  product: PropTypes.object,
  auth: PropTypes.object,
};


export default withAllowedOperationsProvider(
  VCBCAddFrom,
  RESOURCE_TYPE.PPAP
);
