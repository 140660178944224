/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable eqeqeq */
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SETAll = "SETAll";
export const RESETAll = "RESETAll";
export const SETAllDI = "SETAllDI";
export const GETINVOICE = "GETINVOICE";
export const SETCHASSISDATA = "SETCHASSISDATA";
export const TOAST = "TOAST";
export const POSEARCH = "POSEARCH";
export const POROW = "POROW";
export const SETERROR = "SETERROR";
export const MENU = "MENU";
export const TABLEROWSTORE = "TABLEROWSTORE";
export const DASHBOARD = "DASHBOARD";

export const setError = (data) => ({
  type: SETERROR,
  payload: {
    error: data,
  }
});
export const setToast = (data) => ({
  type: TOAST,
  payload: {
    toast: data,
  }
});

export const setTableRowData = (data) => ({
  type: TABLEROWSTORE,
  payload: {
    tableData: data,
  }
});
export const setChassisData = (data) => ({
  type: SETCHASSISDATA,
  payload: {
    chassisData: data,
  }
});

export const setChassis = (data = null) => {
  if (data) {
    return {
      type: SET_PRODUCTS,
      payload: data,
    };
  }

  return {
    type: SET_PRODUCTS,
    payload: {},
  };
};

export const fetchChassis = paramData => async dispatch => {
  const { chassisNo, userId, flagFromPage, flagForOperations, ccoCode, baseUrl } = paramData;

  const apiFormatedData = {
    // eslint-disable-next-line object-shorthand
    chassisNo: chassisNo,
    userId,
    flagFromPage,
    flagForOperations,
    ccoCode
  }
  const apiFormatedDataJson = JSON.stringify(apiFormatedData);
  const headerss = new Headers();
  headerss.append('Content-Type', 'application/json');
  const res = await fetch(
    `${baseUrl}chsAck/cls_ChassisSearchDetails`,
    {
      method: "POST",
      headers: headerss,
      body: apiFormatedDataJson
    }
  );

  const responseData = await res.json();
  if (responseData.msg == "fail") {

    let errorMsg = responseData.c_ChassisSearchData[0].returnmsg;
    let obj = {
      errorMsg,
      errorStatus: true,    // show error msg
      showPage: false
    }
    let objData = {
      msg: "fail", c_ChassisSearchData:[]

    }

    dispatch(setError(obj));
    dispatch(setChassis(objData));

  } else {
    // let obj = {
    //   errorMsg: "",
    //   errorStatus: false,   // close error msg
    //   showPage: false
    // }
    // dispatch(setError(obj));
    dispatch(setChassis(responseData));

  }


  return res;
}

export const setPO = (data = null) => {
  if (data) {
    return {
      type: POSEARCH,
      payload: data,
    };
  }

  return {
    type: POSEARCH,
    payload: {},
  };
};
export const setPORow = (data = null) => {
  if (data) {
    return {
      type: POROW,
      payload: data,
    };
  }

  return {
    type: POROW,
    payload: {},
  };
};


export const poSearch = paramData => async dispatch => {
  const {
    userId,
    companyCode,
    userRole,
    poNo,
    vcNo,
    poDate,
    rsoCode,
    baseUrl
  } = paramData;

  const apiFormatedData = {
    // eslint-disable-next-line object-shorthand
    userId,
    companyCode,
    userRole,
    poNo,
    vcNo,
    poDate,
    rsoCode,
  }
  const apiFormatedDataJson = JSON.stringify(apiFormatedData);
  const headerss = new Headers();
  headerss.append('Content-Type', 'application/json');
  const res = await fetch(
    `${baseUrl}MFG/poSearch`,
    {
      method: "POST",
      headers: headerss,
      body: apiFormatedDataJson
    }
  );

  const data = await res.json();

  dispatch(setPO(data));

  return res;
}
export const getMenu = paramData => async dispatch => {
  const {
    userId, 
    baseUrl
  } = paramData;

  const apiFormatedData = {
    // eslint-disable-next-line object-shorthand
    userId,

  }
  const apiFormatedDataJson = JSON.stringify(apiFormatedData);
  const headerss = new Headers();
  headerss.append('Content-Type', 'application/json');
  const res = await fetch(
    `${baseUrl}home/menuData`,
    {
      method: "POST",
      headers: headerss,
      body: apiFormatedDataJson
    }
  );

  const data = await res.json();

  dispatch(menu(data));

  
}
export const getDashboardCount = paramData => async dispatch => {
  const {userId,companyCode,baseUrl} = paramData;

  const apiFormatedData = {
    userId,
    companyCode 
  }
  const apiFormatedDataJson = JSON.stringify(apiFormatedData);
  const headerss = new Headers();
  headerss.append('Content-Type', 'application/json');
  const res = await fetch(
    `${baseUrl}dashboard/dashboardCount`,
    {
      method: "POST",
      headers: headerss,
      body: apiFormatedDataJson
    }
  );

  const data = await res.json();

  dispatch(dashboard(data));

  
}


export const dashboard = (data = null) => { 
  if (data) {

    return {
      type: DASHBOARD,
      payload: data,
    };
  } 
  return {
    type: DASHBOARD,
    payload: {},
  };
};
export const menu = (data = null) => {
  

  if (data) {

    return {
      type: MENU,
      payload: data,
    };
  }


  return {
    type: SETAll,
    payload: {},
  };
};
export const setAll = (data = null, flag) => {

  if (data) {

    return {
      type: SETAll,
      payload: data,
    };
  }


  return {
    type: SETAll,
    payload: {},
  };
};
export const restAllSingle = () => {
let data=true;
  if (data) {

    return {
      type: RESETAll,
      payload: {
        msg:"",
        c_ChassisDetails: [{
          chs_rso_code: "",
          chs_veh_target_dt: null,
          returnmsg:"..."
        }],
        c_DefectList: [],
        c_DamageList: [],
        c_TransporterList: [],
        c_ChassisDefectList: [],
        c_ChassisePODDetails: [],  

      },
    };
  }


};


export const setAllDI = (data = null, flag) => {

  if (data) {

    return {
      type: SETAllDI,
      payload: data,
    };
  }


  return {
    type: SETAllDI,
    payload: {},
  };
};



export const setAllDetails = (paramData) => async dispatch => {
  const { bbCode, chassisNo, userId, flagFromPage, flagForOperations, companyCode, userRole, chsStmNo, uploadType, baseUrl } = paramData;

  const apiFormatedData = {
    chassisNo,
    flagFromPage,
    flagForOperations,
    userId,
    companyCode,
    userRole,
    chsStmNo,
    uploadType,
    bbCode
  }
  const apiFormatedDataJson = JSON.stringify(apiFormatedData);
  const headerss = new Headers();
  headerss.append('Content-Type', 'application/json');
  const res = await fetch(
    `${baseUrl}chsAck/cls_ShowChassisDetails`,
    {
      method: "POST",
      headers: headerss,
      body: apiFormatedDataJson
    }
  );

  const responseData = await res.json();

  if (responseData.msg == "fail") {
//    alert(responseData.msg)

    let errorMsg = responseData.c_ChassisDetails[0].returnmsg;
    let obj = {
      errorMsg,
      errorStatus: true,
      showPage: false
    }

    let emptyObj = {
      msg: responseData.msg,
      c_ChassisDetails: [
        {
      //    returnmsg: "Target date is already stamped",
          returnmsg: responseData.c_ChassisDetails[0].returnmsg,
          chs_rso_code: "",
          chs_veh_target_dt: null,
          check_flag: "No"
        }
      ],
      c_DefectList: [],
      c_DamageList: [],
      c_TransporterList: [],
      c_ChassisDefectList: [],
      c_ChassisePODDetails: [],

    }
    dispatch(setError(obj));
    // const response =  JSON.stringify(emptyObj)
    dispatch(setAll(emptyObj, 'if'));

  } else if(flagFromPage=="DI"){
    
    let obj = {
      errorMsg: "",
      errorStatus: false,
      showPage: true
    }
    dispatch(setError(obj));
    dispatch(setAllDI(responseData, 'else if'));
    
  } else {

    let obj = {
      errorMsg: "",
      errorStatus: false,
      showPage: true
    }
    dispatch(setError(obj));
    dispatch(setAll(responseData, 'else'));
    

  }


  return res;

};



export const setInvoice = (data = null) => {

  if (data) {
    return {
      type: GETINVOICE,
      payload: data,
    };
  }

  return {
    type: GETINVOICE,
    payload: {},
  };
};
// eslint-disable-next-line no-unused-vars
export const getInvoice = (paramData) => async dispatch => {
  const apiFormatedData = {
    ChassisNo: paramData.chassisNo
  }


  const apiFormatedDataJson = JSON.stringify(apiFormatedData);
  const headerss = new Headers();
  headerss.append('Content-Type', 'application/json');
  const res = await fetch(
    `https://172.24.47.101/TMLD_WEBAPI/Api/ChassisDetails`,
    {
      method: "POST",
      headers: headerss,
      body: apiFormatedDataJson,
      mode: 'cors'
    }
  );    // comment

  // const data = JSON.stringify(res);
  // eslint-disable-next-line no-unused-vars
  // const res={
  //   "msg": "Success",
  //   "data": {
  //     "Chassis_No": "MAT751050LFA01613",
  //     "EXEMPT_Value": "YES",
  //     "Chs_Invoicelist": [
  //       {
  //         "InvoiceNo": 750261259,
  //         "InvoiceDate": "20/02/2020",
  //         "MarchDate": ""
  //       },
  //       {
  //         "InvoiceNo": 750261115,
  //         "InvoiceDate": "18/02/2020",
  //         "MarchDate": ""
  //       }
  //     ],
  //     "Transporterlist": [
  //       {
  //         "TRANS_CODE": "A78160",
  //         "TRANS_NAME1": "A78160-A P CARRIERS"
  //       },
  //       {
  //         "TRANS_CODE": "AB9050",
  //         "TRANS_NAME1": "AB9050-A P TRANSPORT CO"
  //       }
  //     ]
  //   }
  // };

  const data = await res.json();

  if (!res.ok) {
    console.log(res.status, "log")
  }


  dispatch(setInvoice(data));

  return res;

};


