/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Form,
  Box,
  TextField,
  Grid,
  FormGroup,
  IconButton,
} from "@material-ui/core";
import WarningIcon from "@mui/icons-material/Warning";
import FormLabel from "@mui/material/FormLabel";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
// for print user in consol
import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";
// for print user in consol
import axios from "axios";

import clsx from "clsx";
// import $ from 'jquery';
import { useHistory } from "react-router-dom";
import Table from "../../components/Table/Table";
import styles from "./SupplierImportDetailsForTierII.module.css";
import { CustomFormGroup, CustomSelect } from "../../components/FormComponents";
import BackButton from "../../components/BackButton/BackButton";
import {
  DATE_FORMAT,
  API_RESOURCE_URLS,
  DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  MESSAGE_TYPE_CERTIFICATION,
  MESSAGE_TYPE_ANNEXURE,
  RESOURCE_TYPE,
  USER_OPERATIONS,
  Regex,
} from "../../constants";
import { withAllowedOperationsProvider } from "../../hocs";
import { CustomTab, Button, AuthChecker } from "../../atomicComponents";
import { API } from "../../apis/api";
import { buildErrorMessage } from "../../apis/calls";
import { usePopupManager } from "../../providers/PopupManager/PopupManager";

import StepBar1 from '../../components/StepBar1/StepBar';

const SupplierImportDetails = () => {


  const history = useHistory();
  const { showPopup } = usePopupManager();
  // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector((state) => state.counter.baseUrl); // useSelector
  console.log(baseUrl);
  const auth = useSelector((state) => state.auth); // useSelector
  console.log(auth)

  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [dynamicColumns1, setDynamicColumns1] = useState([]);
  const [plant, setPlant] = useState(null);
  const [flag, show] = useState(false);
  const [checkDisable, setDisable] = useState(true);
  const [Bc, setBC] = useState(null);
  const [bcArray, setBcArray] = useState([]);
  const [plantArray, setPlantArray] = useState([]);
  const [tableArray, setTableArray] = useState([]);
  const [asnCount, setAsnCount] = useState(0);
  const [loading, setLoader] = useState(false);
  const [loader, setLoading] = useState(true);

  const [vendCode, setvendCode] = useState();
  const [datesData, setDates] = useState({
    endDate: "",
    startDate: "",
  });

  // 
  const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false);
  const handleOpenFormModal = () => {
    setPopupOpenFormModal(true);
  }
  const handleCloseFormModal = () => setPopupOpenFormModal(false);

  // 

  const ROW_HEIGHT = 38;

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleOpen = () => setIsPopupOpen(true);
  const handleClose = () => setIsPopupOpen(false);
  const goNext = () => {
    uploadFileData();
  };

  const redirectToApplicationLandingPage = () => {
    history.push("/");
  };

  const setCount = (count) => {
    setAsnCount(count);
  };

  useEffect(() => {
    getData();
   // getDataAll();
    getdate();
    //  Uploadcertification();
  }, []);

  const getdate = () => {

    const headerss = new Headers();
    headerss.append("Content-Type", "application/json");
    fetch(`${baseUrl}Milestone_calendarView/`, {
      method: "GET",
      headers: headerss,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const endDate = responseJson[0].Collect_DVA_from_vendors_EndDate;
        const startDate = responseJson[0].Collect_DVA_from_vendors_StartDate;
        setDates((prevData) => ({ ...prevData, endDate, startDate }));

      })
      .catch((error) => { });
  };
  // useEffect(()=>{
  //   console.log("bcArray","effect")

  //   const {userRole,userID }=auth;

  //   if(userRole.includes("Vendor-CV") || userRole.includes("Vendor-PV"))
  //     {
  //    const singleVendor = bcArray.filter(element => element.value ==userID);
  //    if(singleVendor.length>=1)
  //    {
  //     setBC(singleVendor[0])
  //     console.log(bcArray,"render setbc",userID)
  //     setDisable(true)
  //    } else{
  //     console.log(bcArray,"render empty else",userID,singleVendor)

  //    }

  //    } else{
  //     setDisable(false)
  //    }

  // },[bcArray])
  //  useEffect(()=>{
  //   const {userRole,userID }=auth;

  //   if(userRole.includes("Vendor-CV") || userRole.includes("Vendor-PV"))
  //     {
  //       console.log(Bc,"render")
  //    showTable()
  //    } else{
  //     setDisable(false)
  //    }

  // },[Bc])

  const showTable = () => {
    getTableData();
    show(true);
  };
  // const showTableAll = () => {
  //   getTableDataAll();
  //   show(true);
  // };

  // let Vendor_Code ='';
  const handleSelect = (selectedOption, name) => {
    // alert(selectedOption.value);
    // console.log(selectedOption);
    setvendCode(selectedOption.value);
    if (name == "VC") {
      show(false);

      setBC(selectedOption);
      // getPlantData(selectedOption)
    } else {
      setPlant(selectedOption);
    }
  };

  const getTableData = () => {
    const apiFormatedData = {
      VC: Bc.value,
    };
    var apiFormatedDataJson = JSON.stringify(apiFormatedData);
    let headerss = new Headers();
    headerss.append("Content-Type", "application/json");
    fetch(`${baseUrl}Supplier_import_details_for_TierII/`, {
      method: "POST",
      headers: headerss,
      body: apiFormatedDataJson,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const tableData = responseJson.data;

        setTableArray(tableData);
        const excelData = tableData.map(
          (
            {
              Vendor_Code,
              // Plant_code,
              NAME1,
              Tier,
              GSTIN,
              IEC_Code,
              Revenue_from_Operations_in_last_FY_Rs_Crore,
              Total_Import_of_Goods_in_last_FY_Rs_Crore,
              Total_Import_of_Services_in_last_FY_Rs_Crore,
              Total_Imports_in_last_FY_Rs_Crore,
              Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
              Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
              Estimated_Import_attributable_to_Applicant_Rs_Crore,
              // Claimed_for_PLI,
              // Start_Date,
              Explanation,
            },
            id
          ) => {
            let obj = {
              id,
              Vendor_Code,
              // Plant_code,
              NAME1,
              Tier,
              GSTIN,
              IEC_Code,
              Revenue_from_Operations_in_last_FY_Rs_Crore,
              Total_Import_of_Goods_in_last_FY_Rs_Crore,
              Total_Import_of_Services_in_last_FY_Rs_Crore,
              Total_Imports_in_last_FY_Rs_Crore,
              Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
              Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
              Estimated_Import_attributable_to_Applicant_Rs_Crore,
              // Claimed_for_PLI,
              // Start_Date,
            //  Explanation,
            };
            return obj;
          }
        );
        setTableArray(excelData)
     
        setLoading(false)
        //  exportExcel(excelData);
      })
      .catch((error) => { });
  };

  const getTableData1 = (value) => {
    const apiFormatedData = {
      VC: value,
    };
    var apiFormatedDataJson = JSON.stringify(apiFormatedData);
    let headerss = new Headers();
    headerss.append("Content-Type", "application/json");
    fetch(`${baseUrl}Supplier_import_details/`, {
      method: "POST",
      headers: headerss,
      body: apiFormatedDataJson,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const tableData = responseJson.data;

        setTableArray(tableData);
        const excelData = tableData.map(
          (
            {
              Vendor_Code,
              // Plant_code,
              NAME1,
              Tier,
              GSTIN,
              IEC_Code,
              Revenue_from_Operations_in_last_FY_Rs_Crore,
              Total_Import_of_Goods_in_last_FY_Rs_Crore,
              Total_Import_of_Services_in_last_FY_Rs_Crore,
              Total_Imports_in_last_FY_Rs_Crore,
              Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
              Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
              Estimated_Import_attributable_to_Applicant_Rs_Crore
              // Exception_Classification,
              // Total_Import,
              // DVA,
              // // Claimed_for_PLI,
              // // Start_Date,
              // Explanation

            },
            id
          ) => {
            let obj = {
              id,
              Vendor_Code,
              // Plant_code,
              NAME1,
              Tier,
              GSTIN,
              IEC_Code,
              Revenue_from_Operations_in_last_FY_Rs_Crore,
              Total_Import_of_Goods_in_last_FY_Rs_Crore,
              Total_Import_of_Services_in_last_FY_Rs_Crore,
              Total_Imports_in_last_FY_Rs_Crore,
              Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore,
              Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore,
              Estimated_Import_attributable_to_Applicant_Rs_Crore,
              // Claimed_for_PLI,
              // Start_Date,
              // Explanation
            };
            return obj;
          }
        );

        exportExcel(excelData);
      })
      .catch((error) => { });
  };

  const getData = () => {
    let headerss = new Headers();
    headerss.append("Content-Type", "application/json");
    fetch(`${baseUrl}Supplier_import_details_for_TierII/`, {
      method: "GET",
      headers: headerss,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const ListArray = responseJson.map(({ Vendor_Code }) => ({
          value: Vendor_Code,
          label: Vendor_Code,
        }));
        setBcArray(ListArray);

        let { userRole, userID } = auth;

        if (userRole.includes("Vendor-CV") || userRole.includes("Vendor-PV") || userRole.includes("Vendor")) {
          const singleVendor = ListArray.filter(
            (element) => element.value == userID
          );
          if (singleVendor.length >= 1) {
            setBC(singleVendor[0]);
            setDisable(true);
            show(true); // table show
            getTableData1(singleVendor[0].value);
          }
        } else {
          setDisable(false);
        }
      })
      .catch((error) => { });
  };
  const getDataAll = () => {
    let headerss = new Headers();
    headerss.append("Content-Type", "application/json");
    fetch(`${baseUrl}Gat_all_Supplier_list/`, {
      method: "GET",
      headers: headerss,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setTableArray(responseJson);
     
 console.log(responseJson)
    
       
      })
      .catch((error) => { });
  };
  const uploadFileData = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.id = "file";
    input.setAttribute(
      "accept",
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    );
    input.click();
    input.onchange = (e) => {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      fetch(`${baseUrl}Supplier_import_details_for_TierII/`, {
        method: "PUT",
        body: formData,
      })
        .then((response) => {
          // eslint-disable-next-line radix
          let status1 = parseInt(response.status);
          if (status1 == "201") {
            //   document.removeChild(input);
            getTableData();
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded sucessfully",
            });
          } else {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded failed",
            });
          }
        })

        .catch((error) => { });
    };
  };

  const exportExcel = async (excelData) => {
    var fileTypel =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    var fileExt = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { booktype: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileTypel });
    FileSaver.saveAs(data, `${Bc.value}${fileExt}`);
  };

  const baseDefaultColumns = [
    {
      width: 120,
      title: "TML_Vendor Code",
      field: "Vendor_Code",
      enableSearch: true,
      enableFilter: true,
    },
    // {
    //   width: 120,
    //   title: "Plant code",
    //   field: "Plant_code",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 150,
      title: "Name of Suppliers",
      field: "NAME1",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 100,
      title: "Tier",
      field: "Tier",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: "GSTIN_of_Supplier",
      field: "GSTIN",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: "IEC Code",
      field: "IEC_Code",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: "Revenue from Operations in last FY",
      field: "Revenue_from_Operations_in_last_FY_Rs_Crore",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: "Total Imports of Good on Last FY",
      field: "Total_Import_of_Goods_in_last_FY_Rs_Crore",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: "Total Imports of Services* on Last FY",
      field: "Total_Import_of_Services_in_last_FY_Rs_Crore",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: "Total Imports in Last FY",
      field: "Total_Imports_in_last_FY_Rs_Crore",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 150,
      title: "Estimated Imports of Goods attributal to Applicant",
      field: "Estimated_Import_of_Goods_attributable_to_Applicant_Rs_Crore",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 150,
      title: "Estimated Imports of Services* attributal to Applicant",
      field: "Estimated_Import_of_Services_attributable_to_Applicant_Rs_Crore",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: "Estimated Imports attributal to Applicant",
      field: "Estimated_Import_attributable_to_Applicant_Rs_Crore",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: "PPE at the start of last FY ",
      field: "PPE_at_the_start_of_last_FY ",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: "PPE At The Close_of_last_FY",
      field: "PPE_at_the_close_of_last_FY",
      enableSearch: true,
      enableFilter: true,
    }, 
    {
      width: 120,
      title: "Avg_PPE_for_last_FY",
      field: "Avg. PPE for last FY",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: "Revenue to PPE ratio",
      field: "Revenue_to_PPE_ratio",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: "Copies of AFS of suppliers in last FY submitted",
      field: "Copies_of_AFS_of_suppliers_in_last_FY_submitted",
      enableSearch: true,
      enableFilter: true,
    },

    // {
    //   width: 120,
    //   title: "Claimed for PLI",
    //   field: "Claimed_for_PLI",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Start Date",
    //   field: "Start_Date",
    //   enableSearch: true,
    //   enableFilter: true,
    // },

  ];

  const goNextForm = () => {
    var url;
    var urlMethod;

    let { userRole, userID } = auth;

    if (userRole.includes("GDC")) {
      url = "VendorMISProgress";
      urlMethod = "PUT";
    } else if (userRole.includes("Vendor") || userRole.includes("Vendor-CV")) {
      url = "VendorMISProgress";
      urlMethod = "POST";
    } else {
      url = "VendorMISProgress";
      urlMethod = "POST";
    }
    const apiFormatedData = {
      VC: Bc.value,


    }

    var apiFormatedDataJson = JSON.stringify(apiFormatedData);
    let headerss = new Headers();
    headerss.append('Content-Type', 'application/json');
    fetch(`${baseUrl}${url}/`,
      {
        method: urlMethod,
        headers: headerss,
        body: apiFormatedDataJson
      }).then((response) => response.json())
      .then((responseJson) => {
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: "Data is Succesfully Saved"

        });
        history.push('/')

      })
      .catch((error) => {

      });

  };


  // const redirectToCreateProjectPage = () => history.push("/");
  const renderHTML = () => (
    <div className={styles.formGroup}>

      <Grid
        container
        spacing={1}

        style={{ display: 'flex', justifyContent: "left", marginTop: "5px", marginBottom: "1px" }}
        columns={12}
      >
        <Grid item md={5} lg={5}>
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{ width: "20%" }}>
              <label className={styles.label}>Supplier </label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name="bc"
                options={bcArray}
                isDisabled={checkDisable}
                className={clsx(styles.select, styles.sel1)}
                value={Bc}
                isMulti={false}
                // isClearable
                onChange={(e) => {
                  handleSelect(e, "VC");
                }}
              />
              <div
                style={{ marginLeft: "20px", marginTop: "1px", justifyContent: "center" ,display:"flex"}}
              >
                <Button className={clsx(
                  styles.actionButton,
                  styles.primaryActionButton,
                )}
                  variant="primary"
                  onClick={showTable}
                >
                  Show Data

                </Button>
                {
                  loader == false &&

                  <AuthChecker operation="download">

                      {isAuthorized => (
                        <Button
                          className={clsx(
                            styles.actionButton,
                            styles.primaryActionButton,
                          )}
                          variant="primary"
                          //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
                          onClick={() => {

                            if (tableArray.length >= 1) { exportExcel(tableArray); }
                          }}
                          disabled={!isAuthorized}
                          data-testid="confirm-action"
                          style={{ marginLeft: "5px" }}

                        >
                          download
                        </Button>
                      )}

                    </AuthChecker>

                }
                 {/* {
                  loader == false &&

                  <AuthChecker operation="download">

                  {isAuthorized => (
                    <Button
                      className={clsx(
                        styles.actionButton,
                        styles.primaryActionButton,
                      )}
                      variant="primary"
                      //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
                    onClick={getDataAll}
                      disabled={!isAuthorized}
                      data-testid="confirm-action"
                      style={{ marginLeft: "5px" ,    width: "max-content"}}

                    >
                      ALL Data
                    </Button>
                  )}

                </AuthChecker>


                } */}
              </div>
            </div>
          </div>

        </Grid>

      </Grid>
      {/* <div className={styles.formGroup}>
        <div className={styles.formGroupRow}>
          <div className={styles.formRow}>
            <label className={styles.label}>Vendor Code</label>
            <CustomSelect
              name="bc"
              options={bcArray}
              isDisabled={checkDisable}
              className={clsx(styles.select, styles.sel1)}
              value={Bc}
              isMulti={false}
              isClearable
              onChange={(e) => {
                handleSelect(e, "VC");
              }}
            />
          </div>
          <div className={styles.formRow}>
            <label className={styles.label}>
              <Button
                className={clsx(
                  styles.actionButton,
                  styles.primaryActionButton
                )}
                variant="primary"
                onClick={showTable}
              >
                Download Data
              </Button>
            </label>

          </div>
        </div>


      </div> */}
    </div>
  );





  return (
    <>
      <div className={styles.container} style={{ boxSizing: "border-box" }}>
        <div>
          <div className={styles.backButtonLastRefreshedDateWrapper}>
            <BackButton
              action="Back"
              handleClick={redirectToApplicationLandingPage}
            />
            <div className={styles.topBar} style={{}}>
              <div className={styles.divspan}>Collect DVA from Vendors Start Date:<span>{datesData.startDate}</span></div>
              <div className={styles.divspan}>Collect DVA from Vendors End Date:<span>{datesData.endDate}</span></div>
            </div>
          </div>
          <div className={styles.topDiv}>
            <div className={styles.processHeader}>
              <div style={{ width: "15%" }}>
                <CustomTab title="Supplier Import Details (CV)" withOutCount isSelected />
              </div>

              <StepBar1 enable={5} />

            </div>
          </div>
        </div>
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup header="Supplier Details (CV) for Tier-II & Tier-III -- > (1) Details for Tier-II & Tier-III may be provided if required by TA. (2) '*' Import of services including royalty, as disclosed in form 15CA & 15CB of Income Tax Act/ Rules. " body={renderHTML()} />
          </div>
        </div>
        {flag && (
          <div
            className={styles.tableView}
            style={{ height: "86%", padding: "0 2em" }}
          >
            <Table
              columns={[...baseDefaultColumns]}
              defaultColumns={[...baseDefaultColumns]}
              rowHeight={38}
              rows={tableArray}
              primaryAction={{
                name: "Submit Data",
                authOperation: "submit",
                shouldEnable: () => true,
                actionFn: handleOpenFormModal,
              }}
              secondaryActions={[
                {
                  name: "Upload",
                  authOperation: "upload",
                  shouldEnable: (selected) => true,
                  actionFn: (selected) => {
                    handleOpen();
                  },
                  customClass: "",
                },



              ]}
              setAsnCount={setCount}
              isDataLoading={false}
              actionButtons
              onRowSelect={(e) => { }}
              removeRowSelection
            />
          </div>
        )}
      </div>
      {/* <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            // onClick={onDelete}
          >
            Yes
          </Button> */}
      <ResetModalF
        isPopupOpen={isPopupOpenFormModal}
        handleCloseFormModal={handleCloseFormModal}
        goNextForm={goNextForm}
      />

      <ResetModal
        isPopupOpen={isPopupOpen}
        handleClose={handleClose}
        goNext={goNext}
      />

    </>
  );
};
function ResetModalF({ isPopupOpen, handleCloseFormModal, goNextForm }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>Confirm Submit</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation ,VC BoM data will submit</p>

            <p>Are you sure ?</p>

          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleCloseFormModal}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleCloseFormModal()
              goNextForm()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function ResetModal({ isPopupOpen, handleClose, goNext }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: "18px", width: "18px" }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>
              Confirm file Upload
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p>
              {" "}

              On confirmation ,Records will be appended.
            </p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleClose();
              goNext();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}
ResetModalF.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleCloseFormModal: PropTypes.func.isRequired,
  goNextForm: PropTypes.func.isRequired,
};

ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
};

export default withAllowedOperationsProvider(SupplierImportDetails, RESOURCE_TYPE.PROJECT);
