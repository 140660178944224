/* eslint-disable import/order */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { connect, useDispatch,useSelector  } from 'react-redux'

import { TextField, Grid, FormGroup, IconButton } from '@material-ui/core';
import FormLabel from '@mui/material/FormLabel';

import clsx from 'clsx'
import { useHistory } from 'react-router-dom';
import Table from '../../components/Table/Table';
import styles from './Test2.module.css';
import { CustomFormGroup, CustomSelect } from '../../components/FormComponents'
import BackButton from '../../components/BackButton/BackButton';
import { DATE_FORMAT, API_RESOURCE_URLS, DISPLAY_MESSAGES, MESSAGE_TYPE, RESOURCE_TYPE, USER_OPERATIONS, Regex } from '../../constants'
import { withAllowedOperationsProvider } from '../../hocs';
import { CustomTab, Button } from '../../atomicComponents';

import { usePopupManager } from '../../providers/PopupManager/PopupManager';
import { DeleteOutlined } from '@material-ui/icons';


const Test = () => {
  const history = useHistory(); 
  const { showPopup } = usePopupManager();
 // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector(state => state.counter.baseUrl);  // useSelector


  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [dynamicColumns1, setDynamicColumns1] = useState([]);
  const [plant, setPlant] = useState(null);
  const [flag, show] = useState(false);
  const [Bc, setBC] = useState(null);
  const [bcArray, setBcArray] = useState([]);
  const [plantArray, setPlantArray] = useState([]);
  const [tableArray, setTableArray] = useState([]);
  const [asnCount, setAsnCount] = useState(0);

  const ROW_HEIGHT = 38;
  const redirectToApplicationLandingPage = () => {
    history.push('/');
  };

  const setCount = count => {
    setAsnCount(count);
  };

  useEffect(()=>{
    getData();

  },[])
const showTable=()=>{
  getTableData();
  show(true)

}

  const handleSelect = (selectedOption, name) => {
if(name=="baseVC")
{
  show(false)

  setBC(selectedOption);
  getPlantData(selectedOption)

} else{
  setPlant(selectedOption);
}

  }
  const getTableData = () => {
    const apiFormatedData = {
      BascVC:Bc.value,
      Mfg_plant:plant.value,  
    }

    var apiFormatedDataJson = JSON.stringify(apiFormatedData);
    let headerss = new Headers();
    headerss.append('Content-Type', 'application/json');
    fetch(`${baseUrl}select_vc/`,
      {
        method: 'POST',
        headers: headerss,
       body: apiFormatedDataJson
      }).then((response) => response.json())
      .then((responseJson) => { 
     const tableData = responseJson.data;

      setTableArray(tableData)


      })
      .catch((error) => {
 
      });

  };
  const getPlantData = (param) => {
    const apiFormatedData = {
      BascVC:param.value,
      Mfg_plant: "",  
    }

    var apiFormatedDataJson = JSON.stringify(apiFormatedData);
    let headerss = new Headers();
    headerss.append('Content-Type', 'application/json');
    fetch(`${baseUrl}select_vc/`,
      {
        method: 'POST',
        headers: headerss,
       body: apiFormatedDataJson
      }).then((response) => response.json())
      .then((responseJson) => { 
      const plantData = responseJson.data.map(({ Mfg_plant, }) => ({ value: Mfg_plant, label: Mfg_plant }));

      setPlantArray(plantData)


      })
      .catch((error) => {
 
      });

  };


  const getData = () => {
 
    const apiFormatedData = {
      "BascVC":"",
      "Mfg_plant": "",  
    }

    var apiFormatedDataJson = JSON.stringify(apiFormatedData);
    let headerss = new Headers();
    headerss.append('Content-Type', 'application/json');
    fetch(`${baseUrl}QualifiedVcView/`,
      {
        method: 'GET',
        headers: headerss,
    //    body: apiFormatedDataJson
      }).then((response) => response.json())
      .then((responseJson) => { 
      const ListArray = responseJson.map(({ Base_VC, }) => ({ value: Base_VC, label: Base_VC }));
      setBcArray(ListArray)

      })
      .catch((error) => {
 
      });

  };
  const actionBaseVC = (selected) => {
    const ids=selected.map(({id})=>(id));
    const apiFormatedData = {
      id:ids,
    }

    var apiFormatedDataJson = JSON.stringify(apiFormatedData);
    let headerss = new Headers();
    headerss.append('Content-Type', 'application/json');
    fetch(`${baseUrl}QualifiedVcView/`,
      {
        method: 'DELETE',
        // headers: headerss,
        body: apiFormatedDataJson
      }).then((response) => response.json())
      .then((responseJson) => { 
 

      })
      .catch((error) => {
 
      });

  };


  const baseDefaultColumns = [
    {
      width: 130,
      title: 'Mfg Plant',
      field: 'Mfg_plant',
      enableSearch: true,
      enableFilter: true, 
    },
    {
      width: 160,
      title: 'Base VC',
      field: 'Base_VC',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 160,
      title: 'VC Description',
      field: 'VC_Discription',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: 'Colour VC',
      field: 'colour_vc',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 250,
      title: 'Colour VC Desc',
      field: 'Colour_vc_discription',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 300,
      title: 'ATT Product Code',
      field: 'ATT_Product_Code',
      enableSearch: true,
      enableFilter: true,
    }




  ];
   
  const redirectToCreateProjectPage = () => history.push('/');
  const redirectToProjectEditPage = (id) => history.push(`/`);
  const redirectToProjectDetailsPage = (id) => history.push(`/`);



  const renderHTML = () => (
    <div> 

         <div className={styles.formGroup}>

        <div className={styles.formGroupRow}>
          <div className={styles.formRow}>
            <label className={styles.label}>
            Base VC
            </label>
            <CustomSelect
              name="bc"
              options={bcArray} 
              className={clsx(styles.select, styles.sel1)}
              value={Bc}
              isMulti={false}
              isClearable
              onChange={(e) => { handleSelect(e, 'baseVC') }}

            />

          </div>



        </div>
        <div className={styles.formGroupRow}>
          <div className={styles.formRow}>
            <label className={styles.label}>
              Plant
            </label>
            <CustomSelect
              name="Plant"
              options={plantArray}  
              className={clsx(styles.select, styles.sel1)}
              value={plant}

              isMulti={false}

              isClearable
              onChange={(e) => { handleSelect(e, 'plant') }}

            />

          </div>  
        </div>
        <div className={styles.formGroupRow}>
          <div className={styles.formRow}> 
            <Button className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
             variant="primary" 
             onClick={showTable}>
              Search

            </Button>
          </div>  
        </div>
      </div>

     
    </div>

  )


  return (
    <>
      <div className={styles.container} style={{ boxSizing: 'border-box' }}>
        <div>
          <div className={styles.backButtonLastRefreshedDateWrapper}>
            <BackButton
              action="Back"
              handleClick={redirectToApplicationLandingPage}
            />
            <span
              data-testid="lastRefreshedDate"
              className={styles.lastUpdatedAtText}
            />

          </div>
          <div className={styles.topDiv}>
            <div className={styles.processHeader}>
              <div style={{ width: '15%' }}>
                <CustomTab title="VC Qualification" withOutCount isSelected />
              </div>
              <div className={styles.processTopbar} style={{
                flexGrow: 0
              }}>
                <span style={{ color: "#FFA222" }} className={styles.title}>Product Line:SCV</span>

              </div>
            </div>
          </div>
        </div>
        <div   >
          <div className={styles.formContainer}>
            <CustomFormGroup header='VC Qualification for PLI' body={renderHTML()} />


          </div>
        </div>
        {
          flag &&

          <div
            className={styles.tableView}
            style={{ height: '86%', padding: '0 2em' }}
          >
    

            <Table
              columns={[...baseDefaultColumns]}
              defaultColumns={[...baseDefaultColumns]}
              rowHeight={38} 
              rows={tableArray}
    
              secondaryActions={[
                {
                  name: 'Delete',
                  authOperation: 'create-project',
                  shouldEnable: selected => selected.length >=1,
                  actionFn: selected => {actionBaseVC(selected)},
                  customClass:"btnDelete"

                }
              ]}
              setAsnCount={setCount}
              isDataLoading={false}
              actionButtons
              onRowSelect={(e) =>{}}
            />
          </div>
        }
      </div>
    </>)
}


export default withAllowedOperationsProvider(
  Test,
  RESOURCE_TYPE.PROJECT
);