/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './StepBar.module.css';

const StepBar = ({ enable }) => {

  return (
    <div className={styles.progressBarr}>

      <div className={styles.master}>
        <ul id={styles.progressbar} className={styles.textCenter}>
          <li className={(enable === 1 ? clsx(styles.active, styles.bg) : clsx(styles.active))} id={styles.step1}><div className={styles.block}>Milestones</div></li>
          <li className={enable === 2 ? clsx(styles.active, styles.bg) : clsx(styles.active)} id={styles.step2} ><div className={styles.block}>VC Declaration</div></li>
          <li className={enable === 3 ? clsx(styles.active, styles.bg) : clsx(styles.active)} id={styles.step3}><div className={styles.block}>Mass VC</div></li>
          <li className={enable === 4 ? clsx(styles.active, styles.bg) : clsx(styles.active)} id={styles.step4} ><div className={styles.block}>VCBOM Data</div></li>
          <li className={enable === 5 ? clsx(styles.active, styles.bg) : clsx(styles.active)} id={styles.step5} ><div className={styles.block}>Vendor DVA</div></li>
          <li className={enable === 6 ? clsx(styles.active, styles.bg) : clsx(styles.active)} id={styles.step6} ><div className={styles.block}>Certification</div></li>
          <li className={enable === 7 ? clsx(styles.active, styles.bg) : clsx(styles.active)} id={styles.step7} ><div className={styles.block}>VC MIS</div></li>
          <li className={enable === 8 ? clsx(styles.active, styles.bg) : clsx(styles.active)} id={styles.step8} ><div className={styles.block}>Vendor MIS</div></li>
          <li className={enable === 9 ? clsx(styles.active, styles.bg) : clsx(styles.active)} id={styles.step9} ><div className={styles.block}>DVA</div></li>
          <li className={enable === 10 ? clsx(styles.active, styles.bg) : clsx(styles.active)} id={styles.step10} ><div className={styles.block}>MHI data</div></li>
          <li className={enable === 11 ? clsx(styles.active, styles.bg,styles.lastC) : clsx(styles.active)} id={styles.step11} ><div className={styles.block}>Audit Report</div></li>
        </ul>
      </div>



    </div>
  );
}
StepBar.propTypes = {

  enable: PropTypes.number,

};
export default StepBar;
