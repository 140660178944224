import React from 'react';
import PropTypes from 'prop-types';
import styles from './HeaderAndFooter.module.css';
import { Footer, Header } from '../../components';

const HeaderAndFooter = ({ children }) => (
  <div className={styles.body}>
    <Header />
    <div className={styles.renderArea}>{children}</div>
    <Footer />
  </div>
);

HeaderAndFooter.propTypes = {
  children: PropTypes.any,
};

export default HeaderAndFooter;
