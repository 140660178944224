/* eslint-disable vars-on-top */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux'

import clsx from 'clsx';

import { useHistory } from 'react-router-dom';
import styles from './LandingPage.module.css'
import { ListView, SubHeader, ToolsListView } from '../../components';
import { USER_OPERATIONS, PPAP_APP_NAME, PERSONAS, TOOLS } from '../../constants';
import { useAuthorizationContext } from '../../providers/AuthorizationHandler/AuthorizationHandler';
import useAuthorizedEpApps from '../../hooks/useAuthorizedEpApps';
import { usePopupManager } from '../../providers/PopupManager/PopupManager';
import config from '../../config/config';




// function LandingPage() {
const LandingPage = () => {

  const { isAuthenticated, user, authResources } = useAuthorizationContext();
  const history = useHistory();

  const [selectedColumnsQueue, setSelectedColumsQueue] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [columnsRoot, setColumnsRoot] = useState();
  const [classFlag, setClass] = useState('center');
  const authorizedApps = useAuthorizedEpApps(isAuthenticated, authResources);
  const { showLoading } = usePopupManager();
  const auth = useSelector(state => state.auth);
  const role = auth.userRole;
  
  const changeClass = () => {
    setClass('right')
  }
  const handleSelection = selection => {
    console.log('selection in landing Page', selection);
    setSelectedColumsQueue(prev => {
      changeClass();
      if (prev.length) {
        const lastSelection = prev[prev.length - 1];
        if (lastSelection === selection) return prev;
        while (prev.length) {
          const existingTopLevel = prev[prev.length - 1].level;
          if (existingTopLevel >= selection.level) prev.pop();
          else break;
        }
      }
      prev.push(selection);
      return [...prev];
    });
  }


  const getArrayOfOptionSetsToRender = queue =>
    queue.map(item => item.next).filter(item => item);

  const getToolOptions = () =>
    [

      {
        label: 'Barcode Pattern',
        action: () => {
          history.push('/document-master');
        },
        authOperation: USER_OPERATIONS.CREATE_PPAP_STAGE_DOCUMENT,
        testId: 'document-master-btn'
      },



    ];

  const toolOptions = getToolOptions();
  const urlBaseAndPersonaMap = new Map();
  urlBaseAndPersonaMap.set(PERSONAS.BUYER, 'buyer');
  urlBaseAndPersonaMap.set(PERSONAS.VENDOR, 'vendor');
  urlBaseAndPersonaMap.set(PERSONAS.FBV_VENDOR, 'vendor');
  urlBaseAndPersonaMap.set(PERSONAS.FBV, 'fbv');
  const deriveNextLaneForMaterialGroups = (persona, materialGroup) => {
    const urlBaseForPersona = urlBaseAndPersonaMap.get(persona);
    if (persona === PERSONAS.BUYER)
      return [
        {
          label: 'View Plan',
          next: null,
          level: 3,
          action: () => {
            history.push(`/${urlBaseForPersona}/${materialGroup}`);
          },
        },
        {
          label: 'ASN DETAILS',
          next: null,
          level: 3,
          action: () => {
            history.push(`/${urlBaseForPersona}/${materialGroup}/asn-details`);
          },
        },
      ];
    return [
      {
        label: 'Plan',
        next: null,
        level: 3,
        action: () => {
          history.push(`/${urlBaseForPersona}/${materialGroup}`);
        },
      },
      {
        label: 'Drop',
        next: null,
        level: 3,
        action: () => {
          history.push(`/${urlBaseForPersona}/${materialGroup}/drop`);
        },
      },
      {
        label: 'Dispatch',
        next: null,
        level: 3,
        action: () => {
          history.push(`/${urlBaseForPersona}/${materialGroup}/dispatch`);
        },
      },
    ];
  };

  const deriveMaterialGroupLaneFromMaterialGroups = (
    userPersona,
    materialGroups,
  ) =>
    materialGroups.map(record => ({
      value: record.materialGroup,
      label: record.description,
      level: 2,
      next: deriveNextLaneForMaterialGroups(userPersona, record.materialGroup), // step 4
      action: null,
    }));

  //  Admin Role
  const adminMenu = [

    /* Admin control tabs */
    {
      label: "Admin Control",
      next: [
        // {
        //   label: 'Supplier DVA Declaration-CV',
        //   next: null,
        //   level: 2,
        //   action: () => {
        //     history.push('/supplier_import_details-cv');
        //   },
        // },
        {
          label: 'AFS Master',
          next: null,
          level: 2,
          action: () => {
            history.push('/afs-master');
          },
        },
        // {
        //   label: 'Buyer Master',
        //   next: null,
        //   level: 2,
        //   action: () => {
        //     history.push('/buyer-master');
        //   },
        // },
        // {
        //   label: 'MPFD Master',
        //   next: null,
        //   level: 2,
        //   action: () => {
        //     history.push('/mpfd-master');
        //   },
        // },
      ],
      level: 1,
      action: null
    },

    // {
    //   label: "Milestone Calendar",
    //   next: [
    //     {
    //       label: 'Calendar-CV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/milestones-calendar-cv');
    //       },
    //     },
    //     {
    //       label: 'Calendar-PV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/milestones-calendar-pv');
    //       },
    //     },
    //     {
    //       label: 'Calendar-EV',
    //       next: null,
    //       level: 1,
    //       action: () => {
    //         history.push('/');
    //       },
    //     }


    //   ],
    //   level: 1,
    //   action: null
    // },


    // {
    //   label: "VC Declaration",
    //   next: [
    //     {
    //       label: 'VC Declaration-CV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/add-vcbc-cv');
    //       },
    //     },
    //     {
    //       label: 'VC Declaration-PV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/add-vcbc-pv');
    //       },
    //     },
    //     {
    //       label: 'VC Declaration-EV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/');
    //       },
    //     },

    //   ],
    //   level: 1,
    //   action: null
    // },
    // {
    //   label: "Mass VC Declaration",
    //   next: [
    //     {
    //       label: 'Mass VC Declaration-CV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/vcbc-list-cv');
    //       },
    //     },
    //     {
    //       label: 'Mass VC Declaration-PV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/vcbc-list-pv');
    //       },
    //     },
    //     {
    //       label: 'Mass VC Declaration-EV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/');
    //       },
    //     },

    //   ],
    //   level: 1,
    //   action: null
    // },
    // {
    //   label: "VC BoM Data",
    //   next: [
    //     {
    //       label: 'VC BoM Data-CV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/vcbom-data');
    //       },
    //     },
    //     {
    //       label: 'VC BoM Data-PV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/vcbom-data-pv');
    //       },
    //     },
    //     {
    //       label: 'VC BoM Data-EV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/');
    //       },
    //     },

    //   ],
    //   level: 1,
    //   action: null
    // },
    // {
    //   label: "Supplier DVA Declaration",
    //   next: [
    //     // {
    //     //   label: 'Supplier DVA Declaration-CV',
    //     //   next: null,
    //     //   level: 2,
    //     //   action: () => {
    //     //     history.push('/supplier_import_details-cv');
    //     //   },
    //     // },
    //     {
    //       label: 'Supplier DVA Declaration-CV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/supplier_import_details-cv');
    //       },
    //     },
    //     // {
    //     //   label: 'Supplier DVA Declaration-PV',
    //     //   next: null,
    //     //   level: 2,
    //     //   action: () => {
    //     //     history.push('/vendor-data-pv');
    //     //   },
    //     // },
    //     // {
    //     //   label: 'Supplier DVA Declaration-EV',
    //     //   next: null,
    //     //   level: 2,
    //     //   action: () => {
    //     //     history.push('/');
    //     //   },
    //     // }

    //   ],
    //   level: 1,
    //   action: null
    // },
    // {
    //   label: "Supplier Certification",
    //   next: [
    //     {
    //       label: 'Supplier Certification-CV',
    //       next: null,
    //       level: 1,
    //       action: () => {
    //         history.push('/vendor-certificate');
    //       },
    //     },
    //     {
    //       label: 'Supplier Certification-PV',
    //       next: null,
    //       level: 1,
    //       action: () => {
    //         history.push('/vendor-certificate-pv');
    //       },
    //     },
    //     {
    //       label: 'Supplier Certification-EV',
    //       next: null,
    //       level: 1,
    //       action: () => {
    //         history.push('/vendor-certificate-ev');
    //       },
    //     },
    //   ],
    //   level: 1,
    //   action: null
    // },


    // {
    //   label: "VC MIS",
    //   next: [
    //     {
    //       label: 'VC MIS-CV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/colour-vc-mis');
    //       },
    //     },
    //     {
    //       label: 'VC MIS-PV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/colour-vc-mis-pv');
    //       },
    //     },
    //     {
    //       label: 'VC MIS-EV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/');
    //       },
    //     },

    //   ],
    //   level: 1,
    //   action: null
    // },
    // {
    //   label: "Supplier MIS",
    //   next: [
    //     {
    //       label: 'Supplier MIS-CV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/vendor-mis');
    //       },
    //     },
    //     {
    //       label: 'Supplier MIS-PV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/vendor-mis-pv');
    //       },
    //     },
    //     {
    //       label: 'Supplier MIS-EV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/');
    //       },
    //     },

    //   ],
    //   level: 1,
    //   action: null
    // },


    // {
    //   label: "DVA Calculation",
    //   next: [
    //     {
    //       label: 'DVA Calculation CV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/dva-calculation-cv');
    //       },
    //     },
    //     {
    //       label: 'DVA Calculation PV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/dva-calculation-pv');
    //       },
    //     },
    //     {
    //       label: 'DVA Calculation EV',
    //       next: null,
    //       level: 3,
    //       action: () => {
    //         history.push('/');
    //       },
    //     },
    //   ],
    //   level: 1,
    //   action: null
    // },

    // MHI Data Section
    // {
    //   label: "MHI Data",
    //   next: [
    //     {
    //       label: 'MHI Data CV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/mhi-data-cv');
    //       },
    //     },
    //     {
    //       label: 'MHI Data PV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/mhi-data-pv');
    //       },
    //     },
    //     {
    //       label: 'MHI Data EV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/');
    //       },
    //     },
    //     {
    //       label: 'MHI Form CV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/mhi-form-cv');
    //       },
    //     },
    //   ],
    //   level: 1,
    //   action: null
    // },
    // {
    //   label: "Audit Report",
    //   next: [
    //     {
    //       label: 'Report-CV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/audit-report-cv');
    //       },
    //     },
    //     {
    //       label: 'Report-PV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/audit-report-pv');
    //       },
    //     },
    //     {
    //       label: 'Report-EV',
    //       next: null,
    //       level: 1,
    //       action: () => {
    //         history.push('/');
    //       },
    //     }


    //   ],
    //   level: 1,
    //   action: null
    // },
    // {
    //   label: "Supplier DVA Collection",
    //   next: [
    //     {
    //       label: 'Supplier DVA Collection-CV (Purchase)',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/Supplier-DVA-Collection-CV-Purchase');
    //       },
    //     },
    //     {
    //       label: 'Supplier DVA Collection-PV (Purchase)',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/Supplier-DVA-Collection-PV-Purchase');
    //       },
    //     },
    //     {
    //       label: 'Supplier DVA Collection-EV (Purchase)',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/');
    //       },
    //     },
    //     {
    //       label: 'Supplier DVA Collection-CV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/Supplier-DVA-Collection-CV');
    //       },
    //     },
    //     {
    //       label: 'Supplier DVA Collection-PV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/Supplier-DVA-Collection-pv');
    //       },
    //     },
    //     {
    //       label: 'Supplier DVA Collection-EV',
    //       next: null,
    //       level: 1,
    //       action: () => {
    //         history.push('/');
    //       },
    //     }
    //   ],
    //   level: 1,
    //   action: null
    // },

    // AFS Section
    {
      label: "Annual Financial Details Form",
      next: null,
      level: 2,
      action: () => {
        history.push('/annual_financial_statement')
      }
    },
    // {
    //   label: "AFS Details",
    //   next: [
    //     {
    //       label: "Annual Financial Details Form",
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/annual_financial_statement');
    //       }
    //     },
    //     {
    //       label: 'Supplier AFS Details',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/supplier_import_details-cv');
    //       },
    //     },
    //     {
    //       label: 'Supplier Import Details-CV-For Tier II & III ',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/supplier_import_details-cv-For_Tier_II');
    //       },
    //     },
    //     {
    //       label: 'Supplier Import Details-PV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/supplier_import_details-pv');
    //       },
    //     },
    //     {
    //       label: 'Supplier Import Details-PVFor Tier II & III',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/supplier_import_details-pv-For_Tier_II');
    //       },
    //     },
    //     {
    //       label: 'Supplier Import Details-EV',
    //       next: null,
    //       level: 1,
    //       action: () => {
    //         history.push('/');
    //       },
    //     },
    //     {
    //       label: 'Supplier Import Details-EV-For Tier II & III',
    //       next: null,
    //       level: 1,
    //       action: () => {
    //         history.push('/');
    //       },
    //     }
    //   ],
    //   level: 1,
    //   action: null
    // },

    {
      label: "AFS Report",
      next: null,
      // [
      //   {
      //     label: 'AFSMIS',
      //     next: null,
      //     level: 2,
      //     action: () => {
      //       history.push('/AFSMIS');
      //     },
      //   },

      // ],
      level: 1,
      action: () => {
        history.push('/AFSMIS');
      },
    },

    // Base Sheet Data Section
    // {
    //   label: "Base Sheet Data",
    //   next: [
    //     {
    //       label: 'Base Sheet Data (CV)',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/BaseSheetData');  
    //       },
    //     },
    //     {
    //       label: 'Base Sheet Data (PV)',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/BaseSheetDataPV');  
    //       },
    //     },

    //   ],
    //   level: 1,
    //   action: null
    // },

    // {
    //   label: "TML Data Sheet",
    //   next: [
    //     {
    //       label: 'TML Data Sheet (CV)',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/TMLDataSheet');  
    //       },
    //     },
    //     {
    //       label: 'TML Data Sheet (PV)',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/TMLDataSheetPV');  
    //       },
    //     },

    //   ],
    //   level: 1,
    //   action: null
    // },

    // {
    //   label: "Part Level Data",
    //   next: [
    //     {
    //       label: 'Part Level Data (CV)',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/PartLevelData');  
    //       },
    //     },
    //     {
    //       label: 'Part Level Data (PV)',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/PartLevelDataPV');  
    //       },
    //     },

    //   ],
    //   level: 1,
    //   action: null
    // },

    // {
    //   label: "Vendor Part Data",
    //   next: [
    //     {
    //       label: 'Vendor Part Data (CV)',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/VendorPartData');  
    //       },
    //     },
    //     {
    //       label: 'Vendor Part Data (PV)',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/VendorPartDataPV');  
    //       },
    //     },

    //   ],
    //   level: 1,
    //   action: null
    // },

  ]
  //   Role Vendor CV
  const vendorCV = [{
    label: "Supplier DVA Declaration",
    next: [
      {
        label: 'Supplier DVA Declaration-CV',
        next: null,
        level: 2,
        action: () => {
          history.push('/supplier_import_details-cv');
        },
      },



    ],
    level: 1,
    action: null
  },];
  //   Role Vendor PV
  const vendor = [
    // {
    // label: "Supplier DVA Declaration",
    // next: [
    //   {
    //     label: 'Supplier DVA Declaration-CV21',
    //     next: null,
    //     level: 2,
    //     action: () => {
    //       history.push('/supplier_import_details-cv');
    //     },
    //   },

      // {
      //   label: 'Supplier DVA Declaration-PV',
      //   next: null,
      //   level: 2,
      //   action: () => {
      //     history.push('/vendor-data-pv');
      //   },
      // },
      // {
      //   label: 'Supplier DVA Declaration-EV',
      //   next: null,
      //   level: 2,
      //   action: () => {
      //     history.push('/');
      //   },
      // }


    // ],
    // level: 1,
    // action: null
  // },
    // {
    //   label: "Annual Financial Statement",
    //   next: null,
    //   // [
    //   //   {
    //   //     label: 'Annual Financial Statement Supplier Data',
    //   //     next: null,
    //   //     level: 2,
    //   //     action: () => {
    //   //       history.push('/supplier_import_details-cv');
    //   //     }
    //   //   }
    //   // ],
    //   level: 1,
    //   action: () => {
    //     history.push('/supplier_import_details-cv');
    //   }
    // },
    {
      label: "Annual Financial Details Form",
      next: null,
      level: 1,
      action: () => {
        history.push('/annual_financial_statement');
      }
    },

  ];
  const vendorPV = [{
    label: "Supplier DVA Declaration",
    next: [

      {
        label: 'Supplier DVA Declaration-PV',
        next: null,
        level: 2,
        action: () => {
          history.push('/vendor-data-pv');
        },
      },


    ],
    level: 1,
    action: null
  },];


  //   Role Vendor EV
  const vendorEV = [{
    label: "Supplier DVA Declaration",
    next: [

      {
        label: 'Supplier DVA Declaration-EV',
        next: null,
        level: 2,
        action: () => {
          history.push('/');
        },
      }

    ],
    level: 1,
    action: null
  },];




  const roleGDC = [
    {
      label: "Milestone Calendar",
      next: [
        {
          label: 'Calendar-CV',
          next: null,
          level: 2,
          action: () => {
            history.push('/milestones-calendar-cv');
          },
        },
        {
          label: 'Calendar-PV',
          next: null,
          level: 2,
          action: () => {
            history.push('/milestones-calendar-pv');
          },
        },
        {
          label: 'Calendar-EV',
          next: null,
          level: 1,
          action: () => {
            history.push('/');
          },
        }


      ],
      level: 1,
      action: null
    },
    {
      label: "Mass VC Declaration",
      next: [
        {
          label: 'Mass VC Declaration-CV',
          next: null,
          level: 2,
          action: () => {
            history.push('/vcbc-list-cv');
          },
        },
        {
          label: 'Mass VC Declaration-PV',
          next: null,
          level: 2,
          action: () => {
            history.push('/vcbc-list-pv');
          },
        },
        {
          label: 'Mass VC Declaration-EV',
          next: null,
          level: 2,
          action: () => {
            history.push('/');
          },
        },

      ],
      level: 1,
      action: null
    },
    {
      label: "VC BoM Data",
      next: [
        {
          label: 'VC BoM Data-CV',
          next: null,
          level: 2,
          action: () => {
            history.push('/vcbom-data');
          },
        },
        {
          label: 'VC BoM Data-PV',
          next: null,
          level: 2,
          action: () => {
            history.push('/vcbom-data-pv');
          },
        },
        {
          label: 'VC BoM Data-EV',
          next: null,
          level: 2,
          action: () => {
            history.push('/');
          },
        },

      ],
      level: 1,
      action: null
    },
    // {
    //   label: "Vendor DVA Declaration",
    //   next: [
    //     {
    //       label: 'Vendor DVA Declaration-CV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/vendor-data');
    //       },
    //     },
    //     {
    //       label: 'Vendor DVA Declaration-PV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/vendor-data-pv');
    //       },
    //     },
    //     {
    //       label: 'Vendor DVA Declaration-EV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/');
    //       },
    //     }

    //   ],
    //   level: 1,
    //   action: null
    // }
    {
      label: "Supplier Certification",
      next: [
        {
          label: 'Supplier Certification-CV',
          next: null,
          level: 1,
          action: () => {
            history.push('/vendor-certificate');
          },
        },
        {
          label: 'Supplier Certification-PV',
          next: null,
          level: 1,
          action: () => {
            history.push('/vendor-certificate-pv');
          },
        },
        {
          label: 'Supplier Certification-EV',
          next: null,
          level: 1,
          action: () => {
            history.push('/vendor-certificate-ev');
          },
        },
      ],
      level: 1,
      action: null
    },


  ];

  // purchase

  const rolePurchase = [
    {
      label: 'Annual Financial Details Form',
      next: null,
      level: 1,
      action: () => {
        history.push('/annual_financial_statement')
      }
    },

    // {
    //   label: "Milestone Calendar",
    //   next: [
    //     {
    //       label: 'Calendar-CV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/milestones-calendar-cv');
    //       },
    //     },
    //     {
    //       label: 'Calendar-PV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/milestones-calendar-pv');
    //       },
    //     },
    //     {
    //       label: 'Calendar-EV',
    //       next: null,
    //       level: 1,
    //       action: () => {
    //         history.push('/');
    //       },
    //     }


    //   ],
    //   level: 1,
    //   action: null
    // },
    // {
    //   label: "Mass VC Declaration",
    //   next: [
    //     {
    //       label: 'Mass VC Declaration-CV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/vcbc-list-cv');
    //       },
    //     },
    //     {
    //       label: 'Mass VC Declaration-PV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/vcbc-list-pv');
    //       },
    //     },
    //     {
    //       label: 'Mass VC Declaration-EV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/');
    //       },
    //     },

    //   ],
    //   level: 1,
    //   action: null
    // },
    // {
    //   label: "VC BoM Data",
    //   next: [
    //     {
    //       label: 'VC BoM Data-CV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/vcbom-data');
    //       },
    //     },
    //     {
    //       label: 'VC BoM Data-PV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/vcbom-data-pv');
    //       },
    //     },
    //     {
    //       label: 'VC BoM Data-EV',
    //       next: null,
    //       level: 2,
    //       action: () => {
    //         history.push('/');
    //       },
    //     },

    //   ],
    //   level: 1,
    //   action: null
    // },

    // {
    //   label: "Supplier Certification",
    //   next: [
    //     {
    //       label: 'Supplier Certification-CV',
    //       next: null,
    //       level: 1,
    //       action: () => {
    //         history.push('/vendor-certificate');
    //       },
    //     },
    //     {
    //       label: 'Supplier Certification-PV',
    //       next: null,
    //       level: 1,
    //       action: () => {
    //         history.push('/vendor-certificate-pv');
    //       },
    //     },
    //     {
    //       label: 'Supplier Certification-EV',
    //       next: null,
    //       level: 1,
    //       action: () => {
    //         history.push('/vendor-certificate-ev');
    //       },
    //     },
    //   ],
    //   level: 1,
    //   action: null
    // },

    {
      label: 'AFS Report',
      next: null,
      level: 1,
      action: () => {
        history.push('/AFSMIS')
      }
    },
    // {
    //   label: "Annual Financial Statement - Purchase",
    //   next: null,
    //   level: 1,
    //   action: () => {
    //     history.push('/AFSpurchase')
    //   }
    // },
  ];

  const rolePurchaseCV = [
    {
      label: "Milestone Calendar",
      next: [
        {
          label: 'Calendar-CV',
          next: null,
          level: 2,
          action: () => {
            history.push('/milestones-calendar-cv');
          },
        },
      ],
      level: 1,
      action: null
    },
    {
      label: "Mass VC Declaration",
      next: [
        {
          label: 'Mass VC Declaration-CV',
          next: null,
          level: 2,
          action: () => {
            history.push('/vcbc-list-cv');
          },
        }

      ],
      level: 1,
      action: null
    },
    {
      label: "VC BoM Data",
      next: [
        {
          label: 'VC BoM Data-CV',
          next: null,
          level: 2,
          action: () => {
            history.push('/vcbom-data');
          },
        },
      ],
      level: 1,
      action: null
    },

    {
      label: "Supplier Certification",
      next: [
        {
          label: 'Supplier Certification-CV',
          next: null,
          level: 1,
          action: () => {
            history.push('/vendor-certificate');
          },
        },

      ],
      level: 1,
      action: null
    },

  ];

  const rolePurchasePV = [
    {
      label: "Milestone Calendar",
      next: [

        {
          label: 'Calendar-PV',
          next: null,
          level: 2,
          action: () => {
            history.push('/milestones-calendar-pv');
          },
        },



      ],
      level: 1,
      action: null
    },
    {
      label: "Mass VC Declaration",
      next: [

        {
          label: 'Mass VC Declaration-PV',
          next: null,
          level: 2,
          action: () => {
            history.push('/vcbc-list-pv');
          },
        },


      ],
      level: 1,
      action: null
    },
    {
      label: "VC BoM Data",
      next: [

        {
          label: 'VC BoM Data-PV',
          next: null,
          level: 2,
          action: () => {
            history.push('/vcbom-data-pv');
          },
        },


      ],
      level: 1,
      action: null
    },

    {
      label: "Supplier Certification",
      next: [

        {
          label: 'Supplier Certification-PV',
          next: null,
          level: 1,
          action: () => {
            history.push('/vendor-certificate-pv');
          },
        },

      ],
      level: 1,
      action: null
    },


  ];

  // product line 

  const roleProductLine = [
    {
      label: "Milestone Calendar",
      next: [
        {
          label: 'Calendar-CV',
          next: null,
          level: 2,
          action: () => {
            history.push('/milestones-calendar-cv');
          },
        },
        {
          label: 'Calendar-PV',
          next: null,
          level: 2,
          action: () => {
            history.push('/milestones-calendar-pv');
          },
        },
        {
          label: 'Calendar-EV',
          next: null,
          level: 1,
          action: () => {
            history.push('/');
          },
        }


      ],
      level: 1,
      action: null
    },


    {
      label: "VC Declaration",
      next: [
        {
          label: 'VC Declaration-CV',
          next: null,
          level: 2,
          action: () => {
            history.push('/add-vcbc-cv');
          },
        },
        {
          label: 'VC Declaration-PV',
          next: null,
          level: 2,
          action: () => {
            history.push('/add-vcbc-pv');
          },
        },
        {
          label: 'VC Declaration-EV',
          next: null,
          level: 2,
          action: () => {
            history.push('/');
          },
        },

      ],
      level: 1,
      action: null
    },

    {
      label: "Mass VC Declaration",
      next: [
        {
          label: 'Mass VC Declaration-CV',
          next: null,
          level: 2,
          action: () => {
            history.push('/vcbc-list-cv');
          },
        },
        {
          label: 'Mass VC Declaration-PV',
          next: null,
          level: 2,
          action: () => {
            history.push('/vcbc-list-pv');
          },
        },
        {
          label: 'Mass VC Declaration-EV',
          next: null,
          level: 2,
          action: () => {
            history.push('/');
          },
        },
        // {
        //   component: 'PLIList',
        //   url: '/vcbc-list-cv',
        //   label: "Mass VC Declaration"
        // },
        // {
        //   component: 'PLIListPV',
        //   url: '/vcbc-list-pv',
        //   label: "Mass VC Declaration"
        // },
        // {
        //   component: 'PLIListEV',
        //   url: '/vcbc-list-ev',
        //   label: "Mass VC Declaration"
        // },

      ],
      level: 1,
      action: null
    },
    {
      label: "VC BoM Data",
      next: [
        {
          label: 'VC BoM Data-CV',
          next: null,
          level: 2,
          action: () => {
            history.push('/vcbom-data');
          },
        },
        {
          label: 'VC BoM Data-PV',
          next: null,
          level: 2,
          action: () => {
            history.push('/vcbom-data-pv');
          },
        },
        {
          label: 'VC BoM Data-EV',
          next: null,
          level: 2,
          action: () => {
            history.push('/');
          },
        },

      ],
      level: 1,
      action: null
    },
    {
      label: "DVA Calculation",
      next: [
        {
          label: 'DVA Calculation CV',
          next: null,
          level: 2,
          action: () => {
            history.push('/dva-calculation-cv');
          },
        },
        {
          label: 'DVA Calculation PV',
          next: null,
          level: 2,
          action: () => {
            history.push('/dva-calculation-pv');
          },
        },
        {
          label: 'DVA Calculation EV',
          next: null,
          level: 3,
          action: () => {
            history.push('/');
          },
        },
      ],
      level: 1,
      action: null
    },
    {
      label: "MHI Data",
      next: [
        {
          label: 'MHI Data CV',
          next: null,
          level: 2,
          action: () => {
            history.push('/mhi-data-cv');
          },
        },
        {
          label: 'MHI Data PV',
          next: null,
          level: 2,
          action: () => {
            history.push('/mhi-data-pv');
          },
        },
        {
          label: 'MHI Data EV',
          next: null,
          level: 2,
          action: () => {
            history.push('/');
          },
        },
      ],
      level: 1,
      action: null
    },


  ];

  // Newly added TMLBSL role
  const roleTMLBSL = [
    {
      label: 'Annual Financial Details Form',
      next: null,
      level: 1,
      action: () => {
        history.push('/annual_financial_statement')
      }
    },
    {
      label: 'AFS Report',
      next: null,
      level: 1,
      action: () => {
        history.push('/AFSMIS')
      }
    },
  ]

  useEffect(() => {
    // const role = auth.userRole;
    if (role?.includes("admin", "Purchase", "Business-Finance", "Govt", "TMLBSL")) {
      setColumnsRoot(adminMenu)
    }
    else if (role.includes("Vendor")) {
      setColumnsRoot(vendor)

    }
    else if (role.includes("Vendor-CV")) {
      setColumnsRoot(vendorCV)

    }
    else if (role.includes("Vendor-PV")) {
      setColumnsRoot(vendorPV)
    }
    else if (role.includes("Vendor-EV")) {
      setColumnsRoot(vendorEV)
    }

    else if (role.includes("GDC")) {
      setColumnsRoot(roleGDC)
    }
    else if (role.includes("Purchase")) {
      setColumnsRoot(rolePurchase)
    }
    else if (role.includes("Purchase-CV")) {
      setColumnsRoot(rolePurchaseCV)
    }
    else if (role.includes("Purchase-PV")) {
      setColumnsRoot(rolePurchasePV)
    }
    else if (role.includes("Product-line")) {
      setColumnsRoot(roleProductLine)

    }
    else if (role.includes("TMLBSL")) {
      setColumnsRoot(roleTMLBSL)
    }

    if(auth?.userRole?.length > 0){
      let datas=JSON.stringify(auth.userRole[0])
      localStorage.setItem("pli_role",datas)
    }


  }, [auth.userRole])

  // useEffect(()=>{
  //   if(menu?.menu?.userRoleMenu?.length > 0){
  //     let datas=JSON.stringify(menu.menu.userRoleMenu[0].user_role)
  //     localStorage.setItem("role",datas)
  //   }
  // },[menu])

  // useEffect(() => {
  //   if (
  //     authorizedApps.length &&
  //     !authorizedApps.includes(PPAP_APP_NAME)
  //   ) {
  //     showLoading();
  //     window.location.replace(config.HOME_UI_BASE_URL);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps


  // }, [authorizedApps, user.userID]);





  return (
    <div className={styles.container}>
      <div style={{ marginBottom: '1em' }}>
        <SubHeader title="Production Linked lncentive(PLI)" />
      </div>
      <div className={classFlag == 'center' ? clsx(styles.columnsContainer, styles.centerIMg) : clsx(styles.columnsContainer, styles.rightIMg)}>
        <div style={{ height: '100%' }} className={styles.columnsContainerLeft}>
          <ListView
            title="ACTION"
            listItems={columnsRoot}
            handleSelection={handleSelection}
          />
          {getArrayOfOptionSetsToRender(selectedColumnsQueue).map(
            (listItems, index) => (
              <ListView
                key={`${selectedColumnsQueue[0].label}-${listItems[0]?.level}`}
                title={index === 1 ? 'Action' : 'Select'}
                listItems={listItems}
                handleSelection={handleSelection}
                changeClass={changeClass}
              />
            ),
          )}
        </div>
        {/* <div style={{ height: '100%' }}>
          <ToolsListView
            listItems={toolOptions}
            handleSelection={handleSelection}
          />
        </div> */}
      </div>
    </div>
  )
}

export default LandingPage






// /* eslint-disable vars-on-top */
// /* eslint-disable prefer-const */
// /* eslint-disable no-var */
// /* eslint-disable no-redeclare */
// /* eslint-disable eqeqeq */
// /* eslint-disable array-callback-return */
// /* eslint-disable no-undef */
// /* eslint-disable no-unused-vars */
// import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux'

// import clsx from 'clsx';

// import { useHistory } from 'react-router-dom';
// import styles from './LandingPage.module.css'
// import { ListView, SubHeader, ToolsListView } from '../../components';
// import { USER_OPERATIONS, PPAP_APP_NAME, PERSONAS, TOOLS } from '../../constants';
// import { useAuthorizationContext } from '../../providers/AuthorizationHandler/AuthorizationHandler';
// import useAuthorizedEpApps from '../../hooks/useAuthorizedEpApps';
// import { usePopupManager } from '../../providers/PopupManager/PopupManager';
// import config from '../../config/config';




// // function LandingPage() {
// const LandingPage = () => {

//   const { isAuthenticated, user, authResources } = useAuthorizationContext();
//   const history = useHistory();

//   const [selectedColumnsQueue, setSelectedColumsQueue] = useState([]);
//   // eslint-disable-next-line no-unused-vars
//   const [columnsRoot, setColumnsRoot] = useState();
//   const [classFlag, setClass] = useState('center');
//   const authorizedApps = useAuthorizedEpApps(isAuthenticated, authResources);
//   const { showLoading } = usePopupManager();
//   const auth = useSelector(state => state.auth);

//   const changeClass=()=>{
//     setClass('right')
//    }
//   const handleSelection = selection =>
//     setSelectedColumsQueue(prev => {
//       changeClass();
//       if (prev.length) {
//         const lastSelection = prev[prev.length - 1];
//         if (lastSelection === selection) return prev;
//         while (prev.length) {
//           const existingTopLevel = prev[prev.length - 1].level;
//           if (existingTopLevel >= selection.level) prev.pop();
//           else break;
//         }
//       }
//       prev.push(selection);
//       return [...prev];
//     });



//   const getArrayOfOptionSetsToRender = queue =>
//     queue.map(item => item.next).filter(item => item);

//   const getToolOptions = () =>
//     [

//       {
//         label: 'Barcode Pattern',
//         action: () => {
//           history.push('/document-master');
//         },
//         authOperation: USER_OPERATIONS.CREATE_PPAP_STAGE_DOCUMENT,
//         testId: 'document-master-btn'
//       },



//     ];

//   const toolOptions = getToolOptions();
//   const urlBaseAndPersonaMap = new Map();
//   urlBaseAndPersonaMap.set(PERSONAS.BUYER, 'buyer');
//   urlBaseAndPersonaMap.set(PERSONAS.VENDOR, 'vendor');
//   urlBaseAndPersonaMap.set(PERSONAS.FBV_VENDOR, 'vendor');
//   urlBaseAndPersonaMap.set(PERSONAS.FBV, 'fbv');
//   const deriveNextLaneForMaterialGroups = (persona, materialGroup) => {
//     const urlBaseForPersona = urlBaseAndPersonaMap.get(persona);
//     if (persona === PERSONAS.BUYER)
//       return [
//         {
//           label: 'View Plan',
//           next: null,
//           level: 3,
//           action: () => {
//             history.push(`/${urlBaseForPersona}/${materialGroup}`);
//           },
//         },
//         {
//           label: 'ASN DETAILS',
//           next: null,
//           level: 3,
//           action: () => {
//             history.push(`/${urlBaseForPersona}/${materialGroup}/asn-details`);
//           },
//         },
//       ];
//     return [
//       {
//         label: 'Plan',
//         next: null,
//         level: 3,
//         action: () => {
//           history.push(`/${urlBaseForPersona}/${materialGroup}`);
//         },
//       },
//       {
//         label: 'Drop',
//         next: null,
//         level: 3,
//         action: () => {
//           history.push(`/${urlBaseForPersona}/${materialGroup}/drop`);
//         },
//       },
//       {
//         label: 'Dispatch',
//         next: null,
//         level: 3,
//         action: () => {
//           history.push(`/${urlBaseForPersona}/${materialGroup}/dispatch`);
//         },
//       },
//     ];
//   };

//   const deriveMaterialGroupLaneFromMaterialGroups = (
//     userPersona,
//     materialGroups,
//   ) =>
//     materialGroups.map(record => ({
//       value: record.materialGroup,
//       label: record.description,
//       level: 2,
//       next: deriveNextLaneForMaterialGroups(userPersona, record.materialGroup), // step 4
//       action: null,
//     }));

//   //  Admin Role
//   const adminMenu = [
    
//     {
//       label: "Milestone Calendar",
//       next: [
//         {
//           label: 'Calendar-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/milestones-calendar-cv');
//           },
//         },
//         {
//           label: 'Calendar-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/milestones-calendar-pv');
//           },
//         },
//         {
//           label: 'Calendar-EV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/');
//           },
//         }


//       ],
//       level: 1,
//       action: null
//     },


//     {
//       label: "VC Declaration",
//       next: [
//         {
//           label: 'VC Declaration-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/add-vcbc-cv');
//           },
//         },
//         {
//           label: 'VC Declaration-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/add-vcbc-pv');
//           },
//         },
//         {
//           label: 'VC Declaration-EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         },

//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "Mass VC Declaration",
//       next: [
//         {
//           label: 'Mass VC Declaration-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbc-list-cv');
//           },
//         },
//         {
//           label: 'Mass VC Declaration-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbc-list-pv');
//           },
//         },
//         {
//           label: 'Mass VC Declaration-EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         },

//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "VC BoM Data",
//       next: [
//         {
//           label: 'VC BoM Data-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbom-data');
//           },
//         },
//         {
//           label: 'VC BoM Data-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbom-data-pv');
//           },
//         },
//         {
//           label: 'VC BoM Data-EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         },

//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "Vendor DVA Declaration",
//       next: [
//         {
//           label: 'Vendor DVA Declaration-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vendor-data');
//           },
//         },
//         {
//           label: 'Vendor DVA Declaration-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vendor-data-pv');
//           },
//         },
//         {
//           label: 'Vendor DVA Declaration-EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         }

//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "Vendor Certification",
//       next: [
//         {
//           label: 'Vendor Certification-CV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/vendor-certificate');
//           },
//         },
//         {
//           label: 'Vendor Certification-PV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/vendor-certificate-pv');
//           },
//         },
//         {
//           label: 'Vendor Certification-EV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/vendor-certificate-ev');
//           },
//         },
//       ],
//       level: 1,
//       action: null
//     },


//     {
//       label: "VC MIS",
//       next: [
//         {
//           label: 'VC MIS-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/colour-vc-mis');
//           },
//         },
//         {
//           label: 'VC MIS-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/colour-vc-mis-pv');
//           },
//         },
//         {
//           label: 'VC MIS-EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         },

//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "Vendor MIS",
//       next: [
//         {
//           label: 'Vendor MIS-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vendor-mis');
//           },
//         },
//         {
//           label: 'Vendor MIS-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vendor-mis-pv');
//           },
//         },
//         {
//           label: 'Vendor MIS-EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         },

//       ],
//       level: 1,
//       action: null
//     },

    
//     {
//       label: "DVA Calculation",
//       next: [
//         {
//           label: 'DVA Calculation CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/dva-calculation-cv');
//           },
//         },
//         {
//           label: 'DVA Calculation PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/dva-calculation-pv');
//           },
//         },
//         {
//           label: 'DVA Calculation EV',
//           next: null,
//           level: 3,
//           action: () => {
//             history.push('/');
//           },
//         },
//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "MHI Data",
//       next: [
//         {
//           label: 'MHI Data CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/mhi-data-cv');
//           },
//         },
//         {
//           label: 'MHI Data PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/mhi-data-pv');
//           },
//         },
//         {
//           label: 'MHI Data EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         },
//       ],
//       level: 1,
//       action: null
//     },

//     // {
//     //   label: "Audit Report",
//     //   next: [
//     //     {
//     //       label: 'Report-CV',
//     //       next: null,
//     //       level: 2,
//     //       action: () => {
//     //         history.push('/audit-report-cv');
//     //       },
//     //     },
//     //     {
//     //       label: 'Report-PV',
//     //       next: null,
//     //       level: 2,
//     //       action: () => {
//     //         history.push('/audit-report-pv');
//     //       },
//     //     },
//     //     {
//     //       label: 'Report-EV',
//     //       next: null,
//     //       level: 1,
//     //       action: () => {
//     //         history.push('/');
//     //       },
//     //     }


//     //   ],
//     //   level: 1,
//     //   action: null
//     // },

//   ]
//   //   Role Vendor CV
//   const vendorCV = [{
//     label: "Vendor DVA Declaration",
//     next: [
//       {
//         label: 'Vendor DVA Declaration-CV',
//         next: null,
//         level: 2,
//         action: () => {
//           history.push('/vendor-data');
//         },
//       },
      


//     ],
//     level: 1,
//     action: null
//   },];
//   //   Role Vendor PV
//   const vendor = [{
//     label: "Vendor DVA Declaration",
//     next: [
//       {
//         label: 'Vendor DVA Declaration-CV',
//         next: null,
//         level: 2,
//         action: () => {
//           history.push('/vendor-data');
//         },
//       },

//       {
//         label: 'Vendor DVA Declaration-PV',
//         next: null,
//         level: 2,
//         action: () => {
//           history.push('/vendor-data-pv');
//         },
//       },
//       {
//         label: 'Vendor DVA Declaration-EV',
//         next: null,
//         level: 2,
//         action: () => {
//           history.push('/');
//         },
//       }


//     ],
//     level: 1,
//     action: null
//   },];
//   const vendorPV = [{
//     label: "Vendor DVA Declaration",
//     next: [

//       {
//         label: 'Vendor DVA Declaration-PV',
//         next: null,
//         level: 2,
//         action: () => {
//           history.push('/vendor-data-pv');
//         },
//       },


//     ],
//     level: 1,
//     action: null
//   },];


//   //   Role Vendor EV
//   const vendorEV = [{
//     label: "Vendor DVA Declaration",
//     next: [

//       {
//         label: 'Vendor DVA Declaration-EV',
//         next: null,
//         level: 2,
//         action: () => {
//           history.push('/');
//         },
//       }

//     ],
//     level: 1,
//     action: null
//   },];




//   const roleGDC = [
//     {
//       label: "Milestone Calendar",
//       next: [
//         {
//           label: 'Calendar-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/milestones-calendar-cv');
//           },
//         },
//         {
//           label: 'Calendar-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/milestones-calendar-pv');
//           },
//         },
//         {
//           label: 'Calendar-EV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/');
//           },
//         }


//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "Mass VC Declaration",
//       next: [
//         {
//           label: 'Mass VC Declaration-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbc-list-cv');
//           },
//         },
//         {
//           label: 'Mass VC Declaration-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbc-list-pv');
//           },
//         },
//         {
//           label: 'Mass VC Declaration-EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         },

//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "VC BoM Data",
//       next: [
//         {
//           label: 'VC BoM Data-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbom-data');
//           },
//         },
//         {
//           label: 'VC BoM Data-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbom-data-pv');
//           },
//         },
//         {
//           label: 'VC BoM Data-EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         },

//       ],
//       level: 1,
//       action: null
//     },
//     // {
//     //   label: "Vendor DVA Declaration",
//     //   next: [
//     //     {
//     //       label: 'Vendor DVA Declaration-CV',
//     //       next: null,
//     //       level: 2,
//     //       action: () => {
//     //         history.push('/vendor-data');
//     //       },
//     //     },
//     //     {
//     //       label: 'Vendor DVA Declaration-PV',
//     //       next: null,
//     //       level: 2,
//     //       action: () => {
//     //         history.push('/vendor-data-pv');
//     //       },
//     //     },
//     //     {
//     //       label: 'Vendor DVA Declaration-EV',
//     //       next: null,
//     //       level: 2,
//     //       action: () => {
//     //         history.push('/');
//     //       },
//     //     }

//     //   ],
//     //   level: 1,
//     //   action: null
//     // }
//     {
//       label: "Vendor Certification",
//       next: [
//         {
//           label: 'Vendor Certification-CV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/vendor-certificate');
//           },
//         },
//         {
//           label: 'Vendor Certification-PV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/vendor-certificate-pv');
//           },
//         },
//         {
//           label: 'Vendor Certification-EV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/vendor-certificate-ev');
//           },
//         },
//       ],
//       level: 1,
//       action: null
//     },
 

//   ];

//   // purchase

//   const rolePurchase = [
//     {
//       label: "Milestone Calendar",
//       next: [
//         {
//           label: 'Calendar-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/milestones-calendar-cv');
//           },
//         },
//         {
//           label: 'Calendar-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/milestones-calendar-pv');
//           },
//         },
//         {
//           label: 'Calendar-EV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/');
//           },
//         }


//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "Mass VC Declaration",
//       next: [
//         {
//           label: 'Mass VC Declaration-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbc-list-cv');
//           },
//         },
//         {
//           label: 'Mass VC Declaration-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbc-list-pv');
//           },
//         },
//         {
//           label: 'Mass VC Declaration-EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         },

//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "VC BoM Data",
//       next: [
//         {
//           label: 'VC BoM Data-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbom-data');
//           },
//         },
//         {
//           label: 'VC BoM Data-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbom-data-pv');
//           },
//         },
//         {
//           label: 'VC BoM Data-EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         },

//       ],
//       level: 1,
//       action: null
//     },
 
//     {
//       label: "Vendor Certification",
//       next: [
//         {
//           label: 'Vendor Certification-CV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/vendor-certificate');
//           },
//         },
//         {
//           label: 'Vendor Certification-PV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/vendor-certificate-pv');
//           },
//         },
//         {
//           label: 'Vendor Certification-EV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/vendor-certificate-ev');
//           },
//         },
//       ],
//       level: 1,
//       action: null
//     },
 

//   ];

//   const rolePurchaseCV = [
//     {
//       label: "Milestone Calendar",
//       next: [
//         {
//           label: 'Calendar-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/milestones-calendar-cv');
//           },
//         }, 
//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "Mass VC Declaration",
//       next: [
//         {
//           label: 'Mass VC Declaration-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbc-list-cv');
//           },
//         } 

//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "VC BoM Data",
//       next: [
//         {
//           label: 'VC BoM Data-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbom-data');
//           },
//         }, 
//       ],
//       level: 1,
//       action: null
//     },
 
//     {
//       label: "Vendor Certification",
//       next: [
//         {
//           label: 'Vendor Certification-CV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/vendor-certificate');
//           },
//         },
   
//       ],
//       level: 1,
//       action: null
//     }, 

//   ];

//   const rolePurchasePV = [
//     {
//       label: "Milestone Calendar",
//       next: [
   
//         {
//           label: 'Calendar-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/milestones-calendar-pv');
//           },
//         },



//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "Mass VC Declaration",
//       next: [
   
//         {
//           label: 'Mass VC Declaration-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbc-list-pv');
//           },
//         },
 

//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "VC BoM Data",
//       next: [
  
//         {
//           label: 'VC BoM Data-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbom-data-pv');
//           },
//         },
  

//       ],
//       level: 1,
//       action: null
//     },
 
//     {
//       label: "Vendor Certification",
//       next: [
 
//         {
//           label: 'Vendor Certification-PV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/vendor-certificate-pv');
//           },
//         },
   
//       ],
//       level: 1,
//       action: null
//     },
 

//   ];

//   // product line 

//   const roleProductLine = [
//     {
//       label: "Milestone Calendar",
//       next: [
//         {
//           label: 'Calendar-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/milestones-calendar-cv');
//           },
//         },
//         {
//           label: 'Calendar-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/milestones-calendar-pv');
//           },
//         },
//         {
//           label: 'Calendar-EV',
//           next: null,
//           level: 1,
//           action: () => {
//             history.push('/');
//           },
//         }


//       ],
//       level: 1,
//       action: null
//     },
 

//     {
//       label: "VC Declaration",
//       next: [
//         {
//           label: 'VC Declaration-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/add-vcbc-cv');
//           },
//         },
//         {
//           label: 'VC Declaration-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/add-vcbc-pv');
//           },
//         },
//         {
//           label: 'VC Declaration-EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         },

//       ],
//       level: 1,
//       action: null
//     },
    
//     {
//       label: "Mass VC Declaration",
//       next: [
//         {
//           label: 'Mass VC Declaration-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbc-list-cv');
//           },
//         },
//         {
//           label: 'Mass VC Declaration-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbc-list-pv');
//           },
//         },
//         {
//           label: 'Mass VC Declaration-EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         },
//         // {
//         //   component: 'PLIList',
//         //   url: '/vcbc-list-cv',
//         //   label: "Mass VC Declaration"
//         // },
//         // {
//         //   component: 'PLIListPV',
//         //   url: '/vcbc-list-pv',
//         //   label: "Mass VC Declaration"
//         // },
//         // {
//         //   component: 'PLIListEV',
//         //   url: '/vcbc-list-ev',
//         //   label: "Mass VC Declaration"
//         // },

//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "VC BoM Data",
//       next: [
//         {
//           label: 'VC BoM Data-CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbom-data');
//           },
//         },
//         {
//           label: 'VC BoM Data-PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/vcbom-data-pv');
//           },
//         },
//         {
//           label: 'VC BoM Data-EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         },

//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "DVA Calculation",
//       next: [
//         {
//           label: 'DVA Calculation CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/dva-calculation-cv');
//           },
//         },
//         {
//           label: 'DVA Calculation PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/dva-calculation-pv');
//           },
//         },
//         {
//           label: 'DVA Calculation EV',
//           next: null,
//           level: 3,
//           action: () => {
//             history.push('/');
//           },
//         },
//       ],
//       level: 1,
//       action: null
//     },
//     {
//       label: "MHI Data",
//       next: [
//         {
//           label: 'MHI Data CV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/mhi-data-cv');
//           },
//         },
//         {
//           label: 'MHI Data PV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/mhi-data-pv');
//           },
//         },
//         {
//           label: 'MHI Data EV',
//           next: null,
//           level: 2,
//           action: () => {
//             history.push('/');
//           },
//         },
//       ],
//       level: 1,
//       action: null
//     },
//   ];



//   useEffect(() => {
//     const role = auth.userRole;
//     if (role.includes("admin", "Purchase", "Business-Finance", "Govt")) {
//       setColumnsRoot(adminMenu)
//     }
//     else if (role.includes("Vendor")) {
//       setColumnsRoot(vendor)

//     }
//     else if (role.includes("Vendor-CV")) {
//       setColumnsRoot(vendorCV)

//     }
//     else if (role.includes("Vendor-PV")) {
//       setColumnsRoot(vendorPV)  
//     }
//     else if (role.includes("Vendor-EV")) {
//       setColumnsRoot(vendorEV) 
//     }

//     else if (role.includes("GDC")) {
//       setColumnsRoot(roleGDC) 
//     }
//     else if (role.includes("Purchase")) {
//       setColumnsRoot(rolePurchase) 
//     }
//     else if (role.includes("Purchase-CV")) {
//       setColumnsRoot(rolePurchaseCV) 
//     }
//     else if (role.includes("Purchase-PV")) {
//       setColumnsRoot(rolePurchasePV) 
//     }
//     else if (role.includes("Product-line")) {
//       setColumnsRoot(roleProductLine)

//     }


//   }, [auth.userRole])

//   // useEffect(() => {
//   //   if (
//   //     authorizedApps.length &&
//   //     !authorizedApps.includes(PPAP_APP_NAME)
//   //   ) {
//   //     showLoading();
//   //     window.location.replace(config.HOME_UI_BASE_URL);
//   //   }
//   //   // eslint-disable-next-line react-hooks/exhaustive-deps


//   // }, [authorizedApps, user.userID]);





//   return (
//     <div className={styles.container}>
//       <div style={{ marginBottom: '1em' }}>
//         <SubHeader title="Production Linked lncentive(PLI)" />
//       </div>
//       <div className={ classFlag=='center'?clsx(styles.columnsContainer,styles.centerIMg):clsx(styles.columnsContainer,styles.rightIMg)}>
//         <div style={{ height: '100%' }} className={styles.columnsContainerLeft}>
//           <ListView
//             title="ACTION"
//             listItems={columnsRoot}
//             handleSelection={handleSelection}
//           />
//           {getArrayOfOptionSetsToRender(selectedColumnsQueue).map(
//             (listItems, index) => (
//               <ListView
//                 key={`${selectedColumnsQueue[0].label}-${listItems[0]?.level}`}
//                 title={index === 1 ? 'Action' : 'Select'}
//                 listItems={listItems}
//                 handleSelection={handleSelection}
//                 changeClass={changeClass}
//               />
//             ),
//           )}
//         </div>
//         {/* <div style={{ height: '100%' }}>
//           <ToolsListView
//             listItems={toolOptions}
//             handleSelection={handleSelection}
//           />
//         </div> */}
//       </div>
//     </div>
//   )
// }

// export default LandingPage
