/* eslint-disable react/self-closing-comp */
/* eslint-disable no-plusplus */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable import/named */
/* eslint-disable prefer-template */
/* eslint-disable one-var */
/* eslint-disable radix */
/* eslint-disable no-else-return */
/* eslint-disable no-shadow */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-var */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable object-shorthand */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable prefer-const */
/* eslint-disable dot-notation */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useReducer } from 'react'
import { connect, useDispatch } from 'react-redux'
import FileBase64 from 'react-file-base64';

import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import PropTypes from 'prop-types';
import clsx from 'clsx'
import moment from 'moment';

// import {  } from "@material-ui/core";
import { Modal, Dialog, DialogTitle, DialogContent, DialogActions, Form, Box, TextField, Grid, FormGroup, IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import { SearchOutlined, Update } from '@material-ui/icons';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ClearIcon from '@mui/icons-material/Clear';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';

import Select from '@mui/material/Select';
import { MdReplay, MdSystemUpdate } from 'react-icons/md';
import styles from './MilesStone.module.css'
import { CustomDatePicker, CustomFormGroup, CustomSelect, ValidatingTextField } from '../FormComponents'
import { DATE_FORMAT, API_RESOURCE_URLS, DISPLAY_MESSAGES, MESSAGE_TYPE, RESOURCE_TYPE, USER_OPERATIONS, Regex } from '../../constants'
import { API } from '../../apis/api'
import TextIcon from './TextIcon'
import { buildErrorMessage } from '../../apis/calls'
import Table from '../Table/Table';
import { usePopupManager } from '../../providers/PopupManager/PopupManager';
import { AuthChecker, Button } from '../../atomicComponents';
import { withAllowedOperationsProvider } from '../../hocs'
import { setTableRowData, setToast, setPORow, poSearch, fetchChassis, setChassisData, setAllDetails, getInvoice } from '../../redux/actions/productsActions';




const initialState = {
  plant: null,
  part: null,
  aqCommodity: null,
  purchaseCommodity: null,
  supplierCode: null,
  supplierDetails: null,
  supplierName: '',
  supplierLocation: '',
  selectedProject: null,
  selectedPO: null,
  inputFields: {
    purchaseBuyerName: '',
    suppRepresentativeName: '',
    suppRepresentativeContact: '',
    suppRepresentativeEmail: ''
  },
  errors: {},
}
const reducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return {
        ...state,
        [action.field]: action.value
      }
    case 'reset':
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}


function MilesStone({ tableData, product, counter, baseUrl, auth }) {
  const ROW_HEIGHT = 38;


  const { showInternalError } = usePopupManager();
  const { showPopup } = usePopupManager();
  const dispatch1 = useDispatch();
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);


  const { damage, invoice, plant, part, aqCommodity, purchaseCommodity, supplierCode, supplierDetails, supplierName,
    supplierLocation, selectedProject, selectedPO, inputFields, errors } = state;


  const [asnCount, setAsnCount] = useState(0);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [confirmModalFlag2, setConfirmModal2] = useState(false);
  const [resetAllVisitedFields, setResetAllVisitedFields] = useState(false);
  const [highlightMandatoryFields, setHighlightMandatoryFields] = useState(false);
  const [isDataLoading, setDataLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState([]);
  const [statusVal, setStatusVal] = useState([]);
  const [singleRow, setSingleRow] = useState([]);
  const [singleCheckRow, setSingleCheckRow] = useState(null);
  const [statusSelectVal, setStatusArray] = useState(null);
  const [statusRemark, setStatusRemark] = useState();
  const [RowsColumn, setRowsColumn] = useState([]);
  const [RowsColumn1, setRowsColumn1] = useState([]);
  const [excelRowData, setExcelRowData] = useState([]);
  const [hydraulicKitType, setHydraulicKitType] = useState(null);
  const [hydraulicKitSupplie, setHydraulicKitSupplie] = useState(null);
  const [remark, setRemark] = useState(null);
  const [openModal, setPpenModal] = useState(false);
  const [demeritTitle, setDemeritTitle] = useState();

  const [handoverDate, setHandoverDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [myState, setState] = React.useState(
    {
      PONo: "",
      vcbcNo: "",
      chassisNo: "",
      completeDate: "",
      bodyDesc: "",
      kitType: "",
      kitTypeSelect: "",
      supplier: "",
      kitTypeSuplyer: "",
      hydraulicKitsNo: "",
      showMain: false,
      submitBtn: true,
      checkPOError: false,
      checkVCError: false,
      errorFlagFyear: false,
      errorFlagFyearMsg: "",
      QuartersError: false,
      QuartersErrorMsg: "",
      rows: [{
        remark: "",
        status: "",
        rowId: 0,
      }],
      masterRows: [],
      checkType4: "Manually",
      fileObj: {},
      errorFlagFile: false,
      hydraulicKitNoChasis: "",
      year: null,
      quarter: null,





    });




  const resetError = (name) => {
    if (name == "PONo") {
      setState((prevData) => ({ ...prevData, checkPOError: false }));
    }
    else if (name == "vcbcNo") {
      setState((prevData) => ({ ...prevData, checkVCError: false }));
    }

    else if (name == "targetModalDate") {
      setState((prevData) => ({ ...prevData, checkDateError: false }));
    }


  }
  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    // alert(name)
    resetError(name);
    setState((prevData) => ({
      ...prevData, [name]: value
    }));


  }

  const handleDate = (date, name) => {
    //  let newvalue = convert(date);
    if (name == "reported") {

      setReportedDate(date.format(DATE_FORMAT.ISO))
      setState((prevData) => ({ ...prevData, errorFlagReported: false, errorReportedDateMsg: "" }));

    } else {
      setHandoverDate(date.format(DATE_FORMAT.ISO))

    }
  }

  useEffect(() => {
    console.log(tableData.tableRow.singleRow, "table Datdda", "row lenth", tableData.tableRow.singleRow.length)


  }, [tableData])
  useEffect(() => {
    console.log(statusVal, "statusVal")


  }, [statusVal])




  const handleSelect2 = (event, types, idx) => {

    const { value } = event.target

    //  const statusVal = statusVal
    var temp = [];
    var obj;
    // alert(statusVal.length)

    if (statusVal.length >= 1) {

      const arr = statusVal.map((item, idd) => {

        if (item.indnx == idx) {

          obj = {
            statusNew: value,
            indnx: idx
          }
          //   temp.push(obj)
        }
        else {

          obj = {
            statusNew: item.statusNew,
            indnx: item.indnx,
          }
          temp.push(obj)
        }
      });

      obj = {
        statusNew: value,
        indnx: idx
      }
      temp.push(obj)


    } else {
      obj = {
        statusNew: value,
        indnx: idx
      }
      temp.push(obj)

    }


    setStatusVal(temp)




  }

  useEffect(() => {
  }, [singleRow])
  useEffect(() => {

  }, [RowsColumn])
  useEffect(() => {
    if (selectedRow.length >= 1) {
      let check = valididateDate(); 
      if (check) {
        setState((prevData) => ({ ...prevData, submitBtn: false }));
        setRowsColumn(selectedRow)

      } else {
        setState((prevData) => ({ ...prevData, submitBtn: true }));
      }
    }

  }, [selectedRow])

  const valididateDate = () => {
    let lineCompare = selectedRow.every((elem, indx) => {

      var id = indx + 1;
      let PrevEndDate = elem.endDate;  
      let startDateFlag = new Date(elem.startDate);
      let endDateFlag = new Date(elem.endDate);
      if (startDateFlag < endDateFlag) {
        if (selectedRow.length > id) {
          var nextStartDate = selectedRow[id].startDate; 
          return checkEndDate2(PrevEndDate, nextStartDate)
        } else {
          return true;
        }

      } else {
        return false;
      }
    });

    return lineCompare;
  }
  const checkEndDate2 = (PrevEndDate, nextStartDate) => {
    let PrevEndDateFlag = new Date(PrevEndDate);
    let nextStartDateFlag = new Date(nextStartDate);
    if (nextStartDateFlag > PrevEndDateFlag) {
      return true;
    } else {
      return false;
    }

  }



  const setDate = (mileStone, mileStone1, startDate, endDate, id, row, rowIndex, selectedRoww, minimalDate, dateFlag) => {
    let obj;
    if (dateFlag == "startDate") {
      let obj1 = {
        id,
        mileStone,
        startDate,
        endDate,
        mileStone1,
        minimalDate: minimalDate
      }
      let rows = [...selectedRow]
      rows.splice(rowIndex, 1, obj1);
      setSelectedRow(rows)
    } else {

      //  alert(selectedRow.length+"=="+rowIndex)


      let rows = [...selectedRow]
      const finalList = rows
      const filterElement = rows.filter((elem) => (elem.id == id + 1))
      if (filterElement.length == 1) {
        let arrayNo = finalList.map(item => item.id).indexOf(id + 1);
        let obj2;
        if (finalList[arrayNo].mileStone1 == "BoM Explosion") {
          let endDate1 = moment(endDate, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD")

          obj2 = {
            id: finalList[arrayNo].id,
            mileStone: finalList[arrayNo].mileStone,
            startDate: moment(endDate, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD"),
            endDate: moment(endDate, "YYYY-MM-DD").add(2, 'days').format("YYYY-MM-DD"),
            mileStone1: finalList[arrayNo].mileStone1,
            minimalDate: endDate
          }
          rows[arrayNo + 1]
          if (rows[arrayNo + 1] !== undefined) {
            let obj3 = {
              id: rows[arrayNo + 1].id,
              mileStone: rows[arrayNo + 1].mileStone,
              startDate: rows[arrayNo + 1].startDate,
              endDate: rows[arrayNo + 1].endDate,
              mileStone1: rows[arrayNo + 1].mileStone1,
              minimalDate: moment(endDate, "YYYY-MM-DD").add(2, 'days').format("YYYY-MM-DD")
            }
            rows.splice(arrayNo + 1, 1, obj3);

          }



        } else {
          obj2 = {
            id: finalList[arrayNo].id,
            mileStone: finalList[arrayNo].mileStone,
            startDate: finalList[arrayNo].startDate,
            endDate: finalList[arrayNo].endDate,
            mileStone1: finalList[arrayNo].mileStone1,
            minimalDate: endDate
          }
        }


        rows.splice(arrayNo, 1, obj2);

        let obj3 = {
          id,
          mileStone,
          startDate,
          endDate,
          mileStone1,
          minimalDate: minimalDate
        }
        rows.splice(rowIndex, 1, obj3);
        setSelectedRow(rows)
      } else {

        let obj = {
          id,
          mileStone,
          startDate,
          endDate,
          mileStone1,
          minimalDate: minimalDate
        }
        let rows = [...selectedRow]
        rows.splice(rowIndex, 1, obj);
        setSelectedRow(rows)
      }



    }


  }


  const setData = (startDateText, endDateText, row, rowIndex) => {

    var tempArray;
    var masterArray = [];
    if (singleRow.length >= 1) {
      const allSateRow = singleRow;
      const currentRow = getLable(row);
      const obj = allSateRow.find(stateItem => stateItem.id === row.id);
      if (obj === undefined) {
        tempArray = allSateRow.concat(currentRow);

      } else {
        tempArray = allSateRow
      }
    }
    else {
      tempArray = getLable(row);
    }

    const updateArray = tempArray.map((item, idx) => {

      var obj = {}

      let mileStone = item.mileStone;
      let mileStone1 = item.mileStone1;
      var startDates = item.startDate;
      var endDates = item.endDate;
      let id = item.id
      let isSelected = item.isSelected
      if (item.id == row.id) {
        startDates = startDateText
        endDates = endDateText;
        obj = {
          id,
          //   isSelected,
          mileStone,
          "startDate": startDates,
          "endDate": endDates,
          mileStone1
        }

        masterArray.push(obj)

        updateTableRow(rowIndex, obj)
      }
      else {

        obj = {
          id,
          mileStone,
          //  isSelected,
          "startDate": startDates,
          "endDate": endDates,
          mileStone1
        }
        masterArray.push(obj)
        updateTableRow(rowIndex, obj)
      }


    });
    setSingleRow(masterArray)
    //  handleClose();
  }

  const updateTableRow = (idx, item) => {



    let rows2 = [...selectedRow]
    rows2.splice(idx, 1, item);
    setSelectedRow(rows2)

  }
  const updateData = (idx, item) => {
    let check = selectedRow.filter((selectedRowElement) => (selectedRowElement.id == idx));
    if (check.length === 0) {
      return false
    } else {
      return check[0]
    }

  }
  const dateValidation = (e) => {
    alert(e)
  }

  const updateTtableData = (RowsColum) => {
    var tempArray = []
    let rows2 = [...RowsColumn];
    rows2.map((itemInLoop, index) => {
      let item = updateData(itemInLoop.id);
      if (item == false) {
        tempArray.push(itemInLoop)

      } else {
        tempArray.push(item)
      }

    });

    setRowsColumn(tempArray)
    handleClose();

  }

  const getLable = (param) => {
    let tempArray = [];
    tempArray.push(param);
    // const dataArray = tempArray.map((item, id) => Object.assign(item, { remarkNew: "", statusNew: "" }))
    // return dataArray;
    return tempArray;
  }
  const setDemerit = (itemArray) => {
    //  const damageArray = itemArray.map((item, id) => Object.assign(item, { id }))
    setState((prevData) => ({
      ...prevData,
      rows: itemArray,
    }));
  }
  const handleSelect = (selectedOption, name) => {
    if (selectedOption == null) {
      setState((prevData) => ({
        ...prevData,
        [name]: null
      }));
    }

    // eslint-disable-next-line eqeqeq 
    else {

      setState((prevData) => ({
        ...prevData,
        [name]: selectedOption,
        QuartersError: false,
        QuartersErrorMsg: "",
      }));
    }



  };






  const handlePrompt = (param) => {
    handleDeleteParam(param)
    handleOpen1();

  };
  const handlePrompt2 = (param) => {
    // handleDeleteParam2(param)
    handleOpen2();

  };
  const onDeleteRow2 = (idx) => {
    //   handleSubmit('deletion')
    getTableData();
    handleClose2()

  }




  const validateFYear = () => {
    let fYear = myState.year;
    if (fYear == "" || fYear == null) {
      setState((prevData) => ({ ...prevData, errorFlagFyear: true, errorFlagFyearMsg: "FY is mandatory field" }))
      return false
    }

    else {
      setState((prevData) => ({ ...prevData, errorFlagFyear: false, errorFlagFyearMsg: "FY is mandatory field" }))

      return true

    }

  }

  const validateQurter = () => {
    let quarters = myState.quarter;
    if (quarters == "" || quarters == null) {
      setState((prevData) => ({ ...prevData, QuartersError: true, QuartersErrorMsg: "Quarter is mandatory field" }))
      return false
    }
    else {
      setState((prevData) => ({ ...prevData, QuartersError: false, QuartersErrorMsg: "Quarter is mandatory field" }))

      return true

    }

  }

  const handleSubmit = (flag) => {
    var diList = [];
    RowsColumn.map((element, idx, array) => {
      let mileStone = element.mileStone;
      let startDates = element.startDate;
      let endDates = element.endDate;
      let obj = {
        mileStone,
        "startDate": startDates,
        "endDate": endDates,
      }
      diList.push(obj)
    });

    let flag1 = validateFYear();
    let flag2 = validateQurter();
    if (flag1 == true && flag2 == true)
    // eslint-disable-next-line no-empty
    {

    } else {
      return false;
    }
    let fY = myState.year;
    let Fqurter = myState.quarter;
    let cutOfDate = handoverDate;

    var apiFormatedData = {

      "FY": fY.value,
      "Quarter": Fqurter.value,
      "cut_off_Date": cutOfDate,
      "Publish_Milestone_StartDate": RowsColumn[0].startDate,
      "Publish_Milestone_EndDate": RowsColumn[0].endDate,
      "Qualification_of_VCs_eligible_for_PLI_StartDate": RowsColumn[1].startDate,
      "Qualification_of_VCs_eligible_for_PLI_EndDate": RowsColumn[1].endDate,
      "BoM_Explosion_StartDate": RowsColumn[2].startDate,
      "BoM_Explosion_EndDate": RowsColumn[2].endDate,
      "Part_Details_correction_StartDate": RowsColumn[3].startDate,
      "Part_Details_correction_EndDate": RowsColumn[3].endDate,
      "Publish_to_Vendors_StartDate": RowsColumn[4].startDate,
      "Publish_to_Vendors_EndDate": RowsColumn[4].endDate,
      "Collect_DVA_from_vendors_StartDate": RowsColumn[5].startDate,
      "Collect_DVA_from_vendors_EndDate": RowsColumn[5].endDate,
      "Validation_of_Vendors_input_StartDate": RowsColumn[6].startDate,
      "Validation_of_Vendors_input_EndDate": RowsColumn[6].endDate,
      "TML_DVA_Calculation_StartDate": RowsColumn[7].startDate,
      "TML_DVA_Calculation_EndDate": RowsColumn[7].endDate,
      "TML_DVA_Validation_StartDate": RowsColumn[8].startDate,
      "TML_DVA_Validation_EndDate": RowsColumn[8].endDate,
      "TML_DVA_CA_Certification_StartDate": RowsColumn[9].startDate,
      "TML_DVA_CA_Certification_EndDate": RowsColumn[9].endDate,
      "TML_DVA_ARAI_Certification_StartDate": RowsColumn[10].startDate,
      "TML_DVA_ARAI_Certification_EndDate": RowsColumn[10].endDate,
      "TML_DVA_publish_to_MHI_StartDate": RowsColumn[11].startDate,
      "TML_DVA_publish_to_MHI_EndDate": RowsColumn[11].endDate

    }
    var apiFormatedDataJson = JSON.stringify(apiFormatedData);
    console.log("final", apiFormatedDataJson);
    let headerss = new Headers();
    headerss.append('Content-Type', 'application/json');
    // Milestone_calendar_PV_View
    fetch(`${baseUrl}Milestone_calendarView/`,
      {
        method: 'POST',
        headers: headerss,
        body: apiFormatedDataJson
      }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson) {

          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: responseJson.status,
          });

        } else {

          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: "data save operation failed",

          });

        }
      })
      .catch((error) => {
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Internal server error`,

        });
      });

  };






  const onHover = (name) => {
    setHover({ isHover: true, name: name });
  };

  const onLeave = () => {
    setHover({ isHover: false, name: null });
  };

  const BG_COLOR = '#fffef8';
  const [backgroundColors, setBackgroundColors] = useState({
    processInfoBg: true,
    supplierInfoBg: false,
    poInfoBg: false,
    projectsInfoBg: false
  });
  const { processInfoBg, supplierInfoBg, poInfoBg, projectsInfoBg } = backgroundColors;

  const changeBackgroundColor = (field) =>
    setBackgroundColors(prev => {
      const newBackgroundColors = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(prev)) {
        if (key === field)
          newBackgroundColors[key] = true;
        else
          newBackgroundColors[key] = false;
      }
      return newBackgroundColors
    })



  const handleOpen2 = () => {
    setConfirmModal2(true);


  };
  const handleClose2 = () => setConfirmModal2(false);
  const resetFields = () => setResetAllVisitedFields(true);


  const baseDefaultColumns = [
    {
      width: 400,
      title: 'Milestones',
      field: 'mileStone1',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 160,
      title: 'Start Date',
      field: 'startDate',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 160,
      title: 'End Date',
      field: 'endDate',
      enableSearch: true,
      enableFilter: true,
    },


  ];

  const processPoList = poList => {
    const processedPoList = poList.map(({ ...po }) => {
      const plantName = po.plant.name;
      const partDesc = po.part.description;
      const id = po.number;
      const processCreationDate = moment(po.createdAt).format("DD/MM/YYYY");
      return {
        ...po,
        id,
        plantName,
        partDesc,
        processCreationDate
      }
    });
    return processedPoList;
  }

  const loadPO = async () => {
    try {
      setPOData(prev => ({
        ...prev,
        loading: true,
      }));

      const response = await API.get(
        API_RESOURCE_URLS.PURCHASE_ORDER,
        {
          params:
          {
            supplierId: supplierDetails ? supplierDetails.id : null,
            partNumber: part ? part.value : null,
            plantCode: plant ? plant.value : null,
            page: poData.number,
          }
        }
      );
      const processedPoList = processPoList(response.data.content);

      setPOData(prev => ({
        ...prev,
        data: [...prev.data, ...processedPoList],
        filteredData: [...prev.filteredData, ...processedPoList],
        loading: false,
        last: response.data.last
      })
      );

    } catch (error) {
      showInternalError(error);
      console.error(buildErrorMessage(error));
      setPOData({
        number: 0,
        data: [],
        filteredData: [],
        loading: false,
        last: false
      });
    }
  };

  const handleValidation = (event, field) => {
    let err = errors;
    const { value } = event.target;

    switch (field) {
      case 'purchaseBuyerName':
        err['purchaseBuyerName'] = value ? RegExp(Regex.name).test(value) ? '' : 'Name is invalid' : 'Name is required';
        break;

      case 'suppRepresentativeName':
        err['suppRepresentativeName'] = value ? RegExp(Regex.name).test(value) ? '' : 'Name is invalid' : 'Name is required';
        break;

      case 'suppRepresentativeEmail':
        err['suppRepresentativeEmail'] = value ? RegExp(Regex.email).test(value) ? '' : 'Email is invalid' : 'Email is required';
        break;

      case 'suppRepresentativeContact':
        err['suppRepresentativeContact'] = value ? RegExp(Regex.phone).test(value) ? '' : 'Contact is invalid' : 'Contact is required';
        break;

      default:
        break;
    }
    dispatch({ type: 'update', field: 'errors', value: err });
  }
  const handleClose = () => {
    setPpenModal(false)

  }

  const FY = [
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
    { value: "2025", label: "2025" },
    { value: "2026", label: "2026" },
    { value: "2027", label: "2027" },
    { value: "2028", label: "2028" },
    { value: "2029", label: "2029" },
    { value: "2030", label: "2030" },
    { value: "2031", label: "2031" },

  ]
  const Quarter = [
    { value: "Q1", label: "Q1" },
    { value: "Q2", label: "Q2" },
    { value: "Q3", label: "Q3" },
    { value: "Q4", label: "Q4" },
  ]

  const allRow = (row) => {
    // const masterRows = myState.masterRows;
    setSingleCheckRow(row[0])
    setDemeritTitle(row[0].mileStone1)
    setPpenModal(true)
    setStartDate(row[0].startDate)
    setEndDate(row[0].endDate)



    // const dataArray = row.map((item, id) => Object.assign(item, { remarkNew: "", statusNew: "" }))

    setSelectedRow(row)
    console.log(row, "dfdsfsdfsdf")

    setState((prevData) => ({ ...prevData, masterRows: row }))



  }
  const setCount = count => {
    setAsnCount(count);
  };
  const handleCheck4 = (event) => {
    const { value } = event.target;
    call();
    if (value == "Manually") {
      setState((prevData) => ({ ...prevData, checkType4: value }));

    } else {
      setState((prevData) => ({ ...prevData, checkType4: value, fileObj: {} }));

    }
  }

  const handleFileInputChange2 = e => {
    let { file } = myState;
    file = e.target.files[0];
    getBase64(file)
      .then(result => {
        file["base64"] = result;


        var namefile = file.name;

        let arraystr = namefile.split('.');
        let identifier = arraystr[0];
        let size = e.target.files[0].size;
        // let sizeArr = size.split(" ");
        let base64Arry = file.base64.split(',');
        let link = `file://${namefile}`;


        var objFile = {
          "identifier": identifier,
          "fileName": namefile,
          "size": size,
          "base64String": base64Arry[1]
        };


        setState((prevData) => ({
          ...prevData,
          fileObj: objFile,
          errorFlagFile: false

        }));


      })
      .catch(err => {
        console.log(err);
      });

  };
  const getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  const renderMilestone = () => (
    <div
      className={styles.formGroup}

    >


      {/* <div className={clsx(styles.col2, styles.customeTable)} >
        <Table
          columns={[...baseDefaultColumns]}
          defaultColumns={[...baseDefaultColumns]}
          rowHeight={38}
          rows={RowsColumn}
          secondaryActions={[
            {
              name: 'Edit Dates',
              authOperation: 'create-project',
              shouldEnable: selected => selected.length >= 1,
              actionFn: selected => allRow(selected),
              customClass: ""
            }
          ]}
          setAsnCount={setCount}
          isDataLoading={isDataLoading}
          actionButtons
        // removeRowSelection
        // showRadioButton
        />

      </div> */}
      <div >
        <div >

          <Grid
            container
            spacing={1}

            style={{ display: 'flex', justifyContent: "left" }}
            columns={12}
          >
            <Grid item md={2} lg={2}>
              <div className={clsx(styles.lableDiv)} style={{ width: '100%', fontWeight: 700 }}>
                <label>MilesStones</label>

              </div>
            </Grid>
            <Grid item md={2} lg={2}>
              <div className={clsx(styles.lableDiv)} style={{ width: '100%', fontWeight: 700 }}>
                <label>Start Date</label>
              </div>

            </Grid>

            <Grid item md={2} lg={2}>
              <div className={clsx(styles.lableDiv)} style={{ width: '100%', fontWeight: 700 }}>
                <label>End Date</label>
              </div>

            </Grid>


          </Grid>
          {
            selectedRow.map(({ startDate, endDate, id, isSelected, mileStone, mileStone1, minimalDate }, idx, row) => {




              return (<Grid
                container
                spacing={1}

                style={{ marginBottom: "2px" }}
                columns={12}
              >
                <Grid item md={2} lg={2}>
                  <div className={styles.rowDiv}>
                    <div className={clsx(styles.lableDiv)} style={{ width: '100%', marginLeft: "0px", marginRight: "0px" }}
                    >
                      <label>{mileStone1}</label> 
                      {/* Pratik */}
                    </div>

                  </div>
                </Grid>
                <Grid item md={2} lg={2}>
                  <div className={styles.rowDiv}>

                    <div className={styles.formDiv}>


                      <CustomDatePicker
                        disabled={mileStone1 == "BoM Explosion"}
                        isMandatory

                        markIfUnselected={highlightMandatoryFields}
                        autoOk
                        inputVariant="outlined"
                        value={startDate}
                        onChange={(e) => {
                          //  setStartDate(e.format(DATE_FORMAT.ISO)) 

                          //     var newEndDate = moment(e.format(DATE_FORMAT.ISO), "YYYY-MM-DD").add(1, 'days');
                          //    alert(new_date.format(DATE_FORMAT.ISO))


                          setDate(mileStone, mileStone1, e.format(DATE_FORMAT.ISO), endDate, id, selectedRow[idx], idx, selectedRow, minimalDate, "startDate")

                          //   setData(e.format(DATE_FORMAT.ISO), endDate, selectedRow[idx], idx)
                        }}
                        inputProps={{
                          className: clsx(styles.select, styles.dateInput),

                        }}
                        emptyLabel="yyyy-mm-dd"
                        // defaultDate={moment().format(DATE_FORMAT.DD_MM_YYYY)}
                        minDate={(mileStone1 == "Publish Milestone") ? moment(minimalDate, "YYYY-MM-DD") : moment(minimalDate, "YYYY-MM-DD").add(1, 'days')}
                        format="yyyy-MM-DD"

                        data-testid="date-picker-div-1"
                        style={{ width: "100%" }}
                      // maxDate={moment().toDate()} 

                      />

                    </div>
                  </div>
                </Grid>

                <Grid item md={2} lg={2}>
                  <div className={styles.rowDiv}>

                    <div className={styles.formDiv}>



                      <CustomDatePicker
                        //   onError={dateValidation}
                        disabled={mileStone1 == "BoM Explosion"}

                        markIfUnselected={highlightMandatoryFields}
                        autoOk
                        inputVariant="outlined"
                        value={endDate}
                        onChange={(e) => {
                          // setEndDate(e.format(DATE_FORMAT.ISO)) 
                          setDate(mileStone, mileStone1, startDate, e.format(DATE_FORMAT.ISO), id, selectedRow[idx], idx, selectedRow, minimalDate, "endDate")

                          //  setData(startDate, e.format(DATE_FORMAT.ISO), selectedRow[idx], idx)

                        }}
                        inputProps={{
                          className: clsx(styles.select, styles.dateInput),

                        }}
                        emptyLabel="yyyy-mm-dd"
                        // defaultDate={moment().format(DATE_FORMAT.DD_MM_YYYY)}
                        minDate={moment(startDate, "YYYY-MM-DD").add(1, 'days')}

                        format="yyyy-MM-DD"

                        data-testid="date-picker-div-1"
                        style={{ width: "100%" }}
                      //  maxDate={moment().toDate()} 

                      />
                    </div>
                  </div>
                </Grid>


              </Grid>)
            })
          }

          <Grid
            container
            spacing={1}

            style={{ display: 'flex', marginTop: "10px", marginBottom: "5px" }}
            columns={12}
          >

            {/* <Grid item md={12} lg={12}>
                <div className={styles.rowDiv}>

                  <div className={styles.formDiv} style={{ justifyContent: "center" }}>
                    <Button onClick={(e) => updateTtableData(RowsColumn)}
                      size="small" variant="primary" component="label"
                      disabled={myState.submitBtn}

                      className={clsx(
                        styles.actionButton,
                        styles.primaryActionButton,
                      )}
                    >
                      Ok

                    </Button>


                  </div>
                </div>
              </Grid> */}



          </Grid>
        </div>



      </div>
    </div>)


  const renderOther = () => (<>
    <div
      className={styles.formGroup}
      style={{ backgroundColor: supplierInfoBg ? BG_COLOR : null }}
      onClick={() => changeBackgroundColor('supplierInfoBg')}
    >


      <Grid
        container
        spacing={1}

        style={{ display: 'flex', justifyContent: "left", marginTop: "5px", marginBottom: "1px" }}
        columns={12}
      >
        <Grid item md={3} lg={3}>
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)}>
              <label>FY</label>
            </div>
            <div className={styles.formDiv}>

              <CustomSelect
                isClearable
                isMandatory
                markIfUnselected={highlightMandatoryFields}
                resetAllVisitedFields={resetAllVisitedFields}
                className={clsx(styles.select, styles.sel2)}
                isMulti={false}
                options={FY}
                value={myState.year}
                onChange={(e) => {
                  handleSelect(e, 'year')
                  setState((prevData) => ({ ...prevData, errorFlagFyear: false, errorFlagFyearMsg: "FY is mandatory field" }))
                  setHighlightMandatoryFields(false);

                }}
                name="year"
              />
            </div>
          </div>
          {myState.errorFlagFyear &&
            <div className={styles.error}>{myState.errorFlagFyearMsg}</div>
          }
        </Grid>
        <Grid item md={3} lg={3}>
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)}>
              <label> Quarter</label>
            </div>
            <div className={styles.formDiv}>

              <CustomSelect
                isClearable
                isMandatory
                markIfUnselected={highlightMandatoryFields}
                resetAllVisitedFields={resetAllVisitedFields}
                className={clsx(styles.select, styles.sel2)}
                isMulti={false}
                options={Quarter}
                value={myState.quarter}
                onChange={(e) => {
                  handleSelect(e, 'quarter')
                  setState((prevData) => ({ ...prevData, QuartersError: false, QuartersErrorMsg: "Quarter is mandatory field" }))
                }}
                name="quarter"
              />
            </div>
          </div>
          {myState.QuartersError &&
            <div className={styles.error}>{myState.QuartersErrorMsg}</div>
          }
        </Grid>
        <Grid item md={3} lg={3}>
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)}>
              <label>Cut Of Date</label>
            </div>
            <div className={styles.formDiv}>
              <CustomDatePicker
                //  isMandatory

                //  markIfUnselected={highlightMandatoryFields}
                autoOk
                inputVariant="outlined"
                value={handoverDate}
                onChange={(e) => { handleDate(e, 'handoverDate') }}
                inputProps={{
                  className: clsx(styles.select, styles.dateInput),

                }}
                emptyLabel="yyyy-mm-dd"
                // defaultDate={moment().format(DATE_FORMAT.DD_MM_YYYY)}
                // minDate={!isEditable? null: getMinimumDateForPicker(index, milestones)}
                format="YYYY-MM-DD"

                data-testid="date-picker-div-1"
                style={{ width: "100%" }}
              // maxDate={moment().toDate()} 

              />
            </div>
          </div>
          {myState.errorFlag &&
            <div className={styles.error}>Transporter is mandatory field</div>
          }
        </Grid>
        <Grid item md={1} lg={1}>
          <div className={styles.rowDiv} style={{ justifyContent: "center" }}>
            <Button onClick={(e) => getSearchData()}
              size="small" variant="primary" component="label"
              disabled={(myState.year == null || myState.quarter == null)}
              className={clsx(
                styles.actionButton,
                styles.primaryActionButton,
              )}
            >
              Search

            </Button>
          </div>

        </Grid>

      </Grid>
      {/* <Grid
        container
        spacing={1}

        style={{ display: 'flex', justifyContent: "center", marginTop: "10px" }}
        columns={12}
      >
        <Grid item md={12} lg={12}>
          <div className={styles.rowDiv} style={{ justifyContent: "center" }}>
            <Button onClick={(e) => getSearchData()}
              size="small" variant="primary" component="label"
              disabled={(myState.year == null || myState.quarter == null)}
              className={clsx(
                styles.actionButton,
                styles.primaryActionButton,
              )}
            >
              Search

            </Button>
          </div>

        </Grid>
      </Grid> */}




      {/* <AddVcForm toggle={toggle} loader={loader} handleClickOpen1={handleClickOpen1} targetModalDate={targetModalDate} dateHandler={dateHandler} setDaat={setDaat} PONo={myState.PONo} vcbcNo={myState.vcbcNo} onChangeHandler={onChangeHandler} open={open1} handleClose={handleClose1} /> */}

    </div>
    <Modal open={openModal} onClose={handleClose} disableBackdropClick>
      <div className={styles.addVc}  >
        <div className={styles.addVcTitle}>
          Edit MilesStone Dates
          <div className={styles.plantInfo} >
            <ClearIcon onClick={handleClose} />

          </div>
        </div>
        <div className={styles.frm}>
          <div className={styles.formSec}>

            <Grid
              container
              spacing={1}

              style={{ display: 'flex', justifyContent: "left" }}
              columns={12}
            >
              <Grid item md={4} lg={4}>
                <div className={styles.rowDiv}>
                  <div className={clsx(styles.lableDiv)} style={{ width: '100%', fontWeight: 700 }}>
                    <label>MilesStones</label>
                  </div>

                </div>
              </Grid>
              <Grid item md={4} lg={4}>
                <div className={styles.rowDiv}>
                  <div className={clsx(styles.lableDiv)} style={{ width: '100%', fontWeight: 700 }}>
                    <label>Start Date</label>
                  </div>

                </div>
              </Grid>

              <Grid item md={4} lg={4}>
                <div className={styles.rowDiv}>
                  <div className={clsx(styles.lableDiv)} style={{ width: '100%', fontWeight: 700 }}>
                    <label>End Date</label>
                  </div>

                </div>
              </Grid>


            </Grid>
            {
              selectedRow.map(({ startDate, endDate, id, isSelected, mileStone, mileStone1, minimalDate }, idx, row) => {




                return (<Grid
                  container
                  spacing={2}

                  style={{ display: 'flex', justifyContent: "left" }}
                  columns={12}
                >
                  <Grid item md={4} lg={4}>
                    <div className={styles.rowDiv}>
                      <div className={clsx(styles.lableDiv)} style={{ width: '100%', marginLeft: "0px", marginRight: "0px" }}
                      >
                        <label>{mileStone1}-{minimalDate} </label>
                      </div>

                    </div>
                  </Grid>
                  <Grid item md={4} lg={4}>
                    <div className={styles.rowDiv}>

                      <div className={styles.formDiv}>


                        <CustomDatePicker
                          disabled={mileStone1 == "BoM Explosion"}
                          isMandatory

                          markIfUnselected={highlightMandatoryFields}
                          autoOk
                          inputVariant="outlined"
                          value={startDate}
                          onChange={(e) => {
                            //  setStartDate(e.format(DATE_FORMAT.ISO)) 

                            //     var newEndDate = moment(e.format(DATE_FORMAT.ISO), "YYYY-MM-DD").add(1, 'days');
                            //    alert(new_date.format(DATE_FORMAT.ISO))


                            setDate(mileStone, mileStone1, e.format(DATE_FORMAT.ISO), endDate, id, selectedRow[idx], idx, selectedRow, minimalDate, "startDate")

                            //   setData(e.format(DATE_FORMAT.ISO), endDate, selectedRow[idx], idx)
                          }}
                          inputProps={{
                            className: clsx(styles.select, styles.dateInput),

                          }}
                          emptyLabel="yyyy-mm-dd"
                          // defaultDate={moment().format(DATE_FORMAT.DD_MM_YYYY)}
                          minDate={moment(minimalDate, "YYYY-MM-DD").add(1, 'days')}
                          format="yyyy-MM-DD"

                          data-testid="date-picker-div-1"
                          style={{ width: "100%" }}
                        // maxDate={moment().toDate()} 

                        />

                      </div>
                    </div>
                  </Grid>

                  <Grid item md={4} lg={4}>
                    <div className={styles.rowDiv}>

                      <div className={styles.formDiv}>



                        <CustomDatePicker
                          //   onError={dateValidation}
                          disabled={mileStone1 == "BoM Explosion"}

                          markIfUnselected={highlightMandatoryFields}
                          autoOk
                          inputVariant="outlined"
                          value={endDate}
                          onChange={(e) => {
                            // setEndDate(e.format(DATE_FORMAT.ISO)) 
                            setDate(mileStone, mileStone1, startDate, e.format(DATE_FORMAT.ISO), id, selectedRow[idx], idx, selectedRow, minimalDate, "endDate")

                            //  setData(startDate, e.format(DATE_FORMAT.ISO), selectedRow[idx], idx)

                          }}
                          inputProps={{
                            className: clsx(styles.select, styles.dateInput),

                          }}
                          emptyLabel="yyyy-mm-dd"
                          // defaultDate={moment().format(DATE_FORMAT.DD_MM_YYYY)}
                          minDate={moment(startDate, "YYYY-MM-DD").add(1, 'days')}

                          format="yyyy-MM-DD"

                          data-testid="date-picker-div-1"
                          style={{ width: "100%" }}
                        //  maxDate={moment().toDate()} 

                        />
                      </div>
                    </div>
                  </Grid>


                </Grid>)
              })
            }

            <Grid
              container
              spacing={1}

              style={{ display: 'flex', marginTop: "10px", marginBottom: "5px" }}
              columns={12}
            >

              <Grid item md={12} lg={12}>
                <div className={styles.rowDiv}>

                  <div className={styles.formDiv} style={{ justifyContent: "center" }}>
                    <Button onClick={(e) => updateTtableData(RowsColumn)}
                      size="small" variant="primary" component="label"
                      disabled={myState.submitBtn}

                      className={clsx(
                        styles.actionButton,
                        styles.primaryActionButton,
                      )}
                    >
                      Ok

                    </Button>


                  </div>
                </div>
              </Grid>



            </Grid>
          </div>



        </div>
      </div>
    </Modal>
  </>
  )





  const getSelectedFields = () => ({
    projectId: selectedProject,
    poNumber: selectedPO,
    commodityGroupId: purchaseCommodity && purchaseCommodity.value,
    aqCommodityGroupId: aqCommodity && aqCommodity.value,
    purchaseBuyerName: inputFields?.purchaseBuyerName,
    kamContactDetails: {
      // id: inputFields,
      name: inputFields?.suppRepresentativeName,
      contactNumber: inputFields?.suppRepresentativeContact,
      emailId: inputFields?.suppRepresentativeEmail
    }
  });

  const validateFields = (fields) => {
    const requiredFields = { ...fields, ...fields.kamContactDetails }
    const hasMissingRequiredFields = Object.values(requiredFields).some(
      (field) => !field
    );

    const hasError = Object.values(errors).some((err) => err);

    if (hasMissingRequiredFields || hasError) {
      return false;
    }
    return true;
  };

  const runPreSubmissionChecks = () => {
    const selectedFields = getSelectedFields();
    const areFieldsValid = validateFields(selectedFields);

    if (!areFieldsValid) {
      setHighlightMandatoryFields(true);
      // return false;
      return true;
    }
    return true;
  };







  const resetAllInputs = () => {
    const inputs = {
      purchaseBuyerName: '',
      suppRepresentativeName: '',
      suppRepresentativeContact: '',
      suppRepresentativeEmail: ''
    }
    dispatch({ type: 'update', field: 'inputFields', value: inputs });
    dispatch({ type: 'update', field: 'errors', value: {} });
  }

  const resetState = () => {
    dispatch({ type: 'reset' });
    resetAllInputs();
    resetTableData();
    setHighlightMandatoryFields(null);
    setResetAllVisitedFields(false);
    changeBackgroundColor('processInfoBg');
  }



  useEffect(() => {
    if (resetAllVisitedFields)
      resetState();
    console.log('render2');

  }, [resetAllVisitedFields])

  const setAllDetailsCall = (chassisNo) => {
    // const BBcode = product.chassisData.chassisDetails.chs_bb_code;

    dispatch1(setAllDetails({
      chassisNo,
      flagFromPage: "DI",
      flagForOperations: "Modify",
      userId: auth.userID,
      companyCode: auth.companyCode,
      userRole: auth.userRole,
      chsStmNo: "",
      uploadType: "B",
      baseUrl,
      bbCode: auth.userID
    }));

  }








  useEffect(() => {

    getTableData();

  }, [])


  const getTableData = () => {

    let headerss = new Headers();
    headerss.append('Content-Type', 'application/json');
    // Milestone_calendar_PV_View
    fetch(`${baseUrl}Milestone_calendarView/`,
      {
        method: 'GET',
        headers: headerss,
        //   body: apiFormatedDataJson
      }).then((response) => response.json())
      .then((responseJson) => {
        const tableData = responseJson.data;
        var milesStoneResponse = responseJson[0]

        let id = milesStoneResponse.id;
        let year = milesStoneResponse.FY;
        let QuarterDB = milesStoneResponse.Quarter;
        let cutOffDate = milesStoneResponse.cut_off_Date;
        let LastUpdated = milesStoneResponse.Last_updated;
        const statusFY = FY.filter(o => o.value == year);
        const quarterStatus = Quarter.filter(o => o.value == QuarterDB);
        setHandoverDate(cutOffDate)
        setState((prevData) => ({ ...prevData, year: statusFY[0], quarter: quarterStatus[0], }));
        delete milesStoneResponse.id;
        delete milesStoneResponse.FY;
        delete milesStoneResponse.cut_off_Date;
        delete milesStoneResponse.Quarter;
        delete milesStoneResponse.Last_updated;


        var milestoneArray = [];
        var startDateArray = [];
        var endDateArray = [];
        for (var prop in milesStoneResponse) {
          let text = prop;
          const milesStoneFinal = text.split("_StartDate")[0];
          let StartDate = text.match(/StartDate/gi);
          let EndDate = text.match(/EndDate/gi);
          const mileStoneEnd = text.split("_EndDate")[0];
          if (StartDate != null) {
            let obj = {
              "startDate": milesStoneResponse[prop]
            }
            startDateArray.push(obj)
            milestoneArray.push(milesStoneFinal)
          }

          if (EndDate != null) {
            let obj = {
              "endDate": milesStoneResponse[prop]
            }
            endDateArray.push(obj)
            milestoneArray.push(mileStoneEnd)
          }

        }

        function removeDuplicates(arr) {
          return arr.filter((item,
            index) => arr.indexOf(item) === index);
        }

        let mileStoneFinal = removeDuplicates(milestoneArray).map((iteam) => ({ mileStone: iteam }));

        var merged = [];

        for (let i = 0; i < mileStoneFinal.length; i++) {
          merged.push({
            ...startDateArray[i],
            ...endDateArray[i],
            ...mileStoneFinal[i]
          });
        }
        let RowsColumnn = merged.map((item, id) => Object.assign(item, { id }))


        const finalRowsColumnn = RowsColumnn.map(({ mileStone, startDate, endDate, id }, indexx) => {
          let mileStone1 = mileStone.replace(/_/g, ' ');
          let obj;
          if (indexx == 0) {
            obj = {
              id: id + 1,
              mileStone,
              startDate,
              endDate,
              mileStone1,
              minimalDate: `${year}-01-01`
            }
            return obj;
          } else {

            obj = {
              id: id + 1,
              mileStone,
              startDate,
              endDate,
              mileStone1,
              minimalDate: RowsColumnn[indexx - 1].endDate
            }
            return obj;
          }


        });

        setRowsColumn(finalRowsColumnn)
        setSelectedRow(finalRowsColumnn)

        setDataLoading(false)


      })
      .catch((error) => {

      });

  };
  const getSearchData = () => {
    setRowsColumn([])
    setSelectedRow([])

    setDataLoading(true)
    const apiFormatedData = {
      "FY": myState.year.value,
      "Quarter": myState.quarter.value
    }

    var apiFormatedDataJson = JSON.stringify(apiFormatedData);

    let headerss = new Headers();
    headerss.append('Content-Type', 'application/json');
    fetch(`${baseUrl}Milestone_calendarView/`,
      {
        method: 'PUT',
        headers: headerss,
        body: apiFormatedDataJson
      }).then((response) => response.json())
      .then((responseJson) => {
        const tableData = responseJson.data;
        var milesStoneResponse;
        if (responseJson.length == 0) {
          showPopup({
            type: MESSAGE_TYPE.INFORMATION,
            contextText: MESSAGE_TYPE.INFORMATION,
            info: "No record found",
          });
          let year;
          if (myState.year.value == "2022") {
            year = 2022;
          } else {
            year = moment(`${myState.year.value}-01-01`, "YYYY-MM-DD").subtract(1, 'years').year()

          }
          // pratik

          milesStoneResponse = {
            "FY": myState.year.value,
            "Quarter": myState.quarter.value,
            "cut_off_Date": `${year}-01-01`,
            "Publish_Milestone_StartDate": `${year}-01-01`,
            "Publish_Milestone_EndDate": `${year}-01-02`,
            "Qualification_of_VCs_eligible_for_PLI_StartDate": `${year}-01-03`,
            "Qualification_of_VCs_eligible_for_PLI_EndDate": `${year}-01-04`,
            "BoM_Explosion_StartDate": `${year}-01-05`,
            "BoM_Explosion_EndDate": `${year}-01-06`,
            "Part_Details_correction_StartDate": `${year}-01-07`,
            "Part_Details_correction_EndDate": `${year}-01-08`,
            "Publish_to_Vendors_StartDate": `${year}-01-09`,
            "Publish_to_Vendors_EndDate": `${year}-01-10`,
            "Collect_DVA_from_vendors_StartDate": `${year}-01-11`,
            "Collect_DVA_from_vendors_EndDate": `${year}-01-12`,
            "Validation_of_Vendors_input_StartDate": `${year}-01-13`,
            "Validation_of_Vendors_input_EndDate": `${year}-01-14`,
            "TML_DVA_Calculation_StartDate": `${year}-01-15`,
            "TML_DVA_Calculation_EndDate": `${year}-01-16`,
            "TML_DVA_Validation_StartDate": `${year}-01-17`,
            "TML_DVA_Validation_EndDate": `${year}-01-18`,
            "TML_DVA_CA_Certification_StartDate": `${year}-01-19`,
            "TML_DVA_CA_Certification_EndDate": `${year}-01-20`,
            "TML_DVA_ARAI_Certification_StartDate": `${year}-01-21`,
            "TML_DVA_ARAI_Certification_EndDate": `${year}-01-22`,
            "TML_DVA_publish_to_MHI_StartDate": `${year}-01-23`,
            "TML_DVA_publish_to_MHI_EndDate": `${year}-01-24`

          }


          //   return;
        } else {
          milesStoneResponse = responseJson[0]

        }

        let id = milesStoneResponse.id;
        let year = milesStoneResponse.FY;
        let QuarterDB = milesStoneResponse.Quarter;
        let cutOffDate = milesStoneResponse.cut_off_Date;
        let LastUpdated = milesStoneResponse.Last_updated;
        const statusFY = FY.filter(o => o.value == year);
        const quarterStatus = Quarter.filter(o => o.value == QuarterDB);
        setHandoverDate(cutOffDate)
        setState((prevData) => ({ ...prevData, year: statusFY[0], quarter: quarterStatus[0], }));
        delete milesStoneResponse.id;
        delete milesStoneResponse.FY;
        delete milesStoneResponse.cut_off_Date;
        delete milesStoneResponse.Quarter;
        delete milesStoneResponse.Last_updated;


        var milestoneArray = [];
        var startDateArray = [];
        var endDateArray = [];
        for (var prop in milesStoneResponse) {
          let text = prop;
          const milesStoneFinal = text.split("_StartDate")[0];
          let StartDate = text.match(/StartDate/gi);
          let EndDate = text.match(/EndDate/gi);
          const mileStoneEnd = text.split("_EndDate")[0];
          if (StartDate != null) {
            let obj = {
              "startDate": milesStoneResponse[prop]
            }
            startDateArray.push(obj)
            milestoneArray.push(milesStoneFinal)
          }

          if (EndDate != null) {
            let obj = {
              "endDate": milesStoneResponse[prop]
            }
            endDateArray.push(obj)
            milestoneArray.push(mileStoneEnd)
          }

        }

        function removeDuplicates(arr) {
          return arr.filter((item,
            index) => arr.indexOf(item) === index);
        }

        let mileStoneFinal = removeDuplicates(milestoneArray).map((iteam) => ({ mileStone: iteam }));

        var merged = [];

        for (let i = 0; i < mileStoneFinal.length; i++) {
          merged.push({
            ...startDateArray[i],
            ...endDateArray[i],
            ...mileStoneFinal[i]
          });
        }
        let RowsColumnn = merged.map((item, id) => Object.assign(item, { id }))


        const finalRowsColumnn = RowsColumnn.map(({ mileStone, startDate, endDate, id }, indexx) => {
          let mileStone1 = mileStone.replace(/_/g, ' ');
          let obj;

          if (indexx == 0) {
            obj = {
              id: id + 1,
              mileStone,
              startDate,
              endDate,
              mileStone1,
              minimalDate: `2022-01-01`
            }
            return obj;
          } else {

            obj = {
              id: id + 1,
              mileStone,
              startDate,
              endDate,
              mileStone1,
              minimalDate: RowsColumnn[indexx - 1].endDate
            }
            return obj;
          }
        });

        setRowsColumn(finalRowsColumnn)
        setSelectedRow(finalRowsColumnn)

        setDataLoading(false)


      })
      .catch((error) => {

      });

  };






  return (
    <>
      <div className={clsx(styles.scrollContainer, styles.scrollBar, styles.formContainer)}>
        {/* <div className={styles.row}>
          <div className={styles.formTitle}>
            <span className={styles.formSpan}>Demerit Revision</span>
            <span className={styles.reqired}>Note:* Mandatory fields</span>
          </div>
        </div> */}

        {/* <CustomFormGroup header='Chassis Defect Details' body={renderProcessInfo()} />
        <CustomFormGroup header='E-POD Details' body={renderEPOD()} /> */}
        {/* <CustomFormGroup header='Chassis Details' body={renderStatic()} /> */}
        <CustomFormGroup header='Calendar' body={renderOther()} />
        <CustomFormGroup header='Milestone Details' body={renderMilestone()} />






      </div>

      <div className={styles.actionBar}>
        {/* <Button
          className={clsx(styles.actionButton, styles.resetButton)}
          variant="tertiary"
          // onClick={handleOpen}
          onClick={() => {
            handlePrompt2('0')
          }}
        >
          Reset
        </Button> */}



        <AuthChecker operation="submit">
          {isAuthorized => (
            <Button
              className={clsx(
                styles.actionButton,
                styles.primaryActionButton,
              )}
              variant="primary"
              onClick={() => runPreSubmissionChecks() && handleSubmit('submition')}
              //  onClick={(selectedRow) => handleSubmit('submition')}
              disabled={!isAuthorized || myState.submitBtn}
              data-testid="confirm-action"
            >
              SUBMIT
            </Button>
          )}

        </AuthChecker>

      </div>
      <ResetModal
        isPopupOpen={isPopupOpen}
        // handleClose={handleClose}
        resetFields={resetFields}
      />

      <ResetModal2
        a={confirmModalFlag2}
        handleCase={handleClose2}
        onDelete={() => onDeleteRow2('ok')}
      />


    </>
  )
}


function ResetModal2({ a, handleCase, onDelete }) {
  return (
    <Dialog
      open={a}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup1"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <DeleteIcon style={{ height: '18px', width: '18px' }} />
            <span className={styles.txt}>Are you sure ?</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <span>
            Do you want to reset data ?
          </span>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleCase}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={onDelete}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function ResetModal1({ a, handleCase, onDelete }) {
  return (
    <Dialog
      open={a}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup1"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <DeleteIcon style={{ height: '18px', width: '18px' }} />
            <span className={styles.txt}>Are you sure ?</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <span>
            Do you want to delete ?
          </span>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleCase}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={onDelete}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

function ResetModal({ isPopupOpen, handleClose, resetFields }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <MdReplay style={{ height: '18px', width: '18px' }} />
            <span className={styles.txt}>Reset</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <span>
            Are you sure you want to reset the form?
          </span>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleClose}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleClose()
              resetFields()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

MilesStone.propTypes = {
  redirectToProcessPage: PropTypes.func
}

ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  resetFields: PropTypes.func.isRequired
}
ResetModal1.propTypes = {
  a: PropTypes.bool.isRequired,
  handleCase: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}
ResetModal2.propTypes = {
  a: PropTypes.bool.isRequired,
  handleCase: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  product: state.product,
  counter: state.counter.value,
  baseUrl: state.counter.baseUrl,
  auth: state.auth,
  tableData: state.tableData
});
const mapDispatchToProps = (dispatch) => ({

  fetchChassis: (chassisParam) => dispatch(fetchChassis(chassisParam)),
  getInvoice: (chassisParam) => dispatch(getInvoice(chassisParam)),
});
MilesStone.propTypes = {
  counter: PropTypes.number,
  baseUrl: PropTypes.string,
  product: PropTypes.object,
  auth: PropTypes.object,
  tableData: PropTypes.object,
};
const App = connect(mapStateToProps, mapDispatchToProps)(MilesStone);


export default withAllowedOperationsProvider(
  App,
  RESOURCE_TYPE.PPAP
);
