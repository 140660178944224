// import React, { useState } from 'react';
// import clsx from 'clsx';
// import PropTypes from 'prop-types';
// import styles from './ListView.module.css';

// function ListView({ listItems = [], handleSelection, title }) {
//  // console.log(listItems,"ppp")
//   const [currentSelection, setCurrentSelection] = useState(null);

//   const handleClick = item => {
//     handleSelection(item);
//     setCurrentSelection(item);
//   };

//   if (!listItems?.length) return null;
//   return (
//     <div
//       className={clsx(styles.listView, currentSelection && styles.selectedLane)}
//     >
//       <span className={styles.listTitle}>{title || 'Select'}</span>
//       {listItems?.map(item => (
//         <button
//           key={item.label}
//           type="button"
//           onClick={() => (item.action ? item.action() : handleClick(item))}
//           className={clsx(
//             styles.listItem,
//             currentSelection === item && styles.selected,
//           )}
//         >
//           {item.label}
//         </button>
//       ))}
//     </div>
//   );
// }

// ListView.propTypes = {
//   listItems: PropTypes.arrayOf(
//     PropTypes.shape({
//       label: PropTypes.string.isRequired,
//       action: PropTypes.func,
//     }),
//   ),
//   handleSelection: PropTypes.func.isRequired,
//   title: PropTypes.string,
// };
// export default ListView;

import React, {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import styles from './ListView.module.css';

function ListView({listItems = [], handleSelection, title}) {
  const [currentSelection, setCurrentSelection] = useState(null);
  const handleClick = item => {
    handleSelection(item);
    setCurrentSelection(item);
  };
  if (!listItems?.length) return null;
  return (
    <div
      className={clsx(styles.listView, currentSelection && styles.selectedLane)}
    >
      <div className={clsx(styles.scrollContainer, styles.scrollBar)}>
        <span className={styles.listTitle}>{title || 'Select'}</span>
        {listItems?.map(item => (
          <button
            key={item.label}
            type="button"
            onClick={() => (item.action ? item.action() : handleClick(item))}
            className={clsx(
              item.customLabel ? item.customLabel : '',
              styles.listItem,
              currentSelection === item && styles.selected,
            )}
          >
            {item.label}
          </button>
        ))}
      </div>
    </div>
  );
}
ListView.propTypes = {
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      action: PropTypes.func,
      customLabel: PropTypes.string,
    }),
  ),
  handleSelection: PropTypes.func.isRequired,
  title: PropTypes.string,
};
export default ListView;