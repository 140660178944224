/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */

import React from 'react'
import PropTypes from 'prop-types'
import { AutoSizer, InfiniteLoader, List } from 'react-virtualized';
import clsx from 'clsx';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import styles from './ProcessList.module.css'
import { PPAP_STATE } from '../../../constants';

function ProcessList({ data, pagination, handleClick, searchInput }) {
  const { last, setState } = pagination;

  const isRowLoaded = ({ index }) => !!data[index];

  const loadMoreRows = () =>
    new Promise((resolve) => {
      if (!last) {
        setState(prev => ({
          ...prev,
          number: prev.number + 1
        }))
        resolve();
      }
      else {
        resolve();
      }
    })
    
  function rowRenderer({ key, index, style }) {
    return (
      <div
        key={key}
        style={style}
      >
        <div
          className={clsx(
            styles.listItem,
            data[index].isSelected ? styles.selected : styles.unSelected
          )}
          onClick={() => handleClick(index, data, setState)}
          role="button"
          tabIndex={0}
          style={{ cursor: 'pointer' }}
          data-testid={`list-item-${data[index].ppapId}`}
        >

          <div className={clsx(
            styles.listItemHeader,
            data[index].isSelected ? styles.listItemHeader1 : styles.listItemHeader)}>
            <span className={styles.title}>PPAP No : {data[index].ppapId}</span>
            {
              data[index]?.state &&
              <div className={clsx(
                styles.state,
                data[index]?.state === PPAP_STATE.TERMINATE && styles.terminate,
                data[index]?.state === PPAP_STATE.COMPLETE && styles.complete,
              )}>
                <span>{data[index]?.state}</span>
              </div>
            }
          </div>
          <div className={styles.listItemBody}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
              <span className={styles.partNumber}>{data[index].partNumber}</span>
              <span className={styles.commodity}>{data[index].commodity}</span>
            </div>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}>
              <span className={styles.supplierCode}>{
                data[index].description
                  ? data[index].description
                  : data[index].supplierCode
              }</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={styles.listContainer}>
        <div style={{padding:'10px'}}>
          <div className={styles.inputWrapper}>
            <SearchOutlined className={styles.searchIcon}/>
            <input onKeyUp={(e) => searchInput(e.target.value)} className={styles.inputBox} type='text' placeholder='Search by ppap no.'/>
          </div>
        </div>
        {!data || data.length === 0 && <div style={{ textAlign: 'center'}}>No result found</div>}
        <div className={styles.listWrapper} >
        <InfiniteLoader
          isRowLoaded={isRowLoaded}
          loadMoreRows={loadMoreRows}
          rowCount={data.length + 1}
          threshold={1}
        >
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer>
              {({ width, height }) => (
                <List
                  width={width}
                  height={height}
                  onRowsRendered={onRowsRendered}
                  ref={registerChild}
                  rowCount={data.length}
                  rowHeight={100}
                  rowRenderer={rowRenderer}
                  className={styles.list} 
                  />
              )}
            </AutoSizer>
          )}
        </InfiniteLoader>
        </div>
      </div>
    </>
  )
}

ProcessList.propTypes = {
  data: PropTypes.array,
  pagination: PropTypes.any,
  handleClick: PropTypes.func,
  searchInput: PropTypes.func
}

export default ProcessList;