/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux'

import BackButton from '../../components/BackButton/BackButton';
import styles from './MilesStonePage.module.css';
// import  ChassisTransactionForm  from '../../components/ChassisTransactionForm/ChassisTransactionForm';
import  MilesStoneP  from '../../components/MilesStone/MilesStone';
import  StepBar  from '../../components/StepBar/StepBar';
import  StepBar1  from '../../components/StepBar1/StepBar';


import { CustomTab } from '../../atomicComponents';

function MilesStonePage({product}) {
  const history = useHistory();
  const redirectToProcessPage = () => {
    history.push('/');
  };
const chassis=product.chassisData.chassisDetails.chs_no
const materialNo = product.chassisData.chassisDetails.chs_stm_no;

  return (
    <>
      <div className={styles.container} style={{ boxSizing: 'border-box' }}>
      <div className={styles.backButtonLastRefreshedDateWrapper}>
          <BackButton
            action="Home"
            handleClick={redirectToProcessPage}
          />
        </div>
        <div className={styles.processHeader}>
          <div style={{ width: '15%' }}>
            <CustomTab title="Milestone Calendar-CV" withOutCount isSelected />
          </div>
          {/* <div className={styles.processTopbar} style={{
          
              }}>

              </div> */}
              {/* <StepBar/> */}
              <StepBar1 enable={1}/>
 
        </div>
     
         <MilesStoneP redirectToProcessPage={redirectToProcessPage}/> 

      </div>
    </>
  )
}
const mapStateToProps = state => ({
  product: state.product,
  counter: state.counter.value,
  baseUrl: state.counter.baseUrl,
  auth: state.auth
});
// eslint-disable-next-line no-unused-vars
const mapDispatchToProps = (dispatch) => ({

});
MilesStonePage.propTypes = {

  product: PropTypes.object,

};
export default connect(mapStateToProps, mapDispatchToProps)(MilesStonePage); 
