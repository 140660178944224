import { TABLEROWSTORE } from "../actions/productsActions";

export const productsInitialState = {
    tableRow: { 
        singleRow:[],
    }

};

const tableReducer = (state = productsInitialState, action) => {
    switch (action.type) {

        case TABLEROWSTORE:

            return {
                ...state, tableRow: {
                    singleRow: action.payload.tableData,
                }
            };

        default:
            return state;
    }
};

export default tableReducer;
