import React from 'react';
import moment from 'moment';

import styles from './Footer.module.css';


const Footer = () => (
  <div className={styles.footer}>
    <span className={styles.footerText}>
      Copyright {moment().year()} <span className={styles.companyName}>Tata Motors</span>.
      All rights reserved
    </span>
  </div>
);

export default Footer;
