import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TextareaAutosize as MuiTextareaAutosize } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
// import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base'
// import TextareaAutosize from '@mui/material/TextareaAutosize';

const useStyles = makeStyles({
  root: {
    resize: 'none',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    '.makeStyles-root-26 .Mui-disabled': {},
    overflowY: 'auto',
    '--scrollbar-width': 'thin',
    'scrollbar-width': 'thin', // Adjust the scrollbar width for Firefox
    // '-moz-scrollbar-width': '5px', // Older Firefox versions
    // '-moz-scrollbar-color': '#ccc transparent',
    '& .MuiOutlinedInput-root': {
      background: '#fff',
      // maxHeight: '40px'
    },
    '&::-webkit-scrollbar': {
      width: '5px' // Adjust the width of the scrollbar for WebKit browsers
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E9DEAE'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E9DEAE'
    },
    '&	.Mui-disabled': {
      backgroundColor: '#f3f5f9'
    },

    border: '1px solid #b4b6b9', // Set border color and width
    borderRadius: '4px', // Set border radius
    // color: '#97989a',
    color: '#707070',
    alignItems: 'center',
    '&:hover': {
      borderColor: '#E9DEAE' // Set hover border color
    },

    '&:focus': {
      borderColor: '#E9DEAE', // Set focus border color
      outline: 'none',
      borderWidth: '2px'
    },

    '&:disabled' : {
      backgroundColor: '#f3f5f9 !important',
      color: '#97989a'
    },

    // '& .MuiOutlinedInput-multiline': {
    //   maxHeight: '100px', // Set the maximum height before scrolling starts
    //   overflowY: 'auto', // Enable vertical scrolling
    // },

    // '& .MuiInputBase-inputMultiline': {
    //   padding: '9px 14px', // Adjust padding for multiline input
    // },

    // position: 'relative',
    // '&::after': {
    //   content: '""',
    //   position: 'absolute',
    //   height: '80%',
    //   width: '1px',
    //   background: '#d5d5d5',  // Adjust the color of the vertical line
    //   right: 35,
    //   top: 4,
    // },
    scrollableInput: {
      maxHeight: '100px', // Set the maximum height before scrolling starts
      overflowY: 'auto' // Enable vertical scrolling
    }
  }
})

const CustomTextareaAutosize = ({
  isDisabled,
  isMandatory,
  markIfEmpty,
  validationFn,
  validationHelperText,
  value,
  resetAllVisitedFields,
  type,
  style,
  ...props
}) => {
  const classes = useStyles()

  const [isVisited, setIsVisited] = useState(false)
  const shouldMarkRed =
    ((isMandatory && isVisited) || markIfEmpty) && !value && !isDisabled
  const isValueValid = validationFn && value ? validationFn(value) : true

  useEffect(() => {
    if (resetAllVisitedFields && isVisited) setIsVisited(false)
  }, [resetAllVisitedFields, isVisited])

  return (
    <MuiTextareaAutosize
      onBlur={() => setIsVisited(true)}
      error={shouldMarkRed || !isValueValid}
      helperText={!isValueValid ? validationHelperText : null}
      value={value}
      {...props}
      className={classes.root}
      disabled={isDisabled}
      type={type}
      style={style}
    />
  )
}

CustomTextareaAutosize.propTypes = {
  isDisabled: PropTypes.bool,
  isMandatory: PropTypes.bool,
  markIfEmpty: PropTypes.bool,
  validationFn: PropTypes.func.isRequired,
  validationHelperText: PropTypes.string,
  value: PropTypes.string,
  resetAllVisitedFields: PropTypes.bool,
  type: PropTypes.string,
  style: PropTypes.object
}

export default CustomTextareaAutosize
