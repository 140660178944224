/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Form,
  Box,
  TextField,
  Grid,
  FormGroup,
  IconButton,
} from "@material-ui/core";
import WarningIcon from "@mui/icons-material/Warning";
import FormLabel from "@mui/material/FormLabel";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
// for print user in consol
import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";
// for print user in consol
import axios from "axios";

import clsx from "clsx";
// import $ from 'jquery';
import { useHistory } from "react-router-dom";
import Table from "../../components/Table/Table";
import styles from "./VendarDataPV.module.css";
import { CustomFormGroup, CustomSelect } from "../../components/FormComponents";
import BackButton from "../../components/BackButton/BackButton";
import {
  DATE_FORMAT,
  API_RESOURCE_URLS,
  DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  MESSAGE_TYPE_CERTIFICATION,
  MESSAGE_TYPE_ANNEXURE,
  RESOURCE_TYPE,
  USER_OPERATIONS,
  Regex,
} from "../../constants";
import { withAllowedOperationsProvider } from "../../hocs";
import { CustomTab, Button, AuthChecker } from "../../atomicComponents";
import { API } from "../../apis/api";
import { buildErrorMessage } from "../../apis/calls";
import { usePopupManager } from "../../providers/PopupManager/PopupManager";

import StepBar1 from '../../components/StepBar1/StepBar';

const VendarDataPV = () => {


  const history = useHistory();
  const { showPopup } = usePopupManager();
  // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector((state) => state.counter.baseUrl); // useSelector
  const auth = useSelector((state) => state.auth); // useSelector

  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [dynamicColumns1, setDynamicColumns1] = useState([]);
  const [plant, setPlant] = useState(null);
  const [flag, show] = useState(false);
  const [checkDisable, setDisable] = useState(true);
  const [Bc, setBC] = useState(null);
  const [bcArray, setBcArray] = useState([]);
  const [plantArray, setPlantArray] = useState([]);
  const [tableArray, setTableArray] = useState([]);
  const [asnCount, setAsnCount] = useState(0);
  const [loading, setLoader] = useState(false);
  const [loader, setLoading] = useState(true);

  const [vendCode, setvendCode] = useState();

  const ROW_HEIGHT = 38;

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleOpen = () => setIsPopupOpen(true);
  const handleClose = () => setIsPopupOpen(false);
  const [datesData, setDates] = useState({
    endDate: "",
    startDate: "",
  });


  // 
  const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false);
  const handleOpenFormModal = () => {
    setPopupOpenFormModal(true);
  }
  const handleCloseFormModal = () => setPopupOpenFormModal(false);

  //
  const goNext = () => {
    uploadFileData();
  };

  const redirectToApplicationLandingPage = () => {
    history.push("/");
  };

  const setCount = (count) => {
    setAsnCount(count);
  };

  useEffect(() => {
    getData();
    getdate();
    //  Uploadcertification();
  }, []);

  const getdate = () => {

    const headerss = new Headers();
    headerss.append("Content-Type", "application/json");
    fetch(`${baseUrl}Milestone_calendar_PV_View/`, {
      method: "GET",
      headers: headerss,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const endDate = responseJson[0].Collect_DVA_from_vendors_EndDate;
        const startDate = responseJson[0].Collect_DVA_from_vendors_StartDate;
        setDates((prevData) => ({ ...prevData, endDate, startDate }));

      })
      .catch((error) => { });
  };
  // useEffect(()=>{
  //   console.log("bcArray","effect")

  //   const {userRole,userID }=auth;

  //   if(userRole.includes("Vendor-CV") || userRole.includes("Vendor-PV"))
  //     {
  //    const singleVendor = bcArray.filter(element => element.value ==userID);
  //    if(singleVendor.length>=1)
  //    {
  //     setBC(singleVendor[0])
  //     console.log(bcArray,"render setbc",userID)
  //     setDisable(true)
  //    } else{
  //     console.log(bcArray,"render empty else",userID,singleVendor)

  //    }

  //    } else{
  //     setDisable(false)
  //    }

  // },[bcArray])
  //  useEffect(()=>{
  //   const {userRole,userID }=auth;

  //   if(userRole.includes("Vendor-CV") || userRole.includes("Vendor-PV"))
  //     {
  //       console.log(Bc,"render")
  //    showTable()
  //    } else{
  //     setDisable(false)
  //    }

  // },[Bc])

  const showTable = () => {
    getTableData();
    show(true);
  };

  const handleSelect = (selectedOption, name) => {
    // alert(selectedOption)
    // console.log(selectedOption);	
    setvendCode(selectedOption.value);

    if (name == "VC") {
      show(false);

      setBC(selectedOption);
      // getPlantData(selectedOption)
    } else {
      setPlant(selectedOption);
    }
  };

  const getTableData = () => {
    const apiFormatedData = {
      VC: Bc.value,
    };
    var apiFormatedDataJson = JSON.stringify(apiFormatedData);
    let headerss = new Headers();
    headerss.append("Content-Type", "application/json");
    fetch(`${baseUrl}Venodr_wise_Data_By_Vendor_code_for_PV/`, {
      method: "POST",
      headers: headerss,
      body: apiFormatedDataJson,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const tableData = responseJson.data;

        setTableArray(tableData);
        const excelData = tableData.map(
          (
            {
              Vendor_Code,
              Plant_code,
              Part_number,
              Part_Description,
              PO_Number,
              PO_Date,
              PO_Price,
              Order_Price_unit,
              Unit_of_Measure_BOM,
              T1_Import,
              T2_Import,
              Exception,
              Exception_Classification,
              Total_Import,
              DVA,
              // Claimed_for_PLI,
              // Start_Date,
              Explanation,
            },
            id
          ) => {
            let obj = {
              id,
              Vendor_Code,
              Plant_code,
              Part_number,
              Part_Description,
              PO_Number,
              PO_Date,
              PO_Price,
              Order_Price_unit,
              Unit_of_Measure_BOM,
              T1_Import,
              T2_Import,
              Exception,
              Exception_Classification,
              Total_Import,
              DVA,
              // Claimed_for_PLI,
              // Start_Date,
              Explanation,
            };
            return obj;
          }
        );

        // exportExcel(excelData);
        setLoading(false)
      })
      .catch((error) => { });
  };

  const getTableData1 = (value) => {
    const apiFormatedData = {
      VC: value,
    };
    var apiFormatedDataJson = JSON.stringify(apiFormatedData);
    let headerss = new Headers();
    headerss.append("Content-Type", "application/json");
    fetch(`${baseUrl}Venodr_wise_Data_By_Vendor_code_for_PV/`, {
      method: "POST",
      headers: headerss,
      body: apiFormatedDataJson,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const tableData = responseJson.data;

        setTableArray(tableData);
        const excelData = tableData.map(
          (
            {
              Vendor_Code,
              Plant_code,
              Part_number,
              Part_Description,
              PO_Number,
              PO_Date,
              PO_Price,
              Order_Price_unit,
              Unit_of_Measure_BOM,
              T1_Import,
              T2_Import,
              Exception,
              Exception_Classification,
              Total_Import,
              DVA,
              // Claimed_for_PLI,
              // Start_Date,
              Explanation
            },
            id
          ) => {
            let obj = {
              id,
              Vendor_Code,
              Plant_code,
              Part_number,
              Part_Description,
              PO_Number,
              PO_Date,
              PO_Price,
              Order_Price_unit,
              Unit_of_Measure_BOM,
              T1_Import,
              T2_Import,
              Exception,
              Exception_Classification,
              Total_Import,
              DVA,
              // Claimed_for_PLI,
              // Start_Date,
              Explanation
            };
            return obj;
          }
        );

        exportExcel(excelData);
      })
      .catch((error) => { });
  };

  const getData = () => {
    let headerss = new Headers();
    headerss.append("Content-Type", "application/json");
    fetch(`${baseUrl}Venodr_wise_Data_By_Vendor_code_for_PV/`, {
      method: "GET",
      headers: headerss,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const ListArray = responseJson.map(({ Vendor_Code }) => ({
          value: Vendor_Code,
          label: Vendor_Code,
        }));
        setBcArray(ListArray);

        let { userRole, userID } = auth;

        if (userRole.includes("Vendor-CV") || userRole.includes("Vendor-PV") || userRole.includes("Vendor")) {
          const singleVendor = ListArray.filter(
            (element) => element.value == userID
          );
          if (singleVendor.length >= 1) {
            setBC(singleVendor[0]);
            setDisable(true);
            show(true); // table show
            getTableData1(singleVendor[0].value);
          }
        } else {
          setDisable(false);
        }
      })
      .catch((error) => { });
  };

  const uploadFileData = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.id = "file";
    input.setAttribute(
      "accept",
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    );
    input.click();
    input.onchange = (e) => {
      setLoading(true)
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      fetch(`${baseUrl}Venodr_wise_Data_By_Vendor_code_for_PV/`, {
        method: "PUT",
        body: formData,
      })
        .then((response) => {
          // eslint-disable-next-line radix
          let status1 = parseInt(response.status);
          if (status1 == "201") {
            //   document.removeChild(input);
            getTableData();
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded sucessfully",
            });
          } else {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded failed",
            });
          }
        })

        .catch((error) => { });
    };
  };

  const exportExcel = async (excelData) => {
    var fileTypel =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    var fileExt = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { booktype: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileTypel });
    FileSaver.saveAs(data, `${Bc.value}${fileExt}`);
  };

  const baseDefaultColumns = [
    {
      width: 120,
      title: "Plant code",
      field: "Plant_code",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: "Part number",
      field: "Part_number",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: "Part Description",
      field: "Part_Description",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: "PO Number",
      field: "PO_Number",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: "PO Date",
      field: "PO_Date",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: "PO Price INR",
      field: "PO_Price",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: "Order Price Unit",
      field: "Order_Price_unit",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: "Unit of Measure(BOM)",
      field: "Unit_of_Measure_BOM",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 150,
      title: "T1 Import INR",
      field: "T1_Import",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 150,
      title: "T2 Import INR",
      field: "T2_Import",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: "Exception",
      field: "Exception",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 150,
      title: "Exception Classification ",
      field: "Exception_Classification",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: "Total Import INR",
      field: "Total_Import",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: "DVA",
      field: "DVA",
      enableSearch: true,
      enableFilter: true,
    },
    // {
    //   width: 120,
    //   title: "Claimed for PLI",
    //   field: "Claimed_for_PLI",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 120,
    //   title: "Start Date",
    //   field: "Start_Date",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 120,
      title: "Explanation",
      field: "Explanation",
      enableSearch: true,
      enableFilter: true,
    },

  ];
  const goNextForm = () => {
    var url;
    var urlMethod;

    let { userRole, userID } = auth;

    if (userRole.includes("GDC")) {
      url = "VendorMISProgressTesting_PV";
      urlMethod = "PUT";
    } else if (userRole.includes("Vendor") || userRole.includes("Vendor-PV")) {
      url = "VendorMISProgressTesting_PV";
      urlMethod = "POST";
    } else {
      url = "VendorMISProgressTesting_PV";
      urlMethod = "POST";
    }
    const apiFormatedData = {
      VC: Bc.value,


    }

    var apiFormatedDataJson = JSON.stringify(apiFormatedData);
    let headerss = new Headers();
    headerss.append('Content-Type', 'application/json');
    fetch(`${baseUrl}${url}/`,
      {
        method: urlMethod,
        headers: headerss,
        body: apiFormatedDataJson
      }).then((response) => response.json())
      .then((responseJson) => {
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: "Data is Succesfully Saved"

        });
        history.push('/')

      })
      .catch((error) => {

      });

  };


  const redirectToCreateProjectPage = () => history.push("/");
  const renderHTML = () => (
    <div className={styles.formGroup}>

      <Grid
        container
        spacing={1}

        style={{ display: 'flex', justifyContent: "left", marginTop: "5px", marginBottom: "1px" }}
        columns={12}
      >
        <Grid item md={5} lg={5}>
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{ width: "20%" }}>
              <label className={styles.label}>Supplier</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name="bc"
                options={bcArray}
                isDisabled={checkDisable}
                className={clsx(styles.select, styles.sel1)}
                value={Bc}
                isMulti={false}
                // isClearable
                onChange={(e) => {
                  handleSelect(e, "VC");
                }}
              />
              <div
                style={{ marginLeft: "5px", marginTop: "1px", display: "flex" }}


              >

                <AuthChecker operation="search">
                  {isAuthorized => (
                    <Button
                      className={clsx(
                        styles.actionButton,
                        styles.primaryActionButton,
                      )}
                      variant="primary"
                      //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
                      onClick={showTable}
                      disabled={!isAuthorized}
                      data-testid="confirm-action"
                    >
                      Show Data
                    </Button>
                  )}

                </AuthChecker>

                {
                  loader == false &&

                  <>
                    <AuthChecker operation="download">

                      {isAuthorized => (
                        <Button
                          className={clsx(
                            styles.actionButton,
                            styles.primaryActionButton,
                          )}
                          variant="primary"
                          //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
                          onClick={() => {

                            if (tableArray.length >= 1) { exportExcel(tableArray); }
                          }}
                          disabled={!isAuthorized}
                          data-testid="confirm-action"
                          style={{ marginLeft: "5px" }}

                        >
                          download
                        </Button>
                      )}

                    </AuthChecker>

                  </>
                }


              </div>
            </div>
          </div>

        </Grid>

      </Grid>
      {/* <div className={styles.formGroup}>
            <div className={styles.formGroupRow}>
              <div className={styles.formRow}>
                <label className={styles.label}>Vendor Code</label>
                <CustomSelect
                  name="bc"
                  options={bcArray}
                  isDisabled={checkDisable}
                  className={clsx(styles.select, styles.sel1)}
                  value={Bc}
                  isMulti={false}
                  isClearable
                  onChange={(e) => {
                    handleSelect(e, "VC");
                  }}
                />
              </div>
              <div className={styles.formRow}>
                <label className={styles.label}>
                  <Button
                    className={clsx(
                      styles.actionButton,
                      styles.primaryActionButton
                    )}
                    variant="primary"
                    onClick={showTable}
                  >
                    Download Data
                  </Button>
                </label>
    
              </div>
            </div>
    
    
          </div> */}
    </div>
  );

  const downloadFile = () => {
    // axios.get(`${baseUrl}ResultList/`, {responseType: 'blob'}).then((response) => {

    //   // Log somewhat to show that the browser actually exposes the custom HTTP header
    //   const fileNameHeader = "x-suggested-filename";
    //   const suggestedFileName = response.headers[fileNameHeader];
    //   const effectiveFileName = (suggestedFileName === undefined
    //               ? "certification.pdf"
    //               : suggestedFileName);
    //   console.log(`Received header [${fileNameHeader}]: ${suggestedFileName}, effective fileName: ${effectiveFileName}`);

    //   // Let the user save the file.
    //   FileSaver.saveAs(response.data, effectiveFileName);

    //   }).catch((response) => {
    //       console.error("Could not Download the Excel report from the backend.", response);
    //   });
    fetch(`${baseUrl}ResultListPV/`).then((response) => {
      console.log(response);

      const success = response.ok;

      response
        .blob()
        .then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values

          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = "Certificate.pdf";
          alink.click();

        }).then(() => {
          if (!success) {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "File is Sucessfully Downloaded",
            });
          }

        })
        .catch((error) => {
          // catch any unexpected errors
          console.log(error);
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: "Document download failed.",
          });
        });
    });
  };

  const getUrl = (code) => {
    var msg = code;

    return <div>
      <p>Certificate Uploaded Successfully</p>
      <a href={msg} target="_blank" rel="noreferrer">
        {msg}
      </a>
    </div>
  }
  const Uploadcertification = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.id = "file";
    input.setAttribute("accept", ".pdf");
    input.click();


    input.onchange = (e) => {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("Vendor_code", vendCode);
      fetch(`${baseUrl}PVFileUploadView/`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          var certificateUrl = responseJson.URL
          if (responseJson) {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: "Success",
              //  contextText:getUrl(certificateUrl),
              info: "Certificate Uploaded Successfully"



            });
          } else {
            showPopup({
              type: MESSAGE_TYPE_CERTIFICATION.FAILURE,
              contextText: MESSAGE_TYPE_CERTIFICATION.FAILURE,
              info: responseJson.url,
            });
          }

          // eslint-disable-next-line radix
          // let status1 = parseInt(response.status);
          //  console.log(response.status)
          // if (status1 == "200") {
          //   //   document.removeChild(input);
          // //  getTableData();

          // }
        })

        .catch((error) => {

          showPopup({

            type: MESSAGE_TYPE_CERTIFICATION.FAILURE,
            contextText: MESSAGE_TYPE_CERTIFICATION.FAILURE,
            //  info: responseJson.url,
          });
        });
    };
  };
  const Uploadannexure = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.id = "file";
    input.setAttribute("accept", ".pdf");
    input.click();
    input.onchange = (e) => {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("Vendor_code", vendCode);
      fetch(`${baseUrl}PVAnnexureFileUploadView/`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          if (responseJson) {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: "Success",
              info: MESSAGE_TYPE_ANNEXURE.SUCCESS,
              //   info: responseJson.url,
            });
          } else {
            showPopup({
              type: MESSAGE_TYPE_ANNEXURE.FAILURE,
              contextText: MESSAGE_TYPE_ANNEXURE.FAILURE,
              // info: responseJson.url,
            });
          }


        })

        .catch((error) => { });
    };
  };


  return (
    <>
      <div className={styles.container} style={{ boxSizing: "border-box" }}>
        <div>
          <div className={styles.backButtonLastRefreshedDateWrapper}>
            <BackButton
              action="Back"
              handleClick={redirectToApplicationLandingPage}
            />
            <div className={styles.topBar} style={{}}>
              <div className={styles.divspan}>Collect DVA from Vendors Start Date:<span>{datesData.startDate}</span></div>
              <div className={styles.divspan}>Collect DVA from Vendors End Date:<span>{datesData.endDate}</span></div>
            </div>
          </div>
          <div className={styles.topDiv}>
            <div className={styles.processHeader}>
              <div style={{ width: "15%" }}>
                <CustomTab title="Supplier Data(PV)" withOutCount isSelected />
              </div>

              <StepBar1 enable={5} />

            </div>
          </div>
        </div>
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup header="Supplier Details" body={renderHTML()} />
          </div>
        </div>
        {flag && (
          <div
            className={styles.tableView}
            style={{ height: "86%", padding: "0 2em" }}
          >
            <Table
              columns={[...baseDefaultColumns]}
              defaultColumns={[...baseDefaultColumns]}
              rowHeight={38}
              rows={tableArray}
              primaryAction={{
                name: "Submit Data",
                authOperation: "submit",
                shouldEnable: () => true,
                actionFn: handleOpenFormModal,
              }}
              secondaryActions={[
                {
                  name: "Upload",
                  authOperation: "upload",
                  shouldEnable: (selected) => true,
                  actionFn: (selected) => {
                    handleOpen();
                  },
                  customClass: "",
                },
                {
                  name: "Generate Certificate",
                  authOperation: "submit",
                  shouldEnable: (selected) => true,
                  actionFn: (selected) => {
                    downloadFile();
                  },
                  customClass: "",
                },
                {
                  name: "Upload Certificate",
                  authOperation: "uploadCertificate",
                  shouldEnable: (selected) => true,
                  actionFn: (selected) => {
                    Uploadcertification();
                  },
                  customClass: "",
                },
                {
                  name: "Annexure Upload",
                  authOperation: "uploadAnnexure",
                  shouldEnable: (selected) => true,
                  actionFn: (selected) => {
                    Uploadannexure();
                  },
                  customClass: "",
                },
              ]}
              setAsnCount={setCount}
              isDataLoading={false}
              actionButtons
              onRowSelect={(e) => { }}
              removeRowSelection
            />
          </div>
        )}
      </div>
      {/* <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            // onClick={onDelete}
          >
            Yes
          </Button> */}
      <ResetModalF
        isPopupOpen={isPopupOpenFormModal}
        handleCloseFormModal={handleCloseFormModal}
        goNextForm={goNextForm}
      />

      <ResetModal
        isPopupOpen={isPopupOpen}
        handleClose={handleClose}
        goNext={goNext}
      />
    </>
  );
};
function ResetModalF({ isPopupOpen, handleCloseFormModal, goNextForm }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>Confirm Submit</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation ,VC BoM data will submit</p>

            <p>Are you sure ?</p>

          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleCloseFormModal}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleCloseFormModal()
              goNextForm()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function ResetModal({ isPopupOpen, handleClose, goNext }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: "18px", width: "18px" }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>
              Confirm file Upload
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p>
              {" "}

              On confirmation ,Records will be appended.
            </p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleClose();
              goNext();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}
ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
};
ResetModalF.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleCloseFormModal: PropTypes.func.isRequired,
  goNextForm: PropTypes.func.isRequired,
};


export default withAllowedOperationsProvider(VendarDataPV, RESOURCE_TYPE.PROJECT);






















// /* eslint-disable react/self-closing-comp */
// /* eslint-disable vars-on-top */
// /* eslint-disable eqeqeq */
// /* eslint-disable prefer-const */
// /* eslint-disable no-var */
// /* eslint-disable jsx-a11y/label-has-associated-control */
// /* eslint-disable no-use-before-define */
// /* eslint-disable array-callback-return */
// /* eslint-disable camelcase */
// /* eslint-disable react/no-unused-prop-types */
// /* eslint-disable no-unused-vars */
// import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";

// import {
//   Modal,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Form,
//   Box,
//   TextField,
//   Grid,
//   FormGroup,
//   IconButton,
// } from "@material-ui/core";
// import WarningIcon from "@mui/icons-material/Warning";
// import FormLabel from "@mui/material/FormLabel";
// import * as FileSaver from "file-saver";
// import XLSX from "sheetjs-style";
// // for print user in consol
// import jwt_decode from "jwt-decode";
// import { useSelector } from "react-redux";
// // for print user in consol
// import axios from "axios";

// import clsx from "clsx";
// // import $ from 'jquery';
// import { useHistory } from "react-router-dom";
// import Table from "../../components/Table/Table";
// import styles from "./VendarDataPV.module.css";
// import { CustomFormGroup, CustomSelect } from "../../components/FormComponents";
// import BackButton from "../../components/BackButton/BackButton";
// import {
//   DATE_FORMAT,
//   API_RESOURCE_URLS,
//   DISPLAY_MESSAGES,
//   MESSAGE_TYPE,
//   MESSAGE_TYPE_CERTIFICATION,
//   MESSAGE_TYPE_ANNEXURE,
//   RESOURCE_TYPE,
//   USER_OPERATIONS,
//   Regex,
// } from "../../constants";
// import { withAllowedOperationsProvider } from "../../hocs";
// import { CustomTab, Button } from "../../atomicComponents";
// import { API } from "../../apis/api";
// import { buildErrorMessage } from "../../apis/calls";
// import { usePopupManager } from "../../providers/PopupManager/PopupManager";

// import StepBar1 from '../../components/StepBar1/StepBar';

// const VendarDataPV = () => {


//   const history = useHistory();
//   const { showPopup } = usePopupManager();
//   // const baseUrl='http://127.0.0.1:8000/file/'
//   const baseUrl = useSelector((state) => state.counter.baseUrl); // useSelector
//   const auth = useSelector((state) => state.auth); // useSelector

//   const [dynamicColumns, setDynamicColumns] = useState([]);
//   const [dynamicColumns1, setDynamicColumns1] = useState([]);
//   const [plant, setPlant] = useState(null);
//   const [flag, show] = useState(false);
//   const [checkDisable, setDisable] = useState(true);
//   const [Bc, setBC] = useState(null);
//   const [bcArray, setBcArray] = useState([]);
//   const [plantArray, setPlantArray] = useState([]);
//   const [tableArray, setTableArray] = useState([]);
//   const [asnCount, setAsnCount] = useState(0);
//   const [loading, setLoader] = useState(false);

//   const [vendCode, setvendCode] = useState();

//   const ROW_HEIGHT = 38;

//   const [isPopupOpen, setIsPopupOpen] = useState(false);
//   const handleOpen = () => setIsPopupOpen(true);
//   const handleClose = () => setIsPopupOpen(false);
//   const [datesData, setDates] = useState({
//     endDate:"",
//     startDate:"",
//   });

  
//     // 
//     const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false);
//     const handleOpenFormModal= () => {
//       setPopupOpenFormModal(true);}
//     const handleCloseFormModal= () => setPopupOpenFormModal(false);
  
//     //
//   const goNext = () => {
//     uploadFileData();
//   };

//   const redirectToApplicationLandingPage = () => {
//     history.push("/");
//   };

//   const setCount = (count) => {
//     setAsnCount(count);
//   };

//   useEffect(() => {
//     getData();
//     getdate();
//   //  Uploadcertification();
//   }, []);

//   const getdate = () => {
  
//     const headerss = new Headers();
//     headerss.append("Content-Type", "application/json");
//     fetch(`${baseUrl}Milestone_calendar_PV_View/`, {
//       method: "GET",
//       headers: headerss,
//     })
//       .then((response) => response.json())
//       .then((responseJson) => {
//         const endDate = responseJson[0].Collect_DVA_from_vendors_EndDate;
//         const startDate = responseJson[0].Collect_DVA_from_vendors_StartDate;
//         setDates((prevData)=>({...prevData,endDate,startDate}));
       
//       })
//       .catch((error) => {});
//   };
//   // useEffect(()=>{
//   //   console.log("bcArray","effect")

//   //   const {userRole,userID }=auth;

//   //   if(userRole.includes("Vendor-CV") || userRole.includes("Vendor-PV"))
//   //     {
//   //    const singleVendor = bcArray.filter(element => element.value ==userID);
//   //    if(singleVendor.length>=1)
//   //    {
//   //     setBC(singleVendor[0])
//   //     console.log(bcArray,"render setbc",userID)
//   //     setDisable(true)
//   //    } else{
//   //     console.log(bcArray,"render empty else",userID,singleVendor)

//   //    }

//   //    } else{
//   //     setDisable(false)
//   //    }

//   // },[bcArray])
//   //  useEffect(()=>{
//   //   const {userRole,userID }=auth;

//   //   if(userRole.includes("Vendor-CV") || userRole.includes("Vendor-PV"))
//   //     {
//   //       console.log(Bc,"render")
//   //    showTable()
//   //    } else{
//   //     setDisable(false)
//   //    }

//   // },[Bc])

//   const showTable = () => {
//     getTableData();
//     show(true);
//   };

//   const handleSelect = (selectedOption, name) => {
//     // alert(selectedOption)
//     // console.log(selectedOption);	
//     setvendCode(selectedOption.value);

//     if (name == "VC") {
//       show(false);

//       setBC(selectedOption);
//       // getPlantData(selectedOption)
//     } else {
//       setPlant(selectedOption);
//     }
//   };

//   const getTableData = () => {
//     const apiFormatedData = {
//       VC: Bc.value,
//     };
//     var apiFormatedDataJson = JSON.stringify(apiFormatedData);
//     let headerss = new Headers();
//     headerss.append("Content-Type", "application/json");
//     fetch(`${baseUrl}Venodr_wise_Data_By_Vendor_code_for_PV/`, {
//       method: "POST",
//       headers: headerss,
//       body: apiFormatedDataJson,
//     })
//       .then((response) => response.json())
//       .then((responseJson) => {
//         const tableData = responseJson.data;

//         setTableArray(tableData);
//         const excelData = tableData.map(
//           (
//             {
//               Vendor_Code,
//               Plant_code,
//               Part_number,
//               Part_Description,
//               PO_Number,
//               PO_Date,
//               PO_Price,
//               Order_Price_unit,
//               Unit_of_Measure_BOM,
//               T1_Import,
//               T2_Import,
//               Exception,
//               Exception_Classification,
//               Total_Import,
//               DVA,
//               // Claimed_for_PLI,
//               // Start_Date,
//               Explanation,
//             },
//             id
//           ) => {
//             let obj = {
//               id,
//               Vendor_Code,
//               Plant_code,
//               Part_number,
//               Part_Description,
//               PO_Number,
//               PO_Date,
//               PO_Price,
//               Order_Price_unit,
//               Unit_of_Measure_BOM,
//               T1_Import,
//               T2_Import,
//               Exception,
//               Exception_Classification,
//               Total_Import,
//               DVA,
//               // Claimed_for_PLI,
//               // Start_Date,
//               Explanation,
//             };
//             return obj;
//           }
//         );

//         exportExcel(excelData);
//       })
//       .catch((error) => {});
//   };

//   const getTableData1 = (value) => {
//     const apiFormatedData = {
//       VC: value,
//     };
//     var apiFormatedDataJson = JSON.stringify(apiFormatedData);
//     let headerss = new Headers();
//     headerss.append("Content-Type", "application/json");
//     fetch(`${baseUrl}Venodr_wise_Data_By_Vendor_code_for_PV/`, {
//       method: "POST",
//       headers: headerss,
//       body: apiFormatedDataJson,
//     })
//       .then((response) => response.json())
//       .then((responseJson) => {
//         const tableData = responseJson.data;

//         setTableArray(tableData);
//         const excelData = tableData.map(
//           (
//             {
//               Vendor_Code,
//               Plant_code,
//               Part_number,
//               Part_Description,
//               PO_Number,
//               PO_Date,
//               PO_Price,
//               Order_Price_unit,
//               Unit_of_Measure_BOM,
//               T1_Import,
//               T2_Import,
//               Exception,
//               Exception_Classification,
//               Total_Import,
//               DVA,
//               // Claimed_for_PLI,
//               // Start_Date,
//               Explanation
//             },
//             id
//           ) => {
//             let obj = {
//               id,
//               Vendor_Code,
//               Plant_code,
//               Part_number,
//               Part_Description,
//               PO_Number,
//               PO_Date,
//               PO_Price,
//               Order_Price_unit,
//               Unit_of_Measure_BOM,
//               T1_Import,
//               T2_Import,
//               Exception,
//               Exception_Classification,
//               Total_Import,
//               DVA,
//               // Claimed_for_PLI,
//               // Start_Date,
//               Explanation
//             };
//             return obj;
//           }
//         );

//         exportExcel(excelData);
//       })
//       .catch((error) => {});
//   };

//   const getData = () => {
//     let headerss = new Headers();
//     headerss.append("Content-Type", "application/json");
//     fetch(`${baseUrl}Venodr_wise_Data_By_Vendor_code_for_PV/`, {
//       method: "GET",
//       headers: headerss,
//     })
//       .then((response) => response.json())
//       .then((responseJson) => {
//         const ListArray = responseJson.map(({ Vendor_Code }) => ({
//           value: Vendor_Code,
//           label: Vendor_Code,
//         }));
//         setBcArray(ListArray);

//         let { userRole, userID } = auth;

//         if (userRole.includes("Vendor-CV") || userRole.includes("Vendor-PV") || userRole.includes("Vendor")) {
//           const singleVendor = ListArray.filter(
//             (element) => element.value == userID
//           );
//           if (singleVendor.length >= 1) {
//             setBC(singleVendor[0]);
//             setDisable(true);
//             show(true); // table show
//             getTableData1(singleVendor[0].value);
//           }
//         } else {
//           setDisable(false);
//         }
//       })
//       .catch((error) => {});
//   };

//   const uploadFileData = () => {
//     var input = document.createElement("input");
//     input.type = "file";
//     input.id = "file";
//     input.setAttribute(
//       "accept",
//       ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
//     );
//     input.click();
//     input.onchange = (e) => {
//       const formData = new FormData();
//       formData.append("file", e.target.files[0]);
//       fetch(`${baseUrl}Venodr_wise_Data_By_Vendor_code_for_PV/`, {
//         method: "PUT",
//         body: formData,
//       })
//         .then((response) => {
//           // eslint-disable-next-line radix
//           let status1 = parseInt(response.status);
//           if (status1 == "201") {
//             //   document.removeChild(input);
//             getTableData();
//             showPopup({
//               type: MESSAGE_TYPE.SUCCESS,
//               contextText: MESSAGE_TYPE.SUCCESS,
//               info: "file uploaded sucessfully",
//             });
//           } else {
//             showPopup({
//               type: MESSAGE_TYPE.SUCCESS,
//               contextText: MESSAGE_TYPE.SUCCESS,
//               info: "file uploaded failed",
//             });
//           }
//         })

//         .catch((error) => {});
//     };
//   };

//   const exportExcel = async (excelData) => {
//     var fileTypel =
//       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
//     var fileExt = ".xlsx";
//     const ws = XLSX.utils.json_to_sheet(excelData);
//     const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
//     const excelBuffer = XLSX.write(wb, { booktype: "xlsx", type: "array" });
//     const data = new Blob([excelBuffer], { type: fileTypel });
//     FileSaver.saveAs(data, `${Bc.value}${fileExt}`);
//   };

//   const baseDefaultColumns = [
//     {
//       width: 120,
//       title: "Plant code",
//       field: "Plant_code",
//       enableSearch: true,
//       enableFilter: true,
//     },
//     {
//       width: 120,
//       title: "Part number",
//       field: "Part_number",
//       enableSearch: true,
//       enableFilter: true,
//     },
//     {
//       width: 120,
//       title: "Part Description",
//       field: "Part_Description",
//       enableSearch: true,
//       enableFilter: true,
//     },
//     {
//       width: 120,
//       title: "PO Number",
//       field: "PO_Number",
//       enableSearch: true,
//       enableFilter: true,
//     },
//     {
//       width: 120,
//       title: "PO Date",
//       field: "PO_Date",
//       enableSearch: true,
//       enableFilter: true,
//     },
//     {
//       width: 120,
//       title: "PO Price INR",
//       field: "PO_Price",
//       enableSearch: true,
//       enableFilter: true,
//     },
//     {
//       width: 120,
//       title: "Order Price Unit",
//       field: "Order_Price_unit",
//       enableSearch: true,
//       enableFilter: true,
//     },
//     {
//       width: 120,
//       title: "Unit of Measure(BOM)",
//       field: "Unit_of_Measure_BOM",
//       enableSearch: true,
//       enableFilter: true,
//     },
//     {
//       width: 150,
//       title: "T1 Import INR",
//       field: "T1_Import",
//       enableSearch: true,
//       enableFilter: true,
//     },
//     {
//       width: 150,
//       title: "T2 Import INR",
//       field: "T2_Import",
//       enableSearch: true,
//       enableFilter: true,
//     },
//     {
//       width: 120,
//       title: "Exception",
//       field: "Exception",
//       enableSearch: true,
//       enableFilter: true,
//     },
//     {
//       width: 150,
//       title: "Exception Classification ",
//       field: "Exception_Classification",
//       enableSearch: true,
//       enableFilter: true,
//     },
//     {
//       width: 120,
//       title: "Total Import INR",
//       field: "Total_Import",
//       enableSearch: true,
//       enableFilter: true,
//     },
//     {
//       width: 120,
//       title: "DVA",
//       field: "DVA",
//       enableSearch: true,
//       enableFilter: true,
//     },
//     // {
//     //   width: 120,
//     //   title: "Claimed for PLI",
//     //   field: "Claimed_for_PLI",
//     //   enableSearch: true,
//     //   enableFilter: true,
//     // },
//     // {
//     //   width: 120,
//     //   title: "Start Date",
//     //   field: "Start_Date",
//     //   enableSearch: true,
//     //   enableFilter: true,
//     // },
//     {
//       width: 120,
//       title: "Explanation",
//       field: "Explanation",
//       enableSearch: true,
//       enableFilter: true,
//     },
    
//   ];
//   const goNextForm = () => {
//     var url;
//     var urlMethod;
  
//       let { userRole, userID } = auth;
  
//       if (userRole.includes("GDC") ) {
//         url="VendorMISProgress";
//         urlMethod="PUT"; 
//       } else if(userRole.includes("Vendor") || userRole.includes("Vendor-PV")){
//         url="VendorMISProgress";
//         urlMethod="POST"; 
//       } else {
//         url="VendorMISProgress";
//         urlMethod="POST"; 
//       } 
//       const apiFormatedData = {
//         VC: Bc.value,
    
  
//       }
  
//       var apiFormatedDataJson = JSON.stringify(apiFormatedData);
//       let headerss = new Headers();
//       headerss.append('Content-Type', 'application/json');
//       fetch(`${baseUrl}${url}/`,
//         {
//           method: urlMethod,
//           headers: headerss,
//           body: apiFormatedDataJson
//         }).then((response) => response.json())
//         .then((responseJson) => { 
//           showPopup({
//             type: MESSAGE_TYPE.SUCCESS,
//             contextText: MESSAGE_TYPE.SUCCESS,
//             info: "Data is Succesfully Saved"
    
//           });
//           history.push('/')
  
//         })
//         .catch((error) => {
  
//         });
  
//     };
  

//   const redirectToCreateProjectPage = () => history.push("/");
//   const renderHTML = () => (
//     <div className={styles.formGroup}>

//     <Grid
//             container
//             spacing={1}
    
//             style={{ display: 'flex', justifyContent: "left", marginTop: "5px",marginBottom: "1px" }}
//             columns={12}
//           >
//             <Grid item md={5} lg={5}>
//               <div className={styles.rowDiv}>
//                 <div className={clsx(styles.lableDiv)} style={{width:"20%"}}>
//                   <label className={styles.label}>Vendor Code</label>
//                 </div>
//                 <div className={styles.formDiv}>
//                 <CustomSelect
//                   name="bc"
//                   options={bcArray}
//                   isDisabled={checkDisable}
//                   className={clsx(styles.select, styles.sel1)}
//                   value={Bc}
//                   isMulti={false}
//                   // isClearable
//                   onChange={(e) => {
//                     handleSelect(e, "VC");
//                   }}
//                 />
//                 <div                
//                  style={{marginLeft:"20px", marginTop: "1px",justifyContent: "center"}}
//     >
//              <Button className={clsx(
//                     styles.actionButton,
//                     styles.primaryActionButton,
//                   )}
//                     variant="primary"
//                     onClick={showTable}
//                     >
//                     Get Information
    
//                   </Button>
//                   </div>
//                 </div>
//               </div>
      
//             </Grid>
       
//             </Grid>
//           {/* <div className={styles.formGroup}>
//             <div className={styles.formGroupRow}>
//               <div className={styles.formRow}>
//                 <label className={styles.label}>Vendor Code</label>
//                 <CustomSelect
//                   name="bc"
//                   options={bcArray}
//                   isDisabled={checkDisable}
//                   className={clsx(styles.select, styles.sel1)}
//                   value={Bc}
//                   isMulti={false}
//                   isClearable
//                   onChange={(e) => {
//                     handleSelect(e, "VC");
//                   }}
//                 />
//               </div>
//               <div className={styles.formRow}>
//                 <label className={styles.label}>
//                   <Button
//                     className={clsx(
//                       styles.actionButton,
//                       styles.primaryActionButton
//                     )}
//                     variant="primary"
//                     onClick={showTable}
//                   >
//                     Download Data
//                   </Button>
//                 </label>
    
//               </div>
//             </div>
    
    
//           </div> */}
//         </div>
//   );

//   const downloadFile = () => {
//     // axios.get(`${baseUrl}ResultList/`, {responseType: 'blob'}).then((response) => {

//     //   // Log somewhat to show that the browser actually exposes the custom HTTP header
//     //   const fileNameHeader = "x-suggested-filename";
//     //   const suggestedFileName = response.headers[fileNameHeader];
//     //   const effectiveFileName = (suggestedFileName === undefined
//     //               ? "certification.pdf"
//     //               : suggestedFileName);
//     //   console.log(`Received header [${fileNameHeader}]: ${suggestedFileName}, effective fileName: ${effectiveFileName}`);

//     //   // Let the user save the file.
//     //   FileSaver.saveAs(response.data, effectiveFileName);

//     //   }).catch((response) => {
//     //       console.error("Could not Download the Excel report from the backend.", response);
//     //   });
//     fetch(`${baseUrl}ResultListPV/`).then((response) => {
//       console.log(response);

//       const success = response.ok;

//       response
//         .blob()
//         .then((blob) => {
//           // Creating new object of PDF file
//           const fileURL = window.URL.createObjectURL(blob);
//           // Setting various property values

//           let alink = document.createElement("a");
//           alink.href = fileURL;
//           alink.download = "Certificate.pdf";
//           alink.click();
         
//         }).then(() =>{
//           if (!success) {
//             showPopup({
//               type: MESSAGE_TYPE.SUCCESS,
//               contextText: MESSAGE_TYPE.SUCCESS,
//               info: "File is Sucessfully Downloaded",
//             });
//           }
          
//         })
//         .catch((error) => {
//           // catch any unexpected errors
//           console.log(error);
//           showPopup({
//             type: MESSAGE_TYPE.FAILURE,
//             contextText: MESSAGE_TYPE.FAILURE,
//             info: "Document download failed.",
//           });
//         });
//     });
//   };

// 	const getUrl = (code) => {
//       var msg =  code;
    
//           return <div>
//             <p>Certificate Uploaded Successfully</p>
//             <a href={msg}   target="_blank" rel="noreferrer">
//           {msg}
//         </a>
//           </div>
//   }
//   const Uploadcertification = () => {
//     var input = document.createElement("input");
//     input.type = "file";
//     input.id = "file";
//     input.setAttribute("accept", ".pdf");
//     input.click();
   
    
//     input.onchange = (e) => {
//       const formData = new FormData();
//       formData.append("file", e.target.files[0]);
//       formData.append("Vendor_code", vendCode);
//       fetch(`${baseUrl}PVFileUploadView/`, {
//         method: "POST",
//         body: formData,
//       })
//         .then((response) => response.json())
//         .then((responseJson) => {
//          var certificateUrl = responseJson.URL
//           if (responseJson) {
//             showPopup({
//               type: MESSAGE_TYPE.SUCCESS,
//               contextText:"Success",
//             //  contextText:getUrl(certificateUrl),
//               info:"Certificate Uploaded Successfully"
            
            
           
//             });
//           } else {
//             showPopup({
//               type: MESSAGE_TYPE_CERTIFICATION.FAILURE,
//               contextText: MESSAGE_TYPE_CERTIFICATION.FAILURE,
//                info: responseJson.url,
//             });
//           }

//           // eslint-disable-next-line radix
//           // let status1 = parseInt(response.status);
//           //  console.log(response.status)
//           // if (status1 == "200") {
//           //   //   document.removeChild(input);
//           // //  getTableData();

//           // }
//         })

//         .catch((error) => {
     
//           showPopup({
            
//             type: MESSAGE_TYPE_CERTIFICATION.FAILURE,
//             contextText:  MESSAGE_TYPE_CERTIFICATION.FAILURE,
//              //  info: responseJson.url,
//           });
//         });
//     };
//   };
//   const Uploadannexure = () => {
//     var input = document.createElement("input");
//     input.type = "file";
//     input.id = "file";
//     input.setAttribute("accept", ".pdf");
//     input.click();
//     input.onchange = (e) => {
//       const formData = new FormData();
//       formData.append("file", e.target.files[0]);
//       formData.append("Vendor_code", vendCode);
//       fetch(`${baseUrl}PVAnnexureFileUploadView/`, {
//         method: "POST",
//         body: formData,
//       })
//         .then((response) => response.json())
//         .then((responseJson) => {
//           console.log(responseJson);
//           if (responseJson) {
//             showPopup({
//               type: MESSAGE_TYPE.SUCCESS,
//               contextText: "Success",
//               info: MESSAGE_TYPE_ANNEXURE.SUCCESS,
//            //   info: responseJson.url,
//             });
//           } else {
//             showPopup({
//               type: MESSAGE_TYPE_ANNEXURE.FAILURE,
//               contextText: MESSAGE_TYPE_ANNEXURE.FAILURE,
//               // info: responseJson.url,
//             });
//           }


//         })

//         .catch((error) => {});
//     };
//   };


//   return (
//     <>
//       <div className={styles.container} style={{ boxSizing: "border-box" }}>
//         <div>
//           <div className={styles.backButtonLastRefreshedDateWrapper}>
//             <BackButton
//               action="Back"
//               handleClick={redirectToApplicationLandingPage}
//             />
//            <div className={styles.topBar} style={{}}>
//                 <div className={styles.divspan}>Collect DVA from Vendors Start Date:<span>{datesData.startDate}</span></div>
//                 <div className={styles.divspan}>Collect DVA from Vendors End Date:<span>{datesData.endDate}</span></div>
//               </div> 
//           </div>
//           <div className={styles.topDiv}>
//             <div className={styles.processHeader}>
//               <div style={{ width: "15%" }}>
//                 <CustomTab title="Vendor Data(PV)" withOutCount isSelected />
//               </div>
          
//               <StepBar1 enable={5} />

//             </div>
//           </div>
//         </div>
//         <div>
//           <div className={styles.formContainer}>
//             <CustomFormGroup header="Vendor Details" body={renderHTML()} />
//           </div>
//         </div>
//         {flag && (
//           <div
//             className={styles.tableView}
//             style={{ height: "86%", padding: "0 2em" }}
//           >
//             <Table
//               columns={[...baseDefaultColumns]}
//               defaultColumns={[...baseDefaultColumns]}
//               rowHeight={38}
//               rows={tableArray}
//               primaryAction={{
//                 name: "Submit Data",
//                 authOperation: "submit",
//                 shouldEnable: () => true,
//                 actionFn: handleOpenFormModal,
//               }}
//               secondaryActions={[
//                 {
//                   name: "Upload",
//                   authOperation: "upload",
//                   shouldEnable: (selected) => true,
//                   actionFn: (selected) => {
//                     handleOpen();
//                   },
//                   customClass: "",
//                 },
//                 {
//                   name: "Generate Certificate",
//                   authOperation: "submit",
//                   shouldEnable: (selected) => true,
//                   actionFn: (selected) => {
//                     downloadFile();
//                   },
//                   customClass: "",
//                 },
//                 {
//                   name: "Upload Certificate",
//                   authOperation: "uploadCertificate",
//                   shouldEnable: (selected) => true,
//                   actionFn: (selected) => {
//                     Uploadcertification();
//                   },
//                   customClass: "",
//                 },
//                 {
//                   name: "Annexure Upload",
//                   authOperation: "uploadAnnexure",
//                   shouldEnable: (selected) => true,
//                   actionFn: (selected) => {
//                     Uploadannexure();
//                   },
//                   customClass: "",
//                 },
//               ]}
//               setAsnCount={setCount}
//               isDataLoading={false}
//               actionButtons
//               onRowSelect={(e) => {}}
//               removeRowSelection
//             />
//           </div>
//         )}
//       </div>
//       {/* <Button
//             className={clsx(
//               styles.actionButton,
//               styles.primaryActionButton,
//             )}
//             variant="primary"
//             // onClick={onDelete}
//           >
//             Yes
//           </Button> */}
//                         <ResetModalF
//         isPopupOpen={isPopupOpenFormModal}
//         handleCloseFormModal={handleCloseFormModal}
//         goNextForm={goNextForm}
//       />

//       <ResetModal
//         isPopupOpen={isPopupOpen}
//         handleClose={handleClose}
//         goNext={goNext}
//       />
//     </>
//   );
// };
// function ResetModalF({ isPopupOpen, handleCloseFormModal, goNextForm }) {
//   return (
//     <Dialog
//       open={isPopupOpen}
//       className={styles.popContainer}
//       classes={{
//         paper: styles.popupBox,
//       }}
//       data-testid="reset-popup"
//     >
//       <>
//         <DialogTitle>
//           <span className={styles.title}>
//             <WarningIcon style={{ height: '18px', width: '18px' }} />
//             <span style={{ marginLeft: "8px" }} className={styles.txt}>Confirm Submit</span>
//           </span>
//         </DialogTitle>
//         <DialogContent className={styles.content}>
//           <div>
//             {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
//             <p>On confirmation ,VC BoM data will submit</p>

//             <p>Are you sure ?</p>

//           </div>
//         </DialogContent>
//         <DialogActions>
//           <Button
//             className={clsx(
//               styles.actionButton,
//               styles.transparentButton
//             )}
//             onClick={handleCloseFormModal}
//           >Cancel</Button>
//           <Button
//             className={clsx(
//               styles.actionButton,
//               styles.primaryActionButton,
//             )}
//             variant="primary"
//             onClick={(e) => {
//               e.preventDefault();
//               handleCloseFormModal()
//               goNextForm()
//             }}
//           >
//             Yes
//           </Button>
//         </DialogActions>
//       </>
//     </Dialog>
//   )
// }
// function ResetModal({ isPopupOpen, handleClose, goNext }) {
//   return (
//     <Dialog
//       open={isPopupOpen}
//       className={styles.popContainer}
//       classes={{
//         paper: styles.popupBox,
//       }}
//       data-testid="reset-popup"
//     >
//       <>
//         <DialogTitle>
//           <span className={styles.title}>
//             <WarningIcon style={{ height: "18px", width: "18px" }} />
//             <span style={{ marginLeft: "8px" }} className={styles.txt}>
//               Confirm file Upload
//             </span>
//           </span>
//         </DialogTitle>
//         <DialogContent className={styles.content}>
//           <div>
//             <p>
//               {" "}
             
//             On confirmation ,Records will be appended.
//             </p>
//             <p>Are you sure ?</p>
//           </div>
//         </DialogContent>
//         <DialogActions>
//           <Button
//             className={clsx(styles.actionButton, styles.transparentButton)}
//             onClick={handleClose}
//           >
//             Cancel
//           </Button>
//           <Button
//             className={clsx(styles.actionButton, styles.primaryActionButton)}
//             variant="primary"
//             onClick={(e) => {
//               e.preventDefault();
//               handleClose();
//               goNext();
//             }}
//           >
//             Yes
//           </Button>
//         </DialogActions>
//       </>
//     </Dialog>
//   );
// }
// ResetModal.propTypes = {
//   isPopupOpen: PropTypes.bool.isRequired,
//   handleClose: PropTypes.func.isRequired,
//   goNext: PropTypes.func.isRequired,
// };
// ResetModalF.propTypes = {
//   isPopupOpen: PropTypes.bool.isRequired,
//   handleCloseFormModal: PropTypes.func.isRequired,
//   goNextForm: PropTypes.func.isRequired,
// };


// export default withAllowedOperationsProvider(VendarDataPV, RESOURCE_TYPE.PROJECT);
