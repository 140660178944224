/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useRef, useState,useEffect } from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Popover, LinearProgress } from '@material-ui/core';

import styles from './HeaderWithMenu.module.css';
import SearchSortFilterMenu from '../SearchSortFilterMenu/SearchSortFilterMenu';
import { columnProptype } from '../../types';

const HeaderWithMenu = ({
  column,
  searchText,
  field,
  rows,
  selectedFilterOptions,
  applySort,
  renderSortingHeader,
  sortable,
  sortData,


  ...args
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [fieldToSearchTextMap, setFieldToSearchTextMap] = useState({});
  const [isEllipsisPopupOpen, setIsEllipsisPopupOpen] = useState(false);
  const [ellipsisAnchorEl, setEllipsisAnchorEl] = useState(null);
  const [ellipsisPopupValue, setEllipsisPopupValue] = useState(null);
  const [fieldToFiltersSetMap, setFieldToFiltersSetMap] = useState({});
  const closeEllipsisPopup = () => setIsEllipsisPopupOpen(false);
  useEffect(() => {
    console.log(`${sortData }`,"sortDatalast2");
  }, [sortData.sortState]);

  const ref = useRef(null);

  const uniqueFilterOptions = column.customFilterOptions
    ? new Set(column.customFilterOptions)
    : field === 'allPlants' || field === 'allVehicleLines'
      ? new Set(rows.map(row => row[field])[0]?.map(i => i).filter(i => i))
      : new Set(rows.map(row => row[field]).filter(i => i));
  
  const handlePopoverOpen = (event, val) => {
        if (event.currentTarget.scrollWidth > event.currentTarget.clientWidth) {
          setEllipsisPopupValue(val);
          setIsEllipsisPopupOpen(true);
          setEllipsisAnchorEl(event.currentTarget);
        }
      };
  const handlePopoverClose = () => {
        setIsEllipsisPopupOpen(false);
        setEllipsisAnchorEl(null);
      };

  return (
    <>
      <div className={styles.headerWithMenu}>
        <div
          className={styles.ellipsisText}
          style={{ textAlign: column.alignment || 'left' }}
          onMouseEnter={e => handlePopoverOpen(e, column.title)}
          onMouseLeave={() => handlePopoverClose()}
        >
          {column.title}
        </div>
        <FilterListIcon
          className={clsx(
            styles.filterIcon,
            (searchText || selectedFilterOptions?.size || isMenuOpen) &&
              styles.openFilterIcon,
          )}
          onClick={() => {setIsMenuOpen(true)
          }
          }
          ref={ref}
          aria-label={`menu-for-column-${column.title}`}
        />
      </div>

      <div style={{ background: '#fafafa' }}>
        <SearchSortFilterMenu
          options={column}
          rows={rows}
          field={field}
          open={isMenuOpen}
          anchorEl={ref.current}
          handleClose={() => setIsMenuOpen(false)}
          enableSearch={column.enableSearch}
          enableFilter={column.enableFilter}
          searchText={searchText}
          filterOptions={[...uniqueFilterOptions]}
          selectedFilterOptions={selectedFilterOptions}
          searchLabel={column.title}
          applySort={applySort}
          renderSortingHeader={renderSortingHeader}
          sortable={sortable}
          sortData={sortData}

          {...args}
        />
      </div>
      <Popover
        open={isEllipsisPopupOpen}
        anchorEl={ellipsisAnchorEl}
        onClose={closeEllipsisPopup}
        className={styles.popover}
        classes={{
          paper: styles.popoverPaper,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {ellipsisPopupValue} 
      </Popover>
    </>
  );
};

HeaderWithMenu.propTypes = {
  column: columnProptype,
  searchText: PropTypes.string,
  field: PropTypes.string,
  rows: PropTypes.array,
  selectedFilterOptions: PropTypes.objectOf(Set),
  applySort: PropTypes.func.isRequired,
  renderSortingHeader: PropTypes.func.isRequired,
  sortable:PropTypes.bool,
  sortData: PropTypes.object.isRequired,


};

export default HeaderWithMenu;
