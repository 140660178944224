/* eslint-disable no-unused-vars */
import { RESETAll,SETAllDI, DASHBOARD, MENU, SETERROR, POROW, POSEARCH, TOAST, SETCHASSISDATA, SETAll, SET_PRODUCTS, GETINVOICE } from "../actions/productsActions";

export const productsInitialState = {
  chassisList: {
    chassisArray: [],
    loaderFlag: true,
    hidePage: false
  },
  allDeatils: {
    msg:"",
    singleChassisArray: [{
      chs_rso_code: "",
      chs_veh_target_dt: null,
    }],
    defectListArray: [],
    damageListArray: [],
    transporterListArray: [],
    chassisDefectListArray: [],
    chassisePODDetailsListArray: [], 
  },

  allDeatilsDI: {
    msg:"",
    singleChassisArray: [{
      chs_rso_code: "",
      chs_veh_target_dt: null,
    }],
    hydraulicKitSupplie: [],
    hydraulicKitType:[],
    demerit: [],
    demeritType: [],
  


  },
  invoiceDeatils: {
    EXEMPTValue: "NO",
    invoiceListArray: [],
    transporterListArray: [],
    msg: ""

  },
  chassisData: {
    chassisDetails: {}

  },
  POData: {
    PODetails: [],
    PODetailsMSG: ""
    

  },
  PODataRow: {
    PODetailsRow: []

  },
  toastData: {
    msg: "",
    flag: false,
    type: ""
  },
  errorData: {
    errorMsg: "",
    errorStatus: false,
    showPage: false
  },
  // menu:{
  //   menuArray:{}

  // }
  dashboard: {

    msg: "",
    count: [{
      ack_count: 0,
      bsrn_count: 0,
      di_count: 0,
      inv_count: 0,
      mfcdt_count: 0,
      mfdt_count: 0
    }]
  }

};

const productsReducer = (state = productsInitialState, action) => {
  switch (action.type) {
    case SET_PRODUCTS:
      return {
        ...state, chassisList: {
          chassisArray: action.payload.c_ChassisSearchData,
          loaderFlag: false,
          hidePage: true
        }

      };
    case SETAllDI:
      return {
        ...state, allDeatilsDI: {
          msg:action.payload.msg,
        singleChassisArray: action.payload.c_ChassisDetails,
          hydraulicKitSupplie: action.payload.c_FillHydraulicSupp,
          hydraulicKitType: action.payload.c_FillHydraulictype,
          demerit: action.payload.c_FILL_DI_PARAMETERS,
          demeritType: action.payload.c_Fill_DI_Types,
        


        }
      };
    case SETAll:
      return {
        ...state, allDeatils: {
          msg:action.payload.msg,
          singleChassisArray: action.payload.c_ChassisDetails,
          EXEMPTValue: action.payload.c_ChassisDetails,
          defectListArray: action.payload.c_DefectList,
          damageListArray: action.payload.c_DamageList,
          transporterListArray: action.payload.c_TransporterList,
          chassisDefectListArray: action.payload.c_ChassisDefectList,
          chassisePODDetailsListArray: action.payload.c_ChassisePODDetails,


        }
      };
    case RESETAll:
      return {
        ...state, allDeatils: {
          msg:action.payload.msg,
          singleChassisArray: action.payload.c_ChassisDetails,
          EXEMPTValue: action.payload.c_ChassisDetails,
          defectListArray: action.payload.c_DefectList,
          damageListArray: action.payload.c_DamageList,
          transporterListArray: action.payload.c_TransporterList,
          chassisDefectListArray: action.payload.c_ChassisDefectList,
          chassisePODDetailsListArray: action.payload.c_ChassisePODDetails,


        }
      };
    case GETINVOICE:
      return {
        ...state, invoiceDeatils: {
          EXEMPTValue: action.payload.data.EXEMPT_Value,
          invoiceListArray: action.payload.data.Chs_Invoicelist,
          transporterListArray: action.payload.data.Transporterlist,
          msg: action.payload.msg

        }
      };
    case SETCHASSISDATA:
      return {
        ...state, chassisData: {
          chassisDetails: action.payload.chassisData
        }

      };
    case POSEARCH:
      return {
        ...state, POData: {
          PODetails: action.payload.data,
          PODetailsMSG: action.payload.msg
        }

      };
    case POROW:
      return {
        ...state, PODataRow: {
          PODetailsRow: action.payload.data
        }

      };
    case TOAST:
      return {
        ...state, toastData: {
          msg: action.payload.toast.msg,
          flag: action.payload.toast.flag,
          type: action.payload.toast.type
        }
      };
    case SETERROR:
      return {
        ...state, errorData: {
          errorMsg: action.payload.error.errorMsg,
          errorStatus: action.payload.error.errorStatus,
          showPage: action.payload.error.showPage
        }
      };
    case DASHBOARD:
      return {
        ...state, dashboard: {
          msg: action.payload.msg,
          count: action.payload.data

        }
      };


    default:
      return state;
  }
};

export default productsReducer;
