import React from 'react';
import { SvgIcon } from '@material-ui/core';

const SortDescendingIcon = props => (
    <SvgIcon viewBox="0 0 17.166 15.02" {...props}>
      <path
        d="M10.192,15.125H8.047a.536.536,0,0,0-.536.536v1.073a.536.536,0,0,0,.536.536h2.146a.536.536,0,0,0,.536-.536V15.661A.536.536,0,0,0,10.192,15.125ZM5.9,12.979H4.291V2.786a.536.536,0,0,0-.536-.536H2.682a.536.536,0,0,0-.536.536V12.979H.536a.537.537,0,0,0-.379.916L2.84,17.113a.536.536,0,0,0,.758,0l2.682-3.219A.537.537,0,0,0,5.9,12.979Zm8.583-6.437H8.047a.536.536,0,0,0-.536.536V8.151a.536.536,0,0,0,.536.536h6.437a.536.536,0,0,0,.536-.536V7.078A.536.536,0,0,0,14.484,6.542Zm-2.146,4.292H8.047a.536.536,0,0,0-.536.536v1.073a.536.536,0,0,0,.536.536h4.292a.536.536,0,0,0,.536-.536V11.37A.536.536,0,0,0,12.338,10.833ZM16.63,2.25H8.047a.536.536,0,0,0-.536.536V3.859a.536.536,0,0,0,.536.536H16.63a.536.536,0,0,0,.536-.536V2.786A.536.536,0,0,0,16.63,2.25Z"
        transform="translate(0 -2.25)"
      />
    </SvgIcon>
  );

export default SortDescendingIcon;
