import React, { forwardRef, useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import moment from "moment";
import { Popover } from '@material-ui/core';

import { columnsPropType } from "../../types";
import styles from "./TableHead.module.css";
import HeaderWithMenu from "../HeaderWithMenu/HeaderWithMenu";
import SortAscendingIcon from "../../../../assets/icons/SortAscendingIcon";
import SortDescendingIcon from "../../../../assets/icons/SortDescendingIcon";
import { SORT_STATE } from "../../../../constants";

const TableHead = forwardRef(
  (
    {
      columns,
      className,
      height,
      reorderHeaders,
      rows,
      allRows,
      fieldToSearchTextMap,
      setSearchTextForTitleCurry,
      noOfFixedColumns,
      fieldToFiltersSetMap,
      setFiltersForFieldCurry,
      setSortedRows,
      unsortedRows,
    },
    ref
  ) => {
    const [sortData, setSortData] = useState({
      sortField: null,
      sortState: SORT_STATE.UNSORTED,
   //   sortState: SORT_STATE.DESCENDING,
    });
    useEffect(() => {
      console.log(sortData,"sortData")
    }, [sortData]);
    useEffect(() => {
      setSortData({ sortField: null, sortState: SORT_STATE.UNSORTED });
    }, []);
    const [isEllipsisPopupOpen, setIsEllipsisPopupOpen] = useState(false);
    const [ellipsisAnchorEl, setEllipsisAnchorEl] = useState(null);
    const [ellipsisPopupValue, setEllipsisPopupValue] = useState(null);
    const closeEllipsisPopup = () => setIsEllipsisPopupOpen(false);
  
    const handlePopoverOpen = (event, val) => {
      if (event.currentTarget.scrollWidth > event.currentTarget.clientWidth) {
      
        setEllipsisPopupValue(val);
        setIsEllipsisPopupOpen(true);
        setEllipsisAnchorEl(event.currentTarget);
      }
    };
    const handlePopoverClose = () => {
      setIsEllipsisPopupOpen(false);
      setEllipsisAnchorEl(null);
    };
    // eslint-disable-next-line no-shadow
    const getNextSortState = (field, sortData) => {
      const sortStates = [
        SORT_STATE.ASCENDING,
        SORT_STATE.DESCENDING,
        SORT_STATE.UNSORTED,
      ];
      if (field === sortData.sortField) {
        const currentSortState = sortData.sortState;
        const idx = sortStates.indexOf(currentSortState);
        const nextSortStateIdx = (idx + 1) % sortStates.length;
        return sortStates[nextSortStateIdx];
      }
      return sortStates[0];
    };
    function reverseFormatNumber(val, locale) {
      const group = new Intl.NumberFormat(locale)
        .format(1111)
        .replace(/1/g, "");
      const decimal = new Intl.NumberFormat(locale)
        .format(1.1)
        .replace(/1/g, "");
      let reversedVal = val
        .toString()
        .replace(new RegExp(`\\${group}`, "g"), "");
      reversedVal = reversedVal.replace(new RegExp(`\\${decimal}`, "g"), ".");
      return Number.isNaN(reversedVal) ? 0 : reversedVal;
    }
    function replaceDashesWithZeros(row, field) {
      const parsedVals = row.map((item) =>
        typeof item[field] === "string"
          ? {
            ...item,
            [field]: parseFloat(reverseFormatNumber(item[field], "INR")),
          }
          : { ...item }
      );
      return parsedVals.map((item) =>
        Number.isNaN(item[field]) ? { ...item, [field]: 0 } : { ...item }
      );
    }
    function substitueZerosWithPrevValues(formatedRows, field) {
      const updatedRows = [];
      formatedRows.forEach((item) => {
        if (item[field] === 0) {
          unsortedRows.forEach((ele) => {
            if (ele.id === item.id) {
              updatedRows.push(ele);
            }
          });
        } else {
          updatedRows.push(item);
        }
      });
      return updatedRows;
    }
    const numberFormat = (value) => {
      if (value === undefined) return 0;

      return new Intl.NumberFormat("en-IN", {
        // style: 'currency',
        currency: "INR",
      }).format(value);
    };
    function checkFormat(feild) {
      if (rows.filter((item) =>moment(item?.[feild], "YYYY-MM-DD", true).isValid()).length>0 ||
      rows.filter((item) =>moment(item?.[feild], "YYYY-DD", true).isValid()).length>0 ||
      rows.filter((item) =>moment(item?.[feild], "YYYY-MM", true).isValid()).length>0 
      ) {
        return "date";
      }
      if(rows.map(item=>parseFloat(reverseFormatNumber(item?.[feild],"INR"))).filter(item=>!Number.isNaN(item)).length>0){
        return "num"
      }
      return "string";
    
    }
    const applySort = (field) => {
      console.log(field,"field")
      const nextSortState = getNextSortState(field, sortData);
      switch (nextSortState) {
        case SORT_STATE.ASCENDING:
          if (
            checkFormat(field) === "string" ||
            checkFormat(field) === "date" 
          ) {
            // console.log("str-ASCENDING");
            const ascSortedRows = [...rows];
            ascSortedRows.sort((a, b) =>
              a?.[field].toLowerCase() > b[field]?.toLowerCase() ? 1 : -1
            );
            if (ascSortedRows.length > 0) setSortedRows(ascSortedRows);
          } else if (checkFormat(field) === "num") {
            // console.log("num-ASCENDING");
            const formatedRows = replaceDashesWithZeros(rows, field);
            formatedRows.sort((a, b) => a?.[field] - b?.[field]);
            const updatedRows = substitueZerosWithPrevValues(
              formatedRows,
              field
            );
            const numFormatConverted=updatedRows.map(item=>typeof item?.[field]==='string' || field==='invoiceNumber' ?{...item}:{...item,[field]:numberFormat(item?.[field])})
            if (numFormatConverted.length > 0) setSortedRows(numFormatConverted);
          }
          break;
        case SORT_STATE.DESCENDING:
          if (
            checkFormat(field) === "string" ||
            checkFormat(field) === "date" 
          ) {
            // console.log("str-DESCENDING");
            const decSortedRows = [...rows];
            decSortedRows.sort((a, b) =>
              a[field]?.toLowerCase() > b[field]?.toLowerCase() ? 1 : -1
            );
            decSortedRows.reverse();
            if (decSortedRows.length > 0) setSortedRows(decSortedRows);
          } else if (checkFormat(field) === "num") {
            // console.log("num-DESCENDING");
            const formatedRows = replaceDashesWithZeros(rows, field);
            formatedRows.sort((a, b) => b?.[field] - a?.[field]);
            const updatedRows = substitueZerosWithPrevValues(
              formatedRows,
              field
            );
            const numFormatConverted=updatedRows.map(item=>typeof item?.[field]==='string' || field==='invoiceNumber'?{...item}:{...item,[field]:numberFormat(item?.[field])})
            if (numFormatConverted.length > 0) setSortedRows(numFormatConverted);
          }
          break;
        case SORT_STATE.UNSORTED:
          if (unsortedRows.length > 0) setSortedRows(unsortedRows);
          break;
        default:
          // do nothing
          break;
      }
      setSortData({ sortState: nextSortState, sortField: field });
    };
    const renderSortingHeader = (col) => {
      console.log(col,"col")
      let sortIcon = <SortAscendingIcon className={styles.sortIcon} />;
      if (sortData.sortField === col.field) {
        if (sortData.sortState === SORT_STATE.ASCENDING) {
          sortIcon = (
            <SortAscendingIcon
              className={clsx(styles.sortIcon, styles.openSortIcon)}
            />
          );
        } else if (sortData.sortState === SORT_STATE.DESCENDING) {
          sortIcon = (
            <SortDescendingIcon
              className={clsx(styles.sortIcon, styles.openSortIcon)}
            />
          );
        }
      }
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <span
          className={styles.headerWithMenu}
          onClick={() => applySort(col.field)}
        >
          <span
            className={styles.ellipsisText}
            style={{ textAlign: col.alignment || "left" }}
            onMouseEnter={e => handlePopoverOpen(e, col.title)}
            onMouseLeave={() => handlePopoverClose()}
          >
            {col.title}
          </span>
          {sortIcon}
        </span>
      );
    };
    const renderHeader = (col) => {
      if (col.renderHeader) return col.renderHeader(rows);
      if (col.enableSearch || col.enableFilter)
        return (
          <HeaderWithMenu
            column={col}
            searchText={fieldToSearchTextMap[col.field]}
            handleSearchTextChange={setSearchTextForTitleCurry(col.field)}
            selectedFilterOptions={fieldToFiltersSetMap[col.field]}
            applyFilters={setFiltersForFieldCurry(col.field)}
            rows={allRows}
            field={col.field}
            applySort={applySort}
            renderSortingHeader={renderSortingHeader}
            sortable={false}
            sortData={sortData}

          />
        );

        if (col.enableSearch || col.enableFilter && col.sortable)
        return (
          <>
          <HeaderWithMenu
          column={col}
          searchText={fieldToSearchTextMap[col.field]}
          handleSearchTextChange={setSearchTextForTitleCurry(col.field)}
          selectedFilterOptions={fieldToFiltersSetMap[col.field]}
          applyFilters={setFiltersForFieldCurry(col.field)}
          rows={allRows}
          field={col.field}
          sortable={false}
          sortData={sortData}

        />
        </>
        );

      if (col.sortable) return renderSortingHeader(col);
      return (
        <span className="pratik" style={{ paddingBottom: 2, display: "inline-block" }}
      
        >
          {col.title}
        </span>
      );
    };
    return (
     <> <DragDropContext onDragEnd={reorderHeaders}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided) => (
            <div
              className={clsx(styles.tableHeader, styles.scrollBar, className)}
              ref={(refVal) => {
                provided.innerRef(refVal);
                // eslint-disable-next-line no-param-reassign
                if (ref != null) ref.current = refVal;
              }}
              {...provided.droppableProps}
              style={{
                height,
                ...provided.droppableProps.style,
              }}
            >
              {columns.map((col, index) => (
                <Draggable
                  key={col.field}
                  draggableId={col.field}
                  index={index}
                  isDragDisabled={index < noOfFixedColumns}
                >
                  {(given, snapshot) => (
                    <div
                      ref={given.innerRef}
                      {...given.draggableProps}
                      {...given.dragHandleProps}
                      style={{
                        textAlign: col.alignment || "left",
                        ...col.style,
                        ...col.headerStyles,
                        ...given.draggableProps.style,
                        display: "inline-block",
                        width: col.width,
                        backgroundColor: snapshot.isDragging
                          ? "#eff7ff"
                          : "#fff",
                      }}
                    >
                      {renderHeader(col)}
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
            <Popover
            open={isEllipsisPopupOpen}
            anchorEl={ellipsisAnchorEl}
            onClose={closeEllipsisPopup}
            className={styles.popover}
            classes={{
              paper: styles.popoverPaper,
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {ellipsisPopupValue}
          </Popover>
          </>
    );
    
  }
  
);

TableHead.propTypes = {
  columns: columnsPropType,
  className: PropTypes.string,
  height: PropTypes.number,
  reorderHeaders: PropTypes.func.isRequired,
  rows: PropTypes.array,
  allRows: PropTypes.array,
  fieldToSearchTextMap: PropTypes.object.isRequired,
  setSearchTextForTitleCurry: PropTypes.func.isRequired,
  noOfFixedColumns: PropTypes.number,
  fieldToFiltersSetMap: PropTypes.object.isRequired,
  setFiltersForFieldCurry: PropTypes.func.isRequired,
  setSortedRows: PropTypes.func.isRequired,
  unsortedRows: PropTypes.array,


  
};

export default TableHead;
