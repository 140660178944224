/* eslint-disable react/self-closing-comp */
/* eslint-disable no-plusplus */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable import/named */
/* eslint-disable prefer-template */
/* eslint-disable one-var */
/* eslint-disable radix */
/* eslint-disable no-else-return */
/* eslint-disable no-shadow */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-var */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable object-shorthand */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable prefer-const */
/* eslint-disable dot-notation */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useReducer } from 'react'
import { connect, useDispatch } from 'react-redux'
import FileBase64 from 'react-file-base64';

import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import PropTypes from 'prop-types';
import clsx from 'clsx'
import moment from 'moment';

import { Modal, Dialog, DialogTitle, DialogContent, DialogActions, Form, Box, TextField, Grid, FormGroup, IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import { SearchOutlined, Update } from '@material-ui/icons';
import DeleteIcon from '@mui/icons-material/Delete';

import { MdReplay, MdSystemUpdate } from 'react-icons/md';
import styles from './Audit.module.css'
import { CustomDatePicker, CustomFormGroup, CustomSelect, ValidatingTextField } from '../FormComponents'
import { DATE_FORMAT, API_RESOURCE_URLS, DISPLAY_MESSAGES, MESSAGE_TYPE, RESOURCE_TYPE, USER_OPERATIONS, Regex } from '../../constants'

import { usePopupManager } from '../../providers/PopupManager/PopupManager';
import { AuthChecker, Button } from '../../atomicComponents';
import { withAllowedOperationsProvider } from '../../hocs'
import { setTableRowData, setToast, setPORow, poSearch, fetchChassis, setChassisData, setAllDetails, getInvoice } from '../../redux/actions/productsActions';




const initialState = {
  plant: null,
  part: null,
  aqCommodity: null,
  purchaseCommodity: null,
  supplierCode: null,
  supplierDetails: null,
  supplierName: '',
  supplierLocation: '',
  selectedProject: null,
  selectedPO: null,
  inputFields: {
    purchaseBuyerName: '',
    suppRepresentativeName: '',
    suppRepresentativeContact: '',
    suppRepresentativeEmail: ''
  },
  errors: {},
}
const reducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return {
        ...state,
        [action.field]: action.value
      }
    case 'reset':
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}


function Audit({ tableData, product, counter, baseUrl, auth }) {
  const ROW_HEIGHT = 38;


  const { showInternalError } = usePopupManager();
  const { showPopup } = usePopupManager();
  const dispatch1 = useDispatch();
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);


  const { damage, invoice, plant, part, aqCommodity, purchaseCommodity, supplierCode, supplierDetails, supplierName,
    supplierLocation, selectedProject, selectedPO, inputFields, errors } = state;


  const [asnCount, setAsnCount] = useState(0);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [confirmModalFlag2, setConfirmModal2] = useState(false);
  const [resetAllVisitedFields, setResetAllVisitedFields] = useState(false);
  const [highlightMandatoryFields, setHighlightMandatoryFields] = useState(false);


  const [RowsColumn, setRowsColumn] = useState([]);
  const [handoverDate, setHandoverDate] = useState(null);
  const [myState, setState] = React.useState(
    {

      year: null,
      quarter: null,
      qualifiedVC: [],
      VC: [],
      vendor: [],
      vendorValidation: [],
      tmlDataValidation: [],
      DataToMHI: [],




    });








  const handleSelect = (selectedOption, name) => {
    if (selectedOption == null) {
      setState((prevData) => ({
        ...prevData,
        [name]: null
      }));
    }

    // eslint-disable-next-line eqeqeq 
    else {

      setState((prevData) => ({
        ...prevData,
        [name]: selectedOption,
        QuartersError: false,
        QuartersErrorMsg: "",
      }));
    }



  };










  const validateFYear = () => {
    let fYear = myState.year;
    if (fYear == "" || fYear == null) {
      setState((prevData) => ({ ...prevData, errorFlagFyear: true, errorFlagFyearMsg: "FY is mandatory field" }))
      return false
    }

    else {
      setState((prevData) => ({ ...prevData, errorFlagFyear: false, errorFlagFyearMsg: "FY is mandatory field" }))

      return true

    }

  }

  const validateQurter = () => {
    let quarters = myState.quarter;
    if (quarters == "" || quarters == null) {
      setState((prevData) => ({ ...prevData, QuartersError: true, QuartersErrorMsg: "Quarter is mandatory field" }))
      return false
    }
    else {
      setState((prevData) => ({ ...prevData, QuartersError: false, QuartersErrorMsg: "Quarter is mandatory field" }))

      return true

    }

  }

  const handleSubmit = (flag) => {
    var diList = [];
    RowsColumn.map((element, idx, array) => {
      let mileStone = element.mileStone;
      let startDates = element.startDate;
      let endDates = element.endDate;
      let obj = {
        mileStone,
        "startDate": startDates,
        "endDate": endDates,
      }
      diList.push(obj)
    });

    let flag1 = validateFYear();
    let flag2 = validateQurter();
    if (flag1 == true && flag2 == true)
    // eslint-disable-next-line no-empty
    {

    } else {
      return false;
    }
    let fY = myState.year;
    let Fqurter = myState.quarter;
    let cutOfDate = handoverDate;

    var apiFormatedData = {

  

    }
    var apiFormatedDataJson = JSON.stringify(apiFormatedData);
    console.log("final", apiFormatedDataJson);
    let headerss = new Headers();
    headerss.append('Content-Type', 'application/json');
    // Milestone_calendar_PV_View
    fetch(`${baseUrl}Milestone_calendarView/`,
      {
        method: 'POST',
        headers: headerss,
        body: apiFormatedDataJson
      }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson) {

          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: responseJson.status,
          });

        } else {

          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: "data save operation failed",

          });

        }
      })
      .catch((error) => {
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Internal server error`,

        });
      });

  };






  const onHover = (name) => {
    setHover({ isHover: true, name: name });
  };

  const onLeave = () => {
    setHover({ isHover: false, name: null });
  };

  const BG_COLOR = '#fffef8';
  const [backgroundColors, setBackgroundColors] = useState({
    processInfoBg: true,
    supplierInfoBg: false,
    poInfoBg: false,
    projectsInfoBg: false
  });
  const { processInfoBg, supplierInfoBg, poInfoBg, projectsInfoBg } = backgroundColors;

  const changeBackgroundColor = (field) =>
    setBackgroundColors(prev => {
      const newBackgroundColors = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(prev)) {
        if (key === field)
          newBackgroundColors[key] = true;
        else
          newBackgroundColors[key] = false;
      }
      return newBackgroundColors
    })



  const handleOpen2 = () => {
    setConfirmModal2(true);


  };
  const handleClose2 = () => setConfirmModal2(false);
  const resetFields = () => setResetAllVisitedFields(true);








  const handleValidation = (event, field) => {
    let err = errors;
    const { value } = event.target;

    switch (field) {
      case 'purchaseBuyerName':
        err['purchaseBuyerName'] = value ? RegExp(Regex.name).test(value) ? '' : 'Name is invalid' : 'Name is required';
        break;

      case 'suppRepresentativeName':
        err['suppRepresentativeName'] = value ? RegExp(Regex.name).test(value) ? '' : 'Name is invalid' : 'Name is required';
        break;

      case 'suppRepresentativeEmail':
        err['suppRepresentativeEmail'] = value ? RegExp(Regex.email).test(value) ? '' : 'Email is invalid' : 'Email is required';
        break;

      case 'suppRepresentativeContact':
        err['suppRepresentativeContact'] = value ? RegExp(Regex.phone).test(value) ? '' : 'Contact is invalid' : 'Contact is required';
        break;

      default:
        break;
    }
    dispatch({ type: 'update', field: 'errors', value: err });
  }
  const handleClose = () => {
    setPpenModal(false)

  }

  const FY = [
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
    { value: "2025", label: "2025" },
    { value: "2026", label: "2026" },
    { value: "2027", label: "2027" },
    { value: "2028", label: "2028" },
    { value: "2029", label: "2029" },
    { value: "2030", label: "2030" },
    { value: "2031", label: "2031" },

  ]
  const Quarter = [
    { value: "Q1", label: "Q1" },
    { value: "Q2", label: "Q2" },
    { value: "Q3", label: "Q3" },
    { value: "Q4", label: "Q4" },
  ]







  const renderMilestone = () => (
    <div
      className={styles.formGroup}

    >
      <Grid
        container
        spacing={1}

        style={{ display: 'flex', justifyContent: "left", marginTop: "5px", marginBottom: "1px" }}
        columns={12}
      >
        <Grid item md={4} lg={4}>
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)}>
              <label>Qualified VC </label>
            </div>
            <div className={styles.formDiv}>

              <CustomSelect
                isClearable
                isMandatory
                markIfUnselected={highlightMandatoryFields}
                resetAllVisitedFields={resetAllVisitedFields}
                className={clsx(styles.select, styles.sel2)}
                isMulti={false}

                options={myState.qualifiedVC}
                onChange={(e) => {
                  handleSelect(e, 'year')
                  setState((prevData) => ({ ...prevData, errorFlagFyear: false, errorFlagFyearMsg: "FY is mandatory field" }))
                  setHighlightMandatoryFields(false);

                }}
                name="year"
              />
            </div>
          </div>

        </Grid>

        <Grid item md={1} lg={1}>
          <div className={styles.rowDiv} style={{ display: "flex" }}>
            <Button onClick={(e) => getSearchData()}
              size="small" variant="primary" component="label"
              disabled={(myState.year == null || myState.quarter == null)}
              className={clsx(
                styles.actionButton,
                styles.primaryActionButton,
              )}
            >
              Get List

            </Button>
          </div>

        </Grid>

      </Grid>
      <Grid
        container
        spacing={1}

        style={{ display: 'flex', justifyContent: "left", marginTop: "5px", marginBottom: "1px" }}
        columns={12}
      >
        <Grid item md={4} lg={4}>
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)}>
              <label>VC</label>
            </div>
            <div className={styles.formDiv}>

              <CustomSelect
                isClearable
                isMandatory
                markIfUnselected={highlightMandatoryFields}
                resetAllVisitedFields={resetAllVisitedFields}
                className={clsx(styles.select, styles.sel2)}
                isMulti
                options={myState.VC}
                onChange={(e) => {
                  handleSelect(e, 'year')
                  setState((prevData) => ({ ...prevData, errorFlagFyear: false, errorFlagFyearMsg: "FY is mandatory field" }))
                  setHighlightMandatoryFields(false);

                }}
                name="year"
              />
            </div>
          </div>

        </Grid>


        <Grid item md={2} lg={2}>
          <div className={styles.rowDiv} style={{ display: "flex" }}>
            <Button onClick={(e) => getSearchData()}
              size="small" variant="primary" component="label"
              disabled={(myState.year == null || myState.quarter == null)}
              className={clsx(
                styles.actionButton,
                styles.primaryActionButton,
              )}
              style={{ marginRight: "10px" }}
            >
              Search

            </Button>
            <Button onClick={(e) => getSearchData()}
              size="small" variant="primary" component="label"
              disabled={(myState.year == null || myState.quarter == null)}
              className={clsx(
                styles.actionButton,
                styles.primaryActionButton,
              )}
            >
              Get BOM

            </Button>
          </div>

        </Grid>


      </Grid>
      <Grid
        container
        spacing={1}

        style={{ display: 'flex', justifyContent: "left", marginTop: "5px", marginBottom: "1px" }}
        columns={12}
      >
        <Grid item md={4} lg={4}>
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)}>
              <label>Vendor </label>
            </div>
            <div className={styles.formDiv}>

              <CustomSelect
                isClearable
                isMandatory
                markIfUnselected={highlightMandatoryFields}
                resetAllVisitedFields={resetAllVisitedFields}
                className={clsx(styles.select, styles.sel2)}
                isMulti
                options={myState.vendor}
                onChange={(e) => {
                  handleSelect(e, 'year')
                  setState((prevData) => ({ ...prevData, errorFlagFyear: false, errorFlagFyearMsg: "FY is mandatory field" }))
                  setHighlightMandatoryFields(false);

                }}
                name="year"
              />
            </div>
          </div>

        </Grid>


        <Grid item md={5} lg={5}>
          <div className={styles.rowDiv} style={{ display: "flex" }}>

            <Button onClick={(e) => getSearchData()}
              size="small" variant="primary" component="label"
              disabled={(myState.year == null || myState.quarter == null)}
              className={clsx(
                styles.actionButton,
                styles.primaryActionButton,
              )}
              style={{ marginRight: "10px" }}

            >
              Get DVA Data

            </Button>
            <Button onClick={(e) => getSearchData()}
              size="small" variant="primary" component="label"
              disabled={(myState.year == null || myState.quarter == null)}
              className={clsx(
                styles.actionButton,
                styles.primaryActionButton,
              )}
              style={{ marginRight: "10px" }}

            >
              Get Certificates

            </Button>
            <Button onClick={(e) => getSearchData()}
              size="small" variant="primary" component="label"
              disabled={(myState.year == null || myState.quarter == null)}
              className={clsx(
                styles.actionButton,
                styles.primaryActionButton,
              )}


            >
              Get Annexures

            </Button>
          </div>

        </Grid>


      </Grid>

      <Grid
        container
        spacing={1}

        style={{ display: 'flex', justifyContent: "left", marginTop: "5px", marginBottom: "1px" }}
        columns={12}
      >
        <Grid item md={4} lg={4}>
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)}>
              <label>Vendor Validation </label>
            </div>
            <div className={styles.formDiv}>

              <CustomSelect
                isClearable
                isMandatory
                markIfUnselected={highlightMandatoryFields}
                resetAllVisitedFields={resetAllVisitedFields}
                className={clsx(styles.select, styles.sel2)}
                isMulti
                options={myState.vendorValidation}
                onChange={(e) => {
                  handleSelect(e, 'year')
                  setState((prevData) => ({ ...prevData, errorFlagFyear: false, errorFlagFyearMsg: "FY is mandatory field" }))
                  setHighlightMandatoryFields(false);

                }}
                name="year"
              />
            </div>
          </div>

        </Grid>


        <Grid item md={5} lg={5}>
          <div className={styles.rowDiv} style={{ display: "flex" }}>

            <Button onClick={(e) => getSearchData()}
              size="small" variant="primary" component="label"
              disabled={(myState.year == null || myState.quarter == null)}
              className={clsx(
                styles.actionButton,
                styles.primaryActionButton,
              )}
              style={{ marginRight: "10px" }}

            >
              All Data

            </Button>
            <Button onClick={(e) => getSearchData()}
              size="small" variant="primary" component="label"
              disabled={(myState.year == null || myState.quarter == null)}
              className={clsx(
                styles.actionButton,
                styles.primaryActionButton,
              )}
              style={{ marginRight: "10px" }}

            >
              All Certificates

            </Button>
            <Button onClick={(e) => getSearchData()}
              size="small" variant="primary" component="label"
              disabled={(myState.year == null || myState.quarter == null)}
              className={clsx(
                styles.actionButton,
                styles.primaryActionButton,
              )}


            >
              All Annexures

            </Button>
          </div>

        </Grid>


      </Grid>
      <Grid
        container
        spacing={1}

        style={{ display: 'flex', justifyContent: "left", marginTop: "5px", marginBottom: "1px" }}
        columns={12}
      >
        <Grid item md={4} lg={4}>
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)}>
              <label>TML Data Validation </label>
            </div>
            <div className={styles.formDiv}>

              {/* <CustomSelect
                isClearable
                isMandatory
                markIfUnselected={highlightMandatoryFields}
                resetAllVisitedFields={resetAllVisitedFields}
                className={clsx(styles.select, styles.sel2)}
                isMulti
                options={myState.tmlDataValidation}
                onChange={(e) => {
                  handleSelect(e, 'year')
                  setState((prevData) => ({ ...prevData, errorFlagFyear: false, errorFlagFyearMsg: "FY is mandatory field" }))
                  setHighlightMandatoryFields(false);

                }}
                name="year"
              /> */}
                     <Button onClick={(e) => getSearchData()}
              size="small" variant="primary" component="label"
              disabled={(myState.year == null || myState.quarter == null)}
              className={clsx(
                styles.actionButton,
                styles.primaryActionButton,
              )}
              style={{ marginRight: "10px" }}
            >
              Get list L1 & L2

            </Button>
            </div>
          </div>

        </Grid>


        {/* <Grid item md={5} lg={5}>
          <div className={styles.rowDiv} style={{ display: "flex" }}>
            <Button onClick={(e) => getSearchData()}
              size="small" variant="primary" component="label"
              disabled={(myState.year == null || myState.quarter == null)}
              className={clsx(
                styles.actionButton,
                styles.primaryActionButton,
              )}
              style={{ marginRight: "10px" }}
            >
              Get list L1 & L2

            </Button>

          </div>

        </Grid> */}


      </Grid>
      <Grid
        container
        spacing={1}

        style={{ display: 'flex', justifyContent: "left", marginTop: "5px", marginBottom: "1px" }}
        columns={12}
      >
        <Grid item md={4} lg={4}>
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)}>
              <label>Data Submit to MHI</label>
            </div>
            <div className={styles.formDiv}>

              {/* <CustomSelect
                isClearable
                isMandatory
                markIfUnselected={highlightMandatoryFields}
                resetAllVisitedFields={resetAllVisitedFields}
                className={clsx(styles.select, styles.sel2)}
                isMulti
                options={myState.DataToMHI}
                onChange={(e) => {
                  handleSelect(e, 'year')
                  setState((prevData) => ({ ...prevData, errorFlagFyear: false, errorFlagFyearMsg: "FY is mandatory field" }))
                  setHighlightMandatoryFields(false);

                }}
                name="year"
              /> */}

<Button onClick={(e) => getSearchData()}
              size="small" variant="primary" component="label"
              disabled={(myState.year == null || myState.quarter == null)}
              className={clsx(
                styles.actionButton,
                styles.primaryActionButton,
              )}
              style={{ marginRight: "10px" }}

            >
              Get List

            </Button>
            </div>
          </div>

        </Grid>

{/* 
        <Grid item md={5} lg={5}>
          <div className={styles.rowDiv} style={{ display: "flex" }}>

            <Button onClick={(e) => getSearchData()}
              size="small" variant="primary" component="label"
              disabled={(myState.year == null || myState.quarter == null)}
              className={clsx(
                styles.actionButton,
                styles.primaryActionButton,
              )}
              style={{ marginRight: "10px" }}

            >
              Get List

            </Button>

          </div>

        </Grid> */}


      </Grid>


    </div>)


  const renderOther = () => (<>
    <div
      className={styles.formGroup}
      style={{ backgroundColor: supplierInfoBg ? BG_COLOR : null }}
      onClick={() => changeBackgroundColor('supplierInfoBg')}
    >


      <Grid
        container
        spacing={1}

        style={{ display: 'flex', justifyContent: "left", marginTop: "5px", marginBottom: "1px" }}
        columns={12}
      >
        <Grid item md={4} lg={4}>
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)}>
              <label>FY</label>
            </div>
            <div className={styles.formDiv}>

              <CustomSelect
                isClearable
                isMandatory
                markIfUnselected={highlightMandatoryFields}
                resetAllVisitedFields={resetAllVisitedFields}
                className={clsx(styles.select, styles.sel2)}
                isMulti={false}
                options={FY}
                value={myState.year}
                onChange={(e) => {
                  handleSelect(e, 'year')
                  setState((prevData) => ({ ...prevData, errorFlagFyear: false, errorFlagFyearMsg: "FY is mandatory field" }))
                  setHighlightMandatoryFields(false);

                }}
                name="year"
              />
            </div>
          </div>
          {myState.errorFlagFyear &&
            <div className={styles.error}>{myState.errorFlagFyearMsg}</div>
          }
        </Grid>
        <Grid item md={4} lg={4}>
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)}>
              <label> Quarter</label>
            </div>
            <div className={styles.formDiv}>

              <CustomSelect
                isClearable
                isMandatory
                markIfUnselected={highlightMandatoryFields}
                resetAllVisitedFields={resetAllVisitedFields}
                className={clsx(styles.select, styles.sel2)}
                isMulti={false}
                options={Quarter}
                value={myState.quarter}
                onChange={(e) => {
                  handleSelect(e, 'quarter')
                  setState((prevData) => ({ ...prevData, QuartersError: false, QuartersErrorMsg: "Quarter is mandatory field" }))
                }}
                name="quarter"
              />
            </div>
          </div>
          {myState.QuartersError &&
            <div className={styles.error}>{myState.QuartersErrorMsg}</div>
          }
        </Grid>
        {/* <Grid item md={3} lg={3}>
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)}>
              <label>Cut Of Date</label>
            </div>
            <div className={styles.formDiv}>
              <CustomDatePicker
                //  isMandatory
                disabled
                //  markIfUnselected={highlightMandatoryFields}
                autoOk
                inputVariant="outlined"
                value={handoverDate}
                onChange={(e) => { handleDate(e, 'handoverDate') }}
                inputProps={{
                  className: clsx(styles.select, styles.dateInput),

                }}
                emptyLabel="yyyy-mm-dd"
                // defaultDate={moment().format(DATE_FORMAT.DD_MM_YYYY)}
                // minDate={!isEditable? null: getMinimumDateForPicker(index, milestones)}
                format="YYYY-MM-DD"

                data-testid="date-picker-div-1"
                style={{ width: "100%" }}
              // maxDate={moment().toDate()} 

              />
            </div>
          </div>
       
        </Grid> */}
        <Grid item md={1} lg={1}>
          <div className={styles.rowDiv} style={{ justifyContent: "center" }}>
            <Button onClick={(e) => getSearchData()}
              size="small" variant="primary" component="label"
              disabled={(myState.year == null || myState.quarter == null)}
              className={clsx(
                styles.actionButton,
                styles.primaryActionButton,
              )}
            >
              Search

            </Button>
          </div>

        </Grid>

      </Grid>


    </div>

  </>
  )





  const getSelectedFields = () => ({
    projectId: selectedProject,
    poNumber: selectedPO,
    commodityGroupId: purchaseCommodity && purchaseCommodity.value,
    aqCommodityGroupId: aqCommodity && aqCommodity.value,
    purchaseBuyerName: inputFields?.purchaseBuyerName,
    kamContactDetails: {
      // id: inputFields,
      name: inputFields?.suppRepresentativeName,
      contactNumber: inputFields?.suppRepresentativeContact,
      emailId: inputFields?.suppRepresentativeEmail
    }
  });

  const validateFields = (fields) => {
    const requiredFields = { ...fields, ...fields.kamContactDetails }
    const hasMissingRequiredFields = Object.values(requiredFields).some(
      (field) => !field
    );

    const hasError = Object.values(errors).some((err) => err);

    if (hasMissingRequiredFields || hasError) {
      return false;
    }
    return true;
  };

  const runPreSubmissionChecks = () => {
    const selectedFields = getSelectedFields();
    const areFieldsValid = validateFields(selectedFields);

    if (!areFieldsValid) {
      setHighlightMandatoryFields(true);
      // return false;
      return true;
    }
    return true;
  };







  const resetAllInputs = () => {
    const inputs = {
      purchaseBuyerName: '',
      suppRepresentativeName: '',
      suppRepresentativeContact: '',
      suppRepresentativeEmail: ''
    }
    dispatch({ type: 'update', field: 'inputFields', value: inputs });
    dispatch({ type: 'update', field: 'errors', value: {} });
  }

  const resetState = () => {
    dispatch({ type: 'reset' });
    resetAllInputs();
    resetTableData();
    setHighlightMandatoryFields(null);
    setResetAllVisitedFields(false);
    changeBackgroundColor('processInfoBg');
  }



  useEffect(() => {
    if (resetAllVisitedFields)
      resetState();
    console.log('render2');

  }, [resetAllVisitedFields])




  useEffect(() => {

    getTableData();
    getDropDown()

  }, [])

  
  const exportExcel = async (excelData,fileName) => {
    var fileTypel = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    var fileExt = ".xlsx"
    const ws = XLSX.utils.json_to_sheet(excelData)
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileTypel });
    FileSaver.saveAs(data, `${fileName}${fileExt}`);
  }
 
const DataSubmitMHI=()=>{

  
}

  const getDropDown = async () => {

    let headerss = new Headers();
    headerss.append('Content-Type', 'application/json');
    const urls = [
      { urlText: "Add_Vc_for_PLI", urlMethod: "GET", responseFor: "qualifiedVC" },
      { urlText: "Show_Base_Vc_Data", urlMethod: "GET", responseFor: "VC" },
      { urlText: "Venodr_wise_Data_By_Vendor_code", urlMethod: "GET", responseFor: "vendor" },
      { urlText: "ShowMHIData", urlMethod: "GET", responseFor: "DataToMHI" },

    ]
    try {
      await Promise.all(
        urls.map(({ urlText, urlMethod, responseFor }) => {
          return fetch(`${baseUrl}${urlText}/`,
            {
              method: urlMethod,
              headers: headerss,
            }).then(res => res.json())
        })
      ).then((values) => {
        let [qualifiedVC, VCList, vendor,DataToMHI] = values;
        const loadVC = VCList.map(({ VC }) => ({ value: VC, label: VC }));
        const loadqualifiedVC = qualifiedVC.map(({ colour_vc }) => ({ value: colour_vc, label: colour_vc }));
        const loadVendor = vendor.map(({ Vendor_Code }) => ({ value: Vendor_Code, label: Vendor_Code }));

        setState((prevData) => ({
          ...prevData,
          qualifiedVC: loadqualifiedVC,
          VC: loadVC,
          vendor: loadVendor,
          vendorValidation: loadVendor,
          tmlDataValidation:[],
          DataToMHI

        }));

        console.log(VCList);
      });


    } catch (error) {

      alert(error)
    }



  };


  const getTableData = () => {

    let headerss = new Headers();
    headerss.append('Content-Type', 'application/json');
    // Milestone_calendar_PV_View
    fetch(`${baseUrl}Milestone_calendarView/`,
      {
        method: 'GET',
        headers: headerss,
        //   body: apiFormatedDataJson
      }).then((response) => response.json())
      .then((responseJson) => {
        const tableData = responseJson.data;
        var milesStoneResponse = responseJson[0]

        let id = milesStoneResponse.id;
        let year = milesStoneResponse.FY;
        let QuarterDB = milesStoneResponse.Quarter;
        let cutOffDate = milesStoneResponse.cut_off_Date;
        let LastUpdated = milesStoneResponse.Last_updated;
        const statusFY = FY.filter(o => o.value == year);
        const quarterStatus = Quarter.filter(o => o.value == QuarterDB);
        setHandoverDate(cutOffDate)
        setState((prevData) => ({ ...prevData, year: statusFY[0], quarter: quarterStatus[0], }));
        delete milesStoneResponse.id;
        delete milesStoneResponse.FY;
        delete milesStoneResponse.cut_off_Date;
        delete milesStoneResponse.Quarter;
        delete milesStoneResponse.Last_updated;


        var milestoneArray = [];
        var startDateArray = [];
        var endDateArray = [];
        for (var prop in milesStoneResponse) {
          let text = prop;
          const milesStoneFinal = text.split("_StartDate")[0];
          let StartDate = text.match(/StartDate/gi);
          let EndDate = text.match(/EndDate/gi);
          const mileStoneEnd = text.split("_EndDate")[0];
          if (StartDate != null) {
            let obj = {
              "startDate": milesStoneResponse[prop]
            }
            startDateArray.push(obj)
            milestoneArray.push(milesStoneFinal)
          }

          if (EndDate != null) {
            let obj = {
              "endDate": milesStoneResponse[prop]
            }
            endDateArray.push(obj)
            milestoneArray.push(mileStoneEnd)
          }

        }

        function removeDuplicates(arr) {
          return arr.filter((item,
            index) => arr.indexOf(item) === index);
        }

        let mileStoneFinal = removeDuplicates(milestoneArray).map((iteam) => ({ mileStone: iteam }));

        var merged = [];

        for (let i = 0; i < mileStoneFinal.length; i++) {
          merged.push({
            ...startDateArray[i],
            ...endDateArray[i],
            ...mileStoneFinal[i]
          });
        }
        let RowsColumnn = merged.map((item, id) => Object.assign(item, { id }))


        const finalRowsColumnn = RowsColumnn.map(({ mileStone, startDate, endDate, id }, indexx) => {
          let mileStone1 = mileStone.replace(/_/g, ' ');
          let obj;
          if (indexx == 0) {
            obj = {
              id: id + 1,
              mileStone,
              startDate,
              endDate,
              mileStone1,
              minimalDate: `${year}-01-01`
            }
            return obj;
          } else {

            obj = {
              id: id + 1,
              mileStone,
              startDate,
              endDate,
              mileStone1,
              minimalDate: RowsColumnn[indexx - 1].endDate
            }
            return obj;
          }


        });

        setRowsColumn(finalRowsColumnn)
        setSelectedRow(finalRowsColumnn)

        setDataLoading(false)


      })
      .catch((error) => {

      });

  };

  const getSearchData = () => {
    setRowsColumn([])
    setSelectedRow([])

    setDataLoading(true)
    const apiFormatedData = {
      "FY": myState.year.value,
      "Quarter": myState.quarter.value
    }

    var apiFormatedDataJson = JSON.stringify(apiFormatedData);

    let headerss = new Headers();
    headerss.append('Content-Type', 'application/json');
    fetch(`${baseUrl}Milestone_calendarView/`,
      {
        method: 'PUT',
        headers: headerss,
        body: apiFormatedDataJson
      }).then((response) => response.json())
      .then((responseJson) => {
        const tableData = responseJson.data;
        var milesStoneResponse;
        if (responseJson.length == 0) {
          showPopup({
            type: MESSAGE_TYPE.INFORMATION,
            contextText: MESSAGE_TYPE.INFORMATION,
            info: "No record found",
          });
          let year;
          if (myState.year.value == "2022") {
            year = 2022;
          } else {
            year = moment(`${myState.year.value}-01-01`, "YYYY-MM-DD").subtract(1, 'years').year()

          }
          // pratik

          milesStoneResponse = {
            "FY": myState.year.value,
            "Quarter": myState.quarter.value,
            "cut_off_Date": `${year}-01-01`,
            "Publish_Milestone_StartDate": `${year}-01-01`,
            "Publish_Milestone_EndDate": `${year}-01-02`,
            "Qualification_of_VCs_eligible_for_PLI_StartDate": `${year}-01-03`,
            "Qualification_of_VCs_eligible_for_PLI_EndDate": `${year}-01-04`,
            "BoM_Explosion_StartDate": `${year}-01-05`,
            "BoM_Explosion_EndDate": `${year}-01-06`,
            "Part_Details_correction_StartDate": `${year}-01-07`,
            "Part_Details_correction_EndDate": `${year}-01-08`,
            "Publish_to_Vendors_StartDate": `${year}-01-09`,
            "Publish_to_Vendors_EndDate": `${year}-01-10`,
            "Collect_DVA_from_vendors_StartDate": `${year}-01-11`,
            "Collect_DVA_from_vendors_EndDate": `${year}-01-12`,
            "Validation_of_Vendors_input_StartDate": `${year}-01-13`,
            "Validation_of_Vendors_input_EndDate": `${year}-01-14`,
            "TML_DVA_Calculation_StartDate": `${year}-01-15`,
            "TML_DVA_Calculation_EndDate": `${year}-01-16`,
            "TML_DVA_Validation_StartDate": `${year}-01-17`,
            "TML_DVA_Validation_EndDate": `${year}-01-18`,
            "TML_DVA_CA_Certification_StartDate": `${year}-01-19`,
            "TML_DVA_CA_Certification_EndDate": `${year}-01-20`,
            "TML_DVA_ARAI_Certification_StartDate": `${year}-01-21`,
            "TML_DVA_ARAI_Certification_EndDate": `${year}-01-22`,
            "TML_DVA_publish_to_MHI_StartDate": `${year}-01-23`,
            "TML_DVA_publish_to_MHI_EndDate": `${year}-01-24`

          }


          //   return;
        } else {
          milesStoneResponse = responseJson[0]

        }

        let id = milesStoneResponse.id;
        let year = milesStoneResponse.FY;
        let QuarterDB = milesStoneResponse.Quarter;
        let cutOffDate = milesStoneResponse.cut_off_Date;
        let LastUpdated = milesStoneResponse.Last_updated;
        const statusFY = FY.filter(o => o.value == year);
        const quarterStatus = Quarter.filter(o => o.value == QuarterDB);
        setHandoverDate(cutOffDate)
        setState((prevData) => ({ ...prevData, year: statusFY[0], quarter: quarterStatus[0], }));
        delete milesStoneResponse.id;
        delete milesStoneResponse.FY;
        delete milesStoneResponse.cut_off_Date;
        delete milesStoneResponse.Quarter;
        delete milesStoneResponse.Last_updated;


        var milestoneArray = [];
        var startDateArray = [];
        var endDateArray = [];
        for (var prop in milesStoneResponse) {
          let text = prop;
          const milesStoneFinal = text.split("_StartDate")[0];
          let StartDate = text.match(/StartDate/gi);
          let EndDate = text.match(/EndDate/gi);
          const mileStoneEnd = text.split("_EndDate")[0];
          if (StartDate != null) {
            let obj = {
              "startDate": milesStoneResponse[prop]
            }
            startDateArray.push(obj)
            milestoneArray.push(milesStoneFinal)
          }

          if (EndDate != null) {
            let obj = {
              "endDate": milesStoneResponse[prop]
            }
            endDateArray.push(obj)
            milestoneArray.push(mileStoneEnd)
          }

        }

        function removeDuplicates(arr) {
          return arr.filter((item,
            index) => arr.indexOf(item) === index);
        }

        let mileStoneFinal = removeDuplicates(milestoneArray).map((iteam) => ({ mileStone: iteam }));

        var merged = [];

        for (let i = 0; i < mileStoneFinal.length; i++) {
          merged.push({
            ...startDateArray[i],
            ...endDateArray[i],
            ...mileStoneFinal[i]
          });
        }
        let RowsColumnn = merged.map((item, id) => Object.assign(item, { id }))


        const finalRowsColumnn = RowsColumnn.map(({ mileStone, startDate, endDate, id }, indexx) => {
          let mileStone1 = mileStone.replace(/_/g, ' ');
          let obj;

          if (indexx == 0) {
            obj = {
              id: id + 1,
              mileStone,
              startDate,
              endDate,
              mileStone1,
              minimalDate: `2022-01-01`
            }
            return obj;
          } else {

            obj = {
              id: id + 1,
              mileStone,
              startDate,
              endDate,
              mileStone1,
              minimalDate: RowsColumnn[indexx - 1].endDate
            }
            return obj;
          }
        });

        setRowsColumn(finalRowsColumnn)
        setSelectedRow(finalRowsColumnn)

        setDataLoading(false)


      })
      .catch((error) => {

      });

  };






  return (
    <>
      <div className={clsx(styles.scrollContainer, styles.scrollBar, styles.formContainer)}>
        {/* <div className={styles.row}>
          <div className={styles.formTitle}>
            <span className={styles.formSpan}>Demerit Revision</span>
            <span className={styles.reqired}>Note:* Mandatory fields</span>
          </div>
        </div> */}

        {/* <CustomFormGroup header='Chassis Defect Details' body={renderProcessInfo()} />
        <CustomFormGroup header='E-POD Details' body={renderEPOD()} /> */}
        {/* <CustomFormGroup header='Chassis Details' body={renderStatic()} /> */}
        <CustomFormGroup header='Calendar' body={renderOther()} />
        <CustomFormGroup header='Audit Details' body={renderMilestone()} />






      </div>

      <div className={styles.actionBar}>
        {/* <Button
          className={clsx(styles.actionButton, styles.resetButton)}
          variant="tertiary"
          // onClick={handleOpen}
          onClick={() => {
            handlePrompt2('0')
          }}
        >
          Reset
        </Button> */}



        <AuthChecker operation="submit">
          {isAuthorized => (
            <Button
              className={clsx(
                styles.actionButton,
                styles.primaryActionButton,
              )}
              variant="primary"
              onClick={() => runPreSubmissionChecks() && handleSubmit('submition')}
              //  onClick={(selectedRow) => handleSubmit('submition')}
              disabled={!isAuthorized || myState.submitBtn}
              data-testid="confirm-action"
            >
              SUBMIT
            </Button>
          )}

        </AuthChecker>

      </div>
      <ResetModal
        isPopupOpen={isPopupOpen}
        // handleClose={handleClose}
        resetFields={resetFields}
      />

      <ResetModal2
        a={confirmModalFlag2}
        handleCase={handleClose2}
        onDelete={() => onDeleteRow2('ok')}
      />


    </>
  )
}


function ResetModal2({ a, handleCase, onDelete }) {
  return (
    <Dialog
      open={a}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup1"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <DeleteIcon style={{ height: '18px', width: '18px' }} />
            <span className={styles.txt}>Are you sure ?</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <span>
            Do you want to reset data ?
          </span>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleCase}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={onDelete}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function ResetModal1({ a, handleCase, onDelete }) {
  return (
    <Dialog
      open={a}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup1"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <DeleteIcon style={{ height: '18px', width: '18px' }} />
            <span className={styles.txt}>Are you sure ?</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <span>
            Do you want to delete ?
          </span>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleCase}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={onDelete}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

function ResetModal({ isPopupOpen, handleClose, resetFields }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <MdReplay style={{ height: '18px', width: '18px' }} />
            <span className={styles.txt}>Reset</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <span>
            Are you sure you want to reset the form?
          </span>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleClose}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleClose()
              resetFields()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

Audit.propTypes = {
  redirectToProcessPage: PropTypes.func
}

ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  resetFields: PropTypes.func.isRequired
}
ResetModal1.propTypes = {
  a: PropTypes.bool.isRequired,
  handleCase: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}
ResetModal2.propTypes = {
  a: PropTypes.bool.isRequired,
  handleCase: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  product: state.product,
  counter: state.counter.value,
  baseUrl: state.counter.baseUrl,
  auth: state.auth,
  tableData: state.tableData
});
const mapDispatchToProps = (dispatch) => ({

  fetchChassis: (chassisParam) => dispatch(fetchChassis(chassisParam)),
  getInvoice: (chassisParam) => dispatch(getInvoice(chassisParam)),
});
Audit.propTypes = {
  counter: PropTypes.number,
  baseUrl: PropTypes.string,
  product: PropTypes.object,
  auth: PropTypes.object,
  tableData: PropTypes.object,
};
const App = connect(mapStateToProps, mapDispatchToProps)(Audit);


export default withAllowedOperationsProvider(
  App,
  RESOURCE_TYPE.PPAP
);
