import { combineReducers } from 'redux';

import authReducer from './features/auth/authSlice';
import productsReducer from './reducers/productsReducer'; 
import counterReducer from './reducers/counterReducer';
import menuReducer from './reducers/menuReducer';
import tableReducer from './reducers/tableReducer';


const rootReducer = combineReducers({
  auth: authReducer,
  product:productsReducer,
  counter:counterReducer,
  menu:menuReducer,
  tableData:tableReducer
});

export default rootReducer;
