/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types';

import { Modal, Dialog, DialogTitle, DialogContent, DialogActions, Form, Box, TextField, Grid, FormGroup, IconButton } from '@material-ui/core';
import WarningIcon from '@mui/icons-material/Warning';
import FormLabel from '@mui/material/FormLabel';
// import $ from 'jquery';
import clsx from 'clsx'
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { useHistory } from 'react-router-dom';
import { TrendingUpOutlined } from '@material-ui/icons';
import Table from '../../components/Table/Table';
import styles from './VcBomDataInfo.module.css';
import { CustomFormGroup, CustomSelect } from '../../components/FormComponents'
import BackButton from '../../components/BackButton/BackButton';
import { DATE_FORMAT, API_RESOURCE_URLS, DISPLAY_MESSAGES, MESSAGE_TYPE, RESOURCE_TYPE, USER_OPERATIONS, Regex } from '../../constants'
import { withAllowedOperationsProvider } from '../../hocs';
import { CustomTab, Button,AuthChecker } from '../../atomicComponents';


import { usePopupManager } from '../../providers/PopupManager/PopupManager';
import StepBar1 from '../../components/StepBar1/StepBar';




const VcBomData = () => {
  const history = useHistory();
  const { showPopup } = usePopupManager();
  // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector(state => state.counter.baseUrl);  // useSelector
  const auth = useSelector((state) => state.auth); // useSelector



  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [dynamicColumns1, setDynamicColumns1] = useState([]);
  const [plant, setPlant] = useState(null);
  const [flag, show] = useState(false);
  const [Bc, setBC] = useState(null);
  const [bcArray, setBcArray] = useState([]);
  const [plantArray, setPlantArray] = useState([]);
  const [tableArray, setTableArray] = useState([]);
  const [loader, setLoader] = useState(true);
  const [asnCount, setAsnCount] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleOpen = () => setIsPopupOpen(true);
  const [datesData, setDates] = useState({
    endDate:"",
    startDate:"",
  });

  // 
  const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false);
  const handleOpenFormModal= () => {
    setPopupOpenFormModal(true);}
  const handleCloseFormModal= () => setPopupOpenFormModal(false);

  // 

    // 
    const [reseVC, setReseVC] = useState(false);
    const handleOpenVCResetModal= () => {
      setReseVC(true);
    }
    const handleCloseVCResetModal= () => setReseVC(false);
  
    // 




  
  const handleClose = () => setIsPopupOpen(false);
  // start
  const [selectedRow, setSelectedRow] = useState([]); 
  const handleOpen1 = (selected) => {setIsPopupOpen1(true); setSelectedRow(selected)};
  const [isPopupOpen1, setIsPopupOpen1] = useState(false); 
  const handleClose1 = () => setIsPopupOpen1(false);
  const goNext1 = () =>{
    actionBaseVC()
};
const actionBaseVC = () => {
  let ids = selectedRow.map(({ dbId }) => dbId)
  const remainingData = tableArray.filter(({dbId})=> !ids.includes(dbId))
  setTableArray(remainingData)
  ids=ids.join().split(',')
  
  const apiFormatedData = {
    id:ids,
  }
  
  var apiFormatedDataJson = JSON.stringify(apiFormatedData);
  console.log("apiFormatedDataJson>>>",apiFormatedDataJson)
  let headerss = new Headers();
  headerss.append('Content-Type', 'application/json');
  fetch(`${baseUrl}Show_Base_Vc_Data/`,
    {
      method: 'DELETE',
      // headers: headerss,
      body: apiFormatedDataJson
    }).then((response) => response.json())
    .then((responseJson) => { 

     
        showPopup({
        type: MESSAGE_TYPE.SUCCESS,
        contextText: MESSAGE_TYPE.SUCCESS,
        info: "Data is Succesfully Deleted"

      });
    })
    .catch((error) => {

    });
  

};

// end



  const goNext = () => {
    uploadFileData()
  };

  const ROW_HEIGHT = 38;
  const redirectToApplicationLandingPage = () => {
    history.push('/');
  };

  const setCount = count => {
    setAsnCount(count);
  };

  useEffect(() => {
    getData();
    getdate();
  }, [])
  const getdate = () => {
  
    const headerss = new Headers();
    headerss.append("Content-Type", "application/json");
    fetch(`${baseUrl}Milestone_calendarView/`, {
      method: "GET",
      headers: headerss,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const endDate = responseJson[0].Part_Details_correction_StartDate;
        const startDate = responseJson[0].Part_Details_correction_EndDate;
        setDates((prevData)=>({...prevData,endDate,startDate}));
       
      })
      .catch((error) => {});
  };
  const showTable = () => {
    getTableData('Yes');
    show(true)

  }

  const handleSelect = (selectedOption, name) => {
    if (name == "baseVC") {
      show(false)

      setBC(selectedOption);
      // getPlantData(selectedOption)

    } else {
      setPlant(selectedOption);
    }

  }
  const getTableData = (flagParam) => {
    const apiFormatedData = {
      VC: Bc.value,
    }

    var apiFormatedDataJson = JSON.stringify(apiFormatedData);
    let headerss = new Headers();
    headerss.append('Content-Type', 'application/json');
   // Show_Base_Vc_Data_for_PV
    fetch(`${baseUrl}Show_Base_Vc_Data/`,
      {
        method: 'POST',
        headers: headerss,
        body: apiFormatedDataJson
      }).then((response) => response.json())
      .then((responseJson) => {
        const tableData = responseJson.data;
        const excelData = tableData.map(({id, VC, Part_number, Group_Part_number, Plant_code, BOM_Level, Procurement_type, Spl_Procurement_type, BOM_Quantity_of_Part, Unit_of_Measure_BOM, Vendor_Code, NAME1, SOB, Usage_Probability, final_bom_qty, Purchase_Group_details, PO_Number, PO_Price, Value, Part_Description, PO_Date, PO_Currency, Order_Price_unit, Cell_Identifier, Blocked_item, Exception, Exception_Classification, BOM_Extraction_Date, BOM_Explosion_Date, Bulk_material_indicator,
          Ref_interplant_assemble,
          Destination_plant,
          Item_category,
          Po_doc_type,Exclusion_Flag ,Exclusion_Reason}
          , indexx) => {
          let obj =
          {
            id:indexx,
            dbId:id,
            VC,
            Part_number,
            Group_Part_number,
            Plant_code,
            BOM_Level,
            Procurement_type,
            Spl_Procurement_type,
            BOM_Quantity_of_Part,
            Unit_of_Measure_BOM,
            Vendor_Code,
            NAME1,
            SOB,
            Usage_Probability,
            final_bom_qty,
            Purchase_Group_details,
            PO_Number,
            PO_Price,
            Value,
            Part_Description,
            PO_Date,
            PO_Currency,
            Order_Price_unit,
            Cell_Identifier,
            Blocked_item,
            Bulk_material_indicator,
            Ref_interplant_assemble,
            Destination_plant,
            Item_category,
            Po_doc_type,
            Exception,
            Exception_Classification,
            Exclusion_Flag,
            Exclusion_Reason,
            BOM_Extraction_Date,
            BOM_Explosion_Date
          }

          return obj
        })
        setTableArray(excelData)
        if (flagParam == "Yes") {
    //       exportExcel(excelData);

        }
        setLoader(false)




      })
      .catch((error) => {

      });

  };
  const goNextForm = () => {
  var url;
  var urlMethod;

    let { userRole, userID } = auth;

    if (userRole.includes("GDC") ) {
      url="ColourVcMISProgress";
      urlMethod="POST"; 
    } else if(userRole.includes("Purchase") || userRole.includes("Purchase-CV")){
      url="ColourVcMISProgress";
      urlMethod="PUT"; 
    } else {
      url="ColourVcMISProgress";
      urlMethod="POST"; 
    } 
    const apiFormatedData = {
      VC: Bc.value,
  

    }

    var apiFormatedDataJson = JSON.stringify(apiFormatedData);
    let headerss = new Headers();
    headerss.append('Content-Type', 'application/json');
    fetch(`${baseUrl}${url}/`,
      {
        method: urlMethod,
        headers: headerss,
        body: apiFormatedDataJson
      }).then((response) => response.json())
      .then((responseJson) => { 
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: "Data is Succesfully Saved"
  
        });
        history.push('/')

      })
      .catch((error) => {

      });

  };
  const resetVCCall = () => {

    const apiFormatedData = {
      VC: Bc.value, 

    }

    var apiFormatedDataJson = JSON.stringify(apiFormatedData);
    let headerss = new Headers();
    headerss.append('Content-Type', 'application/json');
    fetch(`${baseUrl}Show_Base_Vc_Data/`,
      {
        method: "PATCH",
        headers: headerss,
        body: apiFormatedDataJson
      }).then((response) => response.json())
      .then((responseJson) => { 
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: "Data is succesfully reset"
  
        });

      })
      .catch((error) => {

      });

  };


  const getData = () => {

    const apiFormatedData = {
      "BascVC": "",
      "Mfg_plant": "",
    }

    var apiFormatedDataJson = JSON.stringify(apiFormatedData);
    let headerss = new Headers();
    headerss.append('Content-Type', 'application/json');
    fetch(`${baseUrl}Show_Base_Vc_Data/`,
      {
        method: 'GET',
        headers: headerss,
        //    body: apiFormatedDataJson
      }).then((response) => response.json())
      .then((responseJson) => {
        const ListArray = responseJson.map(({ VC, }) => ({ value: VC, label: VC }));
        setBcArray(ListArray)

      })
      .catch((error) => {

      });

  };

  const uploadFileData = () => {
    var input = document.createElement('input');
    input.type = "file";
    input.setAttribute("accept", ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel");
    input.click();
    input.onchange = (e) => {
      setLoader(true)
      show(true)


      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      fetch(`${baseUrl}Show_Base_Vc_Data/`,
        {
          method: 'PUT',
          body: formData
        }).then((response) => {
          // eslint-disable-next-line radix
          let status1 = parseInt(response.status)
          if (status1 == "201") {
            //   document.removeChild(input);

            getTableData('No');
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded sucessfully",

            });

          } else {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded failed",

            });
          }


        })

        .catch((error) => {
        });

    }

  }

  const exportExcel = async (excelData) => {
    var fileTypel = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    var fileExt = ".xlsx"
    const ws = XLSX.utils.json_to_sheet(excelData)
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileTypel });
   // FileSaver.saveAs(data, `${Bc.value}${fileExt}`);
    FileSaver.saveAs(data, `${Bc.value}${fileExt}`);
  }
  const filterExcel = async (excelData) => {
    var fileTypel = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    var fileExt = ".xlsx"
    const ws = XLSX.utils.json_to_sheet(excelData)
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileTypel });
    FileSaver.saveAs(data, `${Bc.value}${fileExt}`);
  }


  const baseDefaultColumns = [
    {
      width: 120,
      title: 'VC',
      field: 'VC', 
      enableSearch: true,
      enableFilter: true,

    },
    {
      width: 80,
      title: 'Plant code',
      field: 'Plant_code',
      enableSearch: true,
      enableFilter: true,
      
    },
    {
      width: 150,
      title: 'Part number',
      field: 'Part_number',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 180,
      title: 'Part Description',
      field: 'Part_Description',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: 'Supplier Code',
      field: 'Supplier_Code',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 180,
      title: 'Supplier Name',
      field: 'Supplier_Name',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: 'Supplier Under PLI',
      field: 'Supplier_Under_PLI',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: 'Part UOM',
      field: 'Part_UOM',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: 'GR No',
      field: 'GR_No',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: 'GR Date',
      field: 'GR_Date',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 0,
      title: 'SOB',
      field: 'SOB',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: 'Import/ Domestic',
      field: 'Import_Domestic',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: 'Invoice No(R) ',
      field: 'Invoice_No_R ',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: 'Invoice Date(R)',
      field: 'Invoice_Date_R',
      enableSearch: true,
      enableFilter: true,
    },

    {
      width: 120,
      title: 'Invoice No (S) ',
      field: 'Invoice_No_S ',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: 'Invoice Date(S)',
      field: 'Invoice_Date_S',
      enableSearch: true,
      enableFilter: true,
      
    },
    {
      width: 120,
      title: 'Price Per Part (R)',
      field: 'Price_Per_Part_R',
      sortable:true, 
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: 'PPrice Per Part (S)',
      field: 'Price_Per_Part_S',
      sortable:true, 
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: 'Total Part Price ',
      field: 'Total_Part_Price ',
      enableSearch: true,
      enableFilter: true,
    },
    
    {
      width: 120,
      title: 'SPOC',
      field: 'SPOC',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: 'CTL',
      field: 'CTL',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 150,
      title: 'VC-1 ',
      field: 'VC_1',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: 'VC-2',
      field: 'VC_2',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: 'VC-3',
      field: 'VC_3',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: 'VC-4',
      field: 'VC_4',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: 'VC-5',
      field: 'VC_5',
      enableSearch: true,
      enableFilter: true,
    },
      
 
  ];

  const redirectToCreateProjectPage = () => history.push('/');
  const redirectToProjectEditPage = (id) => history.push(`/`);
  const redirectToProjectDetailsPage = (id) => history.push(`/`);



  const renderHTML = () => (
    <div className={styles.formGroup}>

<Grid
        container
        spacing={1}

        style={{ display: 'flex', justifyContent: "left", marginTop: "5px",marginBottom: "1px" }}
        columns={6}
      >
        <Grid item md={6} lg={6}>
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{width:"15%"}}>
              <label className={styles.label}>VC</label>
            </div>
            <div className={styles.formDiv}>
            <CustomSelect
              name="bc"
              options={bcArray}
              className={clsx(styles.select, styles.sel1)}
              value={Bc}
              isMulti={false}
              style={{width:"54%"}}
           //   isClearable
              onChange={(e) => { handleSelect(e, 'baseVC') }}

            />
            <div                
             style={{marginLeft:"5px", marginTop: "1px",display:"flex"}}

         
>

                  <AuthChecker operation="search">
             {isAuthorized => (
               <Button
                 className={clsx(
                   styles.actionButton,
                   styles.primaryActionButton,
                 )}
                 variant="primary"
                 //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
                 onClick={showTable}
                  disabled={!isAuthorized}
                 data-testid="confirm-action"
               >
                 Show Data
               </Button>
             )}
   
           </AuthChecker>
 
      {
        loader==false&&
    
          <>
        <AuthChecker operation="download">

          {isAuthorized => (
               <Button
                 className={clsx(
                   styles.actionButton,
                   styles.primaryActionButton,
                 )}
                 variant="primary"
                 //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
                 onClick={()=>{

                  if(tableArray.length>=1){exportExcel(tableArray);}
                 }}
                  disabled={!isAuthorized}
                 data-testid="confirm-action"
                 style={{marginLeft:"5px"}}

               >
                 download
               </Button>
             )}
   
           </AuthChecker>
         <AuthChecker operation="search">
             {isAuthorized => (
               <Button
              //  className={clsx(styles.actionButton, styles.resetButton)}
              //  variant="tertiary"
              style={{marginLeft:"5px"}}

              className={clsx(
                styles.actionButton,
                styles.resetButton,
              )}
              variant="tertiary"
                 onClick={handleOpenVCResetModal}
                  disabled={!isAuthorized}
                 data-testid="confirm-action"

               >
                 reset
               </Button>
             )}
   
           </AuthChecker>
       </> 
    }


              </div>
            </div>
          </div>
  
        </Grid>
   
        </Grid>

      {/* <div className={styles.formGroup}>

        <div className={styles.formGroupRow}>
          <div className={styles.formRow}>
            <label className={styles.label}>
              VC
            </label>
            <CustomSelect
              name="bc"
              options={bcArray}
              className={clsx(styles.select, styles.sel1)}
              value={Bc}
              isMulti={false}
              isClearable
              onChange={(e) => { handleSelect(e, 'baseVC') }}

            />

          </div>
          <div className={styles.formRow}>
            <label className={styles.label}>
              <Button className={clsx(
                styles.actionButton,
                styles.primaryActionButton,
              )}
                variant="primary"
                onClick={showTable}>
                Get Information

              </Button>
            </label>

          </div> 
        </div> 
      </div> */}


    </div>

  )

  return (
    <>
      <div className={styles.container} style={{ boxSizing: 'border-box' }}>
        <div>
          <div className={styles.backButtonLastRefreshedDateWrapper}>
            <BackButton
              action="Back"
              handleClick={redirectToApplicationLandingPage}
            />
          <div className={styles.topBar} style={{}}>
                <div className={styles.divspan}>Part Details Correction Start Date:<span>{datesData.startDate}</span></div>
                <div className={styles.divspan}>Part Details Correction End Date:<span>{datesData.endDate}</span></div>
              </div> 

          </div>
          <div className={styles.topDiv}>
            <div className={styles.processHeader}>
              <div style={{ width: '15%' }}>
                <CustomTab title="VCBoM Data CV" withOutCount isSelected />
              </div>
           
              <StepBar1 enable={4} />

            </div>
          </div>
        </div>
        <div   >
          <div className={styles.formContainer}>
            <CustomFormGroup header='VC Details' body={renderHTML()} />


          </div>
        </div>
        {
          flag &&

          <div
            className={styles.tableView}
            style={{ height: '86%', padding: '0 2em' }}
          >


            <Table
              columns={[...baseDefaultColumns]}
              defaultColumns={[...baseDefaultColumns]}
              rowHeight={38}
              rows={tableArray}
              primaryAction={
                {
                  name: 'Submit Data',
                  authOperation: 'submit',
                  shouldEnable: () => true,
                  actionFn: handleOpenFormModal,
                }


              }

              secondaryActions={[
                // {
                //   name: 'Download Data',
                //   authOperation: 'create-project',
                //   shouldEnable: selected => true,
                //   actionFn: selected => {},
                //   customClass:""
                //  customClass:"btnDelete"

                // },




                {
                  name: 'Upload Data',
                  authOperation: 'upload',
                  // shouldEnable: selected => selected.length === 1,
                  shouldEnable: () => true,
                  actionFn: selected => { handleOpen(); },
                  customClass: ""
                  //  customClass:"btnDelete"

                },
                {
                  name: 'Delete',
                  authOperation: 'delete',
                  shouldEnable: selected => selected.length >=1,
                  actionFn: selected => {  handleOpen1(selected); 
                  },
                  customClass:"btnDelete"
  
                }

              ]}
              setAsnCount={setCount}
              isDataLoading={loader}
              actionButtons
              onRowSelect={(e) => { }}
             // removeRowSelection
              filterDownload={filterExcel}
              filterDownloadBtn
              


            />
          </div>
        }
      </div>
      <ResetModalVC
        isPopupOpen={reseVC}
        handleCloseVCModal={handleCloseVCResetModal}
        resetVCCall={resetVCCall}
      />



      <ResetModalF
        isPopupOpen={isPopupOpenFormModal}
        handleCloseFormModal={handleCloseFormModal}
        goNextForm={goNextForm}
      />
      <ResetModal
        isPopupOpen={isPopupOpen}
        handleClose={handleClose}
        goNext={goNext}
      />

          <ResetModal2
        isPopupOpen1={isPopupOpen1}
        handleClose1={handleClose1}
        goNext1={goNext1}
      />
 

{/* <formModal
        isPopupOpen={isPopupOpenFormModal}
        handleCloseFormModal={handleCloseFormModal}
        goNextForm={goNextForm}

      /> */}
    </>)
}
function ResetModalVC({ isPopupOpen, handleCloseVCModal, resetVCCall }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>Confirm Reset</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation, Selected VC BoM will get reset and all changes made will be lost</p>

            <p>Are you sure ?</p>

          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleCloseVCModal}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleCloseVCModal();
              resetVCCall();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function ResetModalF({ isPopupOpen, handleCloseFormModal, goNextForm }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>Confirm Submit</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation ,VC BoM data will submit</p>

            <p>Are you sure ?</p>

          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleCloseFormModal}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleCloseFormModal()
              goNextForm()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

function ResetModal({ isPopupOpen, handleClose, goNext }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>Confirm file Upload</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation ,Records will be appended.</p>

            <p>Are you sure ?</p>

          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleClose}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleClose()
              goNext()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

function ResetModal2({ isPopupOpen1, handleClose1, goNext1 }) {
  return (
    <Dialog
      open={isPopupOpen1}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{marginLeft:"8px"}} className={styles.txt}>Selected data will be deleted</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p> On confirmation, Selected data will be deleted.</p>
            <p>Are you sure ?</p>

          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleClose1}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              goNext1()
              handleClose1()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}




ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
};
ResetModalF.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleCloseFormModal: PropTypes.func.isRequired,
  goNextForm: PropTypes.func.isRequired,
};
ResetModalVC.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleCloseVCModal: PropTypes.func.isRequired,
  resetVCCall: PropTypes.func.isRequired,
};



ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired
}
ResetModal2.propTypes = {
  isPopupOpen1: PropTypes.bool.isRequired,
  handleClose1: PropTypes.func.isRequired,
  goNext1: PropTypes.func.isRequired
}



export default withAllowedOperationsProvider(
  VcBomData,
  RESOURCE_TYPE.PROJECT
);