import { MENU } from "../actions/productsActions";

export const productsInitialState = {
    menu: { 
            masterMenuList:[],
            allMenuList:[] 
    }

};

const menuReducer = (state = productsInitialState, action) => {
    switch (action.type) {

        case MENU:

            return {
                ...state, menu: {
                    masterMenuList: action.payload.masterMenuList,
                    allMenuList: action.payload.allMenuList
                }
            };

        default:
            return state;
    }
};

export default menuReducer;
