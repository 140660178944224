

/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Form,
  Box,
  TextField,
  Grid,
  FormGroup,
  IconButton,
} from "@material-ui/core";
import WarningIcon from "@mui/icons-material/Warning";
import FormLabel from "@mui/material/FormLabel";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
// for print user in consol
import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";
// for print user in consol
import axios from "axios";

import clsx from "clsx";
import { useHistory } from "react-router-dom";
import Table from '../../../components/TableForAdmin/TableForAdmin'

import styles from "./MPFDMaster.module.css";
import BackButton from "../../../components/BackButton/BackButton";
import {
  DATE_FORMAT,
  API_RESOURCE_URLS,
  DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  MESSAGE_TYPE_CERTIFICATION,
  MESSAGE_TYPE_ANNEXURE,
  RESOURCE_TYPE,
  USER_OPERATIONS,
  Regex,
} from "../../../constants";
import { withAllowedOperationsProvider } from "../../../hocs";
import { CustomTab, Button ,AuthChecker} from "../../../atomicComponents";
// import { API } from "../../apis/api";
// import { buildErrorMessage } from "../../apis/calls";
import { usePopupManager } from "../../../providers/PopupManager/PopupManager";

import StepBar1 from '../../../components/StepBar1/StepBar';
// import { error } from "jquery";

const MPFDMaster = () => {


  const history = useHistory();
  const { showPopup } = usePopupManager();
  // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector((state) => state.counter.baseUrl); // useSelector
  const auth = useSelector((state) => state.auth); // useSelector
  // console.log('auth in Supplier: ', auth);
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [dynamicColumns1, setDynamicColumns1] = useState([]);
//   const [plant, setPlant] = useState(null);
//   const [flag, show] = useState(false);
//   const [checkDisable, setDisable] = useState(true);
  const [Bc, setBC] = useState(null);
//   const [projectCode,setProjectCode] = useState(null)
//   const [bcArray, setBcArray] = useState([]);
//   const [plantArray, setPlantArray] = useState([]);
//   const [tableArray, setTableArray] = useState([]);
  const [asnCount, setAsnCount] = useState(0);
//   const [Fy,setFY] = useState(null)
//   const [fyArray,setFyArray] = useState([])
//   const [quarter,setQuarter] = useState(null)
//   const [quarterArray,setQuarterArray] = useState([])
//   const [projectCodeArray,setProjectCodeArray] = useState([])

  const [tableData,setTableData] = useState([])
  const [loading, setLoader] = useState(false);
  const [loader, setLoading] = useState(true);
//   const [vendCode, setvendCode] = useState();
//   const [datesData, setDates] = useState({
//     endDate:"",
//     startDate:"",
//   });

    // 
    const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false);
    const handleOpenFormModal= () => {
      setPopupOpenFormModal(true);}
    const handleCloseFormModal= () => setPopupOpenFormModal(false);
  
    // 

  const ROW_HEIGHT = 38;

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleOpen = () => setIsPopupOpen(true);
  const handleClose = () => setIsPopupOpen(false);
  const goNext = () => {
    uploadFileData();
  };

  const setCount = (count) => {
    setAsnCount(count);
  };


const redirectToApplicationLandingPage = () => {
    history.push('/')
}
const getData = () => {
  const headers = new Headers(
    {
      'Content-Type' : 'application/json'
    }
  )
  fetch(`${baseUrl}BuyerMasterAfsMpfdData/`,{
    method : 'GET',
    header : headers,
  })
  .then((response)=>{
      if(response.ok){
        return response.json()
      }
      return response.json().then(data => {
        console.log(data)
        throw new Error(data.status);
    })
  })
  .then((Jsonresponse)=>{
    console.log('jsonResponse',Jsonresponse)
    // const updatedResponse = Jsonresponse.map((item)=> 
    //   ({
    //     ...item,
    //     is_deleted : (item.is_deleted === 'False' ||item.is_deleted === 'FALSE') ? 'No' : 'Yes'
    //   })
    // )
    // setTableData(updatedResponse) 
    setTableData(Jsonresponse)
  })
  .catch((error)=> {
    console.log(error)
    showPopup({
      type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: `Error : ${error.message}`,
    })
  })
}
useEffect(()=>{
  console.log(tableData)
  getData()
},[])

const uploadFileData = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.id = "file";
    input.setAttribute(
      "accept",
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    );
    input.click();
    input.onchange = (e) => {
      setLoader(true)
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      fetch(`${baseUrl}SupplierDataaddition/`, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          // eslint-disable-next-line radix
          let status1 = parseInt(response.status);
          if (status1 == "201") {
            //   document.removeChild(input);
            // getTableData();
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded sucessfully",
            });
          } else {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded failed",
            });
          }
        })

        .catch((error) => {});
    };
  };

  const exportExcel = async (excelData) => {
    // console.log('excelData: ', excelData);
    const modifiedExcelData = excelData.map((row,index) => {
      const modifiedRow = { 'id' : index,...row }; 
      return modifiedRow;
    });

    var fileTypel =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    var fileExt = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(modifiedExcelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { booktype: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileTypel });
    // FileSaver.saveAs(data, `${Bc?.value}${fileExt}`);
    FileSaver.saveAs(data, `AFSMPFDExcelData${fileExt}`);
  };


  

  

 

  

 
  // useEffect(()=>{
  //   console.log("bcArray","effect")

  //   const {userRole,userID }=auth;

  //   if(userRole.includes("Vendor-CV") || userRole.includes("Vendor-PV"))
  //     {
  //    const singleVendor = bcArray.filter(element => element.value ==userID);
  //    if(singleVendor.length>=1)
  //    {
  //     setBC(singleVendor[0])
  //     console.log(bcArray,"render setbc",userID)
  //     setDisable(true)
  //    } else{
  //     console.log(bcArray,"render empty else",userID,singleVendor)

  //    }

  //    } else{
  //     setDisable(false)
  //    }

  // },[bcArray])
  //  useEffect(()=>{
  //   const {userRole,userID }=auth;

  //   if(userRole.includes("Vendor-CV") || userRole.includes("Vendor-PV"))
  //     {
  //       console.log(Bc,"render")
  //    showTable()
  //    } else{
  //     setDisable(false)
  //    }

  // },[Bc])

//   const showTable = () => {
//     const {userRole} = auth
//     if(userRole[0] === 'admin' || userRole[0] === 'TMLBSL' || userRole[0] === 'GDC' || userRole[0] === 'Purchase'){
//       if(projectCode === null || projectCode.value === ''){
//         showPopup({
//           type: MESSAGE_TYPE.INFORMATION,
//           contextText: MESSAGE_TYPE.INFORMATION,
//           info: "Project Code is Mandatory",
//       })}else{
//         getTableData();
//         show(true);
//       }

//     }else if(userRole[0] === 'Vendor'){
//       if(projectCode === null || projectCode.value === ''){
//         showPopup({
//           type: MESSAGE_TYPE.INFORMATION,
//           contextText: MESSAGE_TYPE.FAILURE,
//           info: "Project Code is Mandatory",
//       })}else if(Bc === null || Bc.value === ''){
//         showPopup({
//           type: MESSAGE_TYPE.INFORMATION,
//           contextText: MESSAGE_TYPE.FAILURE,
//           info: "Supplier Code is Mandatory",
//       })
  
//       }
//       else if(plant === null || plant.value === ''){
//         showPopup({
//           type: MESSAGE_TYPE.INFORMATION,
//           contextText: MESSAGE_TYPE.FAILURE,
//           info: "Plant Code is Mandatory",
//       })  
//     }else{
//       getTableData();
//       show(true);
//     }

//     }
//     else{
//       getTableData();
//       show(true);

//     }
    
    
//   };

//   // let Vendor_Code ='';
//   const handleSelect = (selectedOption, name) => {
//     // alert(selectedOption.value);
//     // console.log(selectedOption);
//     setvendCode(selectedOption.value);
//     if (name == "VC") {
//       show(false);

//       setBC(selectedOption);
//       // getPlantData(selectedOption)
//     } else {
//       setPlant(selectedOption);
//     }
//   };

//   const getTableData = () => {
    
    
//     const apiFormatedData = {
//       Supplier_Code: Bc?.value,
//       Project_code : projectCode?.value,
//       Financial_year : Fy?.value,
//       Quarter: quarter?.value,
//       Plant_code : plant?.value
//     };
//     var apiFormatedDataJson = JSON.stringify(apiFormatedData);
//     let headerss = new Headers();
//     headerss.append("Content-Type", "application/json");
//     fetch(`${baseUrl}SupplierDvaCollection/`, {
//       method: "POST",
//       headers: headerss,
//       body: apiFormatedDataJson,
//     })
//       .then((response) => response.json())
//       .then((responseJson) => {
//         console.log('table data response',responseJson)
//         const tableData = responseJson.data;

//         setTableArray(tableData);
//         // const excelData = tableData.map(
//         //   (
//         //     {
//         //       Vendor_Code,
//         //       Plant_code,
//         //       Part_number,
//         //       Part_Description,
//         //       Selling_Price,
//         //       Value_of_Direct_Import_by_us,
//         //       Broad_Description_of_parts_imported_by_us,
//         //       Value_of_parts_imported_by_our_suppliers,
//         //       Broad_Description_of_parts_imported_by_our_suppliers,
//         //       Local_Content,
//         //       DVA,
              
//         //     },
//         //     id
//         //   ) => {
//         //     let obj = {
//         //       id,
//         //       Vendor_Code,
//         //       Plant_code,
//         //       Part_number,
//         //       Part_Description,
//         //       Selling_Price,
//         //       Value_of_Direct_Import_by_us,
//         //       Broad_Description_of_parts_imported_by_us,
//         //       Value_of_parts_imported_by_our_suppliers,
//         //       Broad_Description_of_parts_imported_by_our_suppliers,
//         //       Local_Content,
//         //       DVA,
//         //     };
//         //     return obj;
//         //   }
//         // );

//         // setTableArray(excelData)
//         // setTableArray(responseJson)
//         setLoading(false)
//       })
//       .catch((error) => {
//         console.log(error)

//       });
//   };

//   const getTableData1 = (value) => {
//     const apiFormatedData = {
//       VC: value,
//     };
//     var apiFormatedDataJson = JSON.stringify(apiFormatedData);
//     let headerss = new Headers();
//     headerss.append("Content-Type", "application/json");
//     fetch(`${baseUrl}Venodr_wise_Data_By_Vendor_code/`, {
//       method: "POST",
//       headers: headerss,
//       body: apiFormatedDataJson,
//     })
//       .then((response) => response.json())
//       .then((responseJson) => {
//         if (responseJson.data === null || responseJson.status === 500){
//           showPopup({
//               type: MESSAGE_TYPE.FAILURE,
//               contextText: MESSAGE_TYPE.FAILURE,
//               info: "Data not found",
  
//           })
//         }else{
//           const tableData = responseJson.data;
//           setTableArray(tableData);
//         }
        
//         // const excelData = tableData.map(
//         //   (
//         //     {
//         //       Vendor_Code,
//         //       Plant_code,
//         //       Part_number,
//         //       Part_Description,
//         //       Selling_Price,
//         //       Value_of_Direct_Import_by_us,
//         //       Broad_Description_of_parts_imported_by_us,
//         //       Value_of_parts_imported_by_our_suppliers,
//         //       Broad_Description_of_parts_imported_by_our_suppliers,
//         //       Local_Content,
//         //       DVA,

//         //     },
//         //     id
//         //   ) => {
//         //     let obj = {
//         //       id,
//         //       Vendor_Code,
//         //       Plant_code,
//         //       Part_number,
//         //       Part_Description,
//         //       Selling_Price,
//         //       Value_of_Direct_Import_by_us,
//         //       Broad_Description_of_parts_imported_by_us,
//         //       Value_of_parts_imported_by_our_suppliers,
//         //       Broad_Description_of_parts_imported_by_our_suppliers,
//         //       Local_Content,
//         //       DVA,
//         //     };
//         //     return obj;
//         //   }
//         // );

//         // exportExcel(excelData);
//       })
//       .catch((error) => {
//         console.log(error)
//         setDisable(false)
//         showPopup({
//           type: MESSAGE_TYPE.FAILURE,
//           contextText: MESSAGE_TYPE.FAILURE,
//           info: `${error.message}`,
//         });
//       });
//   };
  
//   const getAllData = () => {
//     let headerss = new Headers();
//     headerss.append("Content-Type", "application/json");
//     fetch(`${baseUrl}AllSupplierDvaCollectionData/`, {
//       method: "GET",
//       headers: headerss,
      
//     }).then((response) => response.json() )
//     .then((responseJson)=>{
//       if(responseJson === null || responseJson.length === 0){
//         // setTableArray(responseJson)
//         showPopup({
//               type: MESSAGE_TYPE.FAILURE,
//               contextText: MESSAGE_TYPE.FAILURE,
//               info: "No data available",
              
//         })

//       }
//       else{
//         setTableArray(responseJson)
//         show(true)
//         setLoading(false)
//       }
      
//     }).catch((error)=>{
//       console.log('error',error)
//       showPopup({
//         type: MESSAGE_TYPE.FAILURE,
//         contextText: MESSAGE_TYPE.FAILURE,
//         info: 'Error while fetching data.',
//       })
      

//     })

//   }
//   console.log(auth.userRole)

//   const getData = () => {
//     let headerss = new Headers();
//     headerss.append("Content-Type", "application/json");
    
//     fetch(`${baseUrl}SupplierDvaCollection/`, {
//       method: "GET",
//       headers: headerss,
//     })
//     .then((response) => response.json())
//     .then((responseJson) => {
//       // Extract unique values for different fields
//       const uniqueSuppliers = [...new Set(responseJson.map(item => item.Supplier_Code))];
//       const uniqueProjects = [...new Set(responseJson.map(item => item.Project_code))];
//       const uniquePlants = [...new Set(responseJson.map(item => item.Plant_code))];
//       const uniqueQuarters = [...new Set(responseJson.map(item => item.Quarter))];
//       const uniqueYears = [...new Set(responseJson.map(item => item.Financial_year))];
//       console.log(uniqueSuppliers)
  
      
//       if(bcArray.length === 0){
//         setBcArray(uniqueSuppliers.map(value => ({ value, label: value })));
//       }
//       console.log(bcArray)
//       setProjectCodeArray(uniqueProjects.map(value => ({ value, label: value })));
//       console.log(projectCodeArray)
//       setPlantArray(uniquePlants.map(value => ({ value, label: value })));
//       setQuarterArray(uniqueQuarters.map(value => ({ value, label: value })));
//       setFyArray(uniqueYears.map(value => ({ value, label: value })));
  
//       // Extract userRole and userID from auth object
//       const { userRole, userID } = auth;
//       console.log('User Role:', userRole, 'User ID:', userID);
  
//       // Check user role to conditionally set BC state
//       if (userRole.includes("Vendor-CV") || userRole.includes("Vendor")) {
//         const singleVendor = bcArray.find(item => item.value === userID);
//         if (singleVendor) {
//           setBC(singleVendor);
//           setDisable(true); // Disable UI elements
//         }
//       } else {
//         setDisable(false); // Enable UI elements
//       }
//     })
//     .catch((error) => {
//       console.error('Error fetching data:', error);
//       // Handle error (e.g., show error message)
//     });
//   };

  

 

  

  const baseDefaultColumns = [
    {
      width: 300,
      title: "Vendor Code",
      field: "Vendor_code",
      enableSearch: true,
      enableFilter: true,
    },
    {
        width: 300,
        title: "Buyer Name",
        field: "Buyer_email",
        enableSearch: true,
        enableFilter: true,
    },
    // {
    //   width: 300,
    //   title: "AFS Mandatory",
    //   field: "AFS_mandatory",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 300,
      title: "MPFD Mandatory",
      field: "MPFD_mandatory",
      enableSearch: true,
      enableFilter: true,
    },
    // {
    //   width: 300,
    //   title: "Soft Deleted",
    //   field: "is_deleted",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
  ];

  const goNextForm = () => {
    var url;
    var urlMethod;
  
      let { userRole, userID } = auth;
 

        if(userRole.includes("Purchase") || userRole.includes("Purchase-CV")){
        url="SupplierDataaddition";
        urlMethod="POST"; 
      }
      

      const apiFormatedData = {
        VC: Bc.value,
    
  
      }
  
      var apiFormatedDataJson = JSON.stringify(apiFormatedData);
      let headerss = new Headers();
      headerss.append('Content-Type', 'application/json');
      fetch(`${baseUrl}${url}/`,
        {
          method: urlMethod,
          headers: headerss,
          body: apiFormatedDataJson
        }).then((response) => response.json())
        .then((responseJson) => { 
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: "Data is Succesfully Saved"
    
          });
          history.push('/')
  
        })
        .catch((error) => {
  
        });
  
    };

    const rowData = [ 
        {
           "Vendor_code" : "P83251",
           "Buyer_name" : "test123",
           "AFS_mandatory" : "Yes",
           "MPFD_mandatory" : "No"
        },
        {
            "Vendor_code" : "P83250",
            "Buyer_name" : "test1234",
            "AFS_mandatory" : "Yes",
            "MPFD_mandatory" : "No"
         },
         {
            "Vendor_code" : "R83255",
            "Buyer_name" : "testing",
            "AFS_mandatory" : "Yes",
            "MPFD_mandatory" : "No"
         },
         {
            "Vendor_code" : "R83279",
            "Buyer_name" : "testing12",
            "AFS_mandatory" : "Yes",
            "MPFD_mandatory" : "Yes"
         }
    
    ]
  
  

//   const redirectToCreateProjectPage = () => history.push("/");
//   const renderHTML = () => (
//     <div className={styles.formGroup}>

//     <Grid
//         container
//         spacing={1}

//         style={{ display: 'flex', justifyContent: "left"}}
//         columns={12}
//       >
//         <Grid item sm={12}  md={5} lg={4} style={{marginTop:"5px", marginBottom:'5px'}}>
//         <div className={styles.rowDiv}>
//           <div className={clsx(styles.lableDiv)}  style={{width:"40%"}}>
//               <label className={styles.label}>Project Code</label>
//             </div>
//             <div className={styles.formDiv} >
//             <CustomSelect
//               name="Project Code"
//               options={projectCodeArray}
//               // isDisabled={checkDisable}
//               className={clsx(styles.select, styles.sel1)}
//               value={projectCode}
//               isMulti={false}
//               isMandatory={auth.userRole[0] === 'Vendor' || auth.userRole[0] === 'admin'}
//               isClearable
//               onChange={(selectedOption) => {
//                 // handleSelect(e, "VC");
//                 setProjectCode(selectedOption)
//               }}
//             />
//             </div>
//             </div>
//         </Grid>
//         <Grid item sm={12}  md={5} lg={4} style={{marginTop:"5px", marginBottom:'5px'}}>
//         <div className={styles.rowDiv}>
//           <div className={clsx(styles.lableDiv)}  style={{width:"40%"}}>
//               <label className={styles.label}>Financial Year</label>
//             </div>
//             <div className={styles.formDiv} >
//             <CustomSelect
//               name="Fy"
//               options={fyArray}
//               // isDisabled={checkDisable}
//               className={clsx(styles.select, styles.sel1)}
//               value={Fy}
//               isMulti={false}
//               // isMandatory = {auth.userRole === ''}
//               isClearable
//               onChange={(selectedOption) => {
//                 // handleSelect(e, "VC");
//                 setFY(selectedOption)
//               }}
//             />
//             </div>
//             </div>
//         </Grid>
//         <Grid item xs={12} md={5} lg={4} style={{marginTop:"5px", marginBottom:'5px'}}>
//         <div className={styles.rowDiv}>
//           <div className={clsx(styles.lableDiv)} style={{width:"40%"}}>
//               <label className={styles.label}>Quarter</label>
//             </div>
//             <div className={styles.formDiv}>
//             <CustomSelect
//               name="Quarter"
//               options={quarterArray}
//               // isDisabled={checkDisable}
//               className={clsx(styles.select, styles.sel1)}
//               value={quarter}
//               isMulti={false}
//               isClearable
//               onChange={(selectedOption) => {
//                 // handleSelect(e, "VC");
//                 setQuarter(selectedOption)
//               }}
//             />
//             </div>
//             </div>
//         </Grid>
//         <Grid item md={5} lg={4} sm={12} style={{marginTop:"5px", marginBottom:'5px'}}>
//         <div className={styles.rowDiv}>
//           <div className={clsx(styles.lableDiv)} style={{width:"40%"}}>
//               <label className={styles.label}>Plant</label>
//             </div>
//             <div className={styles.formDiv}>
//             <CustomSelect
//               name="Plant"
//               options={plantArray}
//               // isDisabled={checkDisable}
//               className={clsx(styles.select, styles.sel1)}
//               value={plant}
//               isMulti={false}
//               isClearable
//               isMandatory={auth.userRole[0] === 'Vendor'}
//               onChange={(selectedOption) => {
//                 // handleSelect(e, "VC");
//                 setPlant(selectedOption)
//               }}
//             />
//             </div>
//             </div>
//         </Grid>
//         <Grid item md={5} lg={4} sm={12} style={{marginTop:"5px", marginBottom:'5px'}}>
//         <div className={styles.rowDiv}>
//           <div className={clsx(styles.lableDiv)} style={{width:"40%"}}>
//               <label className={styles.label}>Supplier</label>
//             </div>
//             <div className={styles.formDiv}>
//             <CustomSelect
//               name="bc"
//               options={bcArray}
//               isDisabled={checkDisable}
//               isMandatory={auth.userRole[0] === 'Vendor'}
//               className={clsx(styles.select, styles.sel1)}
//               value={Bc}
//               isMulti={false}
//               isClearable
//               onChange={(selectedOption) => {
//                 // handleSelect(e, "VC");
//                 setBC(selectedOption)
//               }}
//             />
//             </div>
//             </div>
//         </Grid>
//         {/* <Grid item md={2} lg={2} sm={12}>
//          */}
         
        
//         <Grid item md={5} lg={4} sm={12} style={{marginTop:"5px", marginBottom:'5px'}}>
//            <div                
//              style={{marginLeft:"5px", paddingRight:"10px",marginTop: "1px",display:"flex",justifyContent:'right'}}

         
// >

//                   <AuthChecker operation="search">
//              {isAuthorized => (
//                <Button
//                  className={clsx(
//                    styles.actionButton,
//                    styles.primaryActionButton,
//                  )}
//                  style={{marginRight:"12px !important"}}
//                  variant="primary"
//                  //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
//                  onClick={showTable}
//                   disabled={!isAuthorized}
                 
//                  data-testid="confirm-action"
//                >
//                  Show Data
//                </Button>
//              )}
   
//            </AuthChecker>
//            <AuthChecker operation="search">
//              {isAuthorized => (
//                <Button
//                  className={clsx(
//                    styles.actionButton,
//                    styles.primaryActionButton,
//                  )}
                 
//                  variant="primary"
//                  //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
//                  onClick={ () => exportExcel(tableArray)}
//                   disabled={!isAuthorized}
//                  data-testid="confirm-action"
//                  style={{marginLeft:"5px"}}
//                >
//                Download
//                </Button>
//              )}
   
//            </AuthChecker>
//            {/* <AuthChecker operation="search">
//              {isAuthorized => (
//                <Button
//                  className={clsx(
//                    styles.actionButton,
//                    styles.primaryActionButton,
//                  )}
//                  style={{marginRight:"12px !important",marginLeft:"5px"}}
//                  variant="primary"
//                  //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
//                  onClick={()=>{
//                   getAllData()
//                  }}
//                   disabled={!isAuthorized}
                 
//                  data-testid="confirm-action"
//                >
//                  Show All
//                </Button>
//              )}
   
//            </AuthChecker> */}
//            {!auth.userRole.includes('Vendor') ? (
//   <AuthChecker operation="search">
//     {isAuthorized => (
//       <Button
//         className={clsx(
//           styles.actionButton,
//           styles.primaryActionButton,
//         )}
//         style={{marginRight:"12px !important",marginLeft:"5px"}}
//         variant="primary"
//         onClick={() => {
//           getAllData();
//         }}
//         disabled={!isAuthorized}
//         data-testid="confirm-action"
//       >
//         Show All
//       </Button>
//     )}
//   </AuthChecker>
// ) : null}
 
//       {/* {
//         loader==false&&
    
//           <>
//         <AuthChecker operation="download">

//           {isAuthorized => (
//                <Button
//                  className={clsx(
//                    styles.actionButton,
//                    styles.primaryActionButton,
//                  )}
//                  variant="primary"
//                  //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
//                  onClick={()=>{

//                   if(tableArray.length>=1){exportExcel(tableArray);}
//                  }}
//                   disabled={!isAuthorized}
//                  data-testid="confirm-action"
//                  style={{marginLeft:"5px"}}

//                >
//                  download
//                </Button>
//              )}
   
//            </AuthChecker>
       
//        </> 
//     } */}


//               </div>
          
  
//         </Grid>
   
//         </Grid>
      
//     </div>
//   );

  

// console.log('tableArray',tableArray);
const uploadDocument = () => {
  var input = document.createElement("input");
  input.type = "file";
  input.id = "file";
  input.setAttribute("accept", ".xlsx");
  input.click();
 
  
  input.onchange = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    // const url = auth.userRole[0] === 'Vendor' ? 'SupplierDVAdataUpload' : 'SupplierDvaCollectionAdminGDC'
    // fetch(`${baseUrl}${url}/`, {
      fetch(`${baseUrl}BuyerMasterAfsMpfdData/`, {
      method: "PUT",
      body: formData,
  })
  .then((response) => {
      console.log(response);
      // return response.json(); // Parse JSON response
      if(response.ok){
        return response.json()
      }
      if(response.status === 400){
        return response.json().then(data => {
          console.log(data)
          const error = data.status.match(/="(.*?)"/)[1];
          throw new Error(error || 'Local Content does not match the calculated value');
      });

      }if(response.status === 500){
        return response.json().then(data => {
          console.log(data)
          throw new Error(data.status);
      });

      }
      return response.json().then(data => {
        console.log(data)
        throw new Error(data.statusText);
    });

  })
  .then((myResponse) => {
      console.log('MyResponse', myResponse);
      showPopup({
                type: MESSAGE_TYPE.SUCCESS,
                contextText: MESSAGE_TYPE.SUCCESS,
                info: "Document Uploaded Successfully"
            });

      getData() 
  })
  .catch((error) => {
      showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Error : ${error.message}`,
      });
  });
  };

}

const secondaryActions = [
    {
      name: "Upload",
      authOperation: "upload",
      shouldEnable: (selected) => true,
      // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor')  ,
      actionFn: (selected) => {
        // handleOpen();
        uploadDocument()
      },
      customClass: "",
    },
    {
      name: "Download",
      authOperation: "download",
      shouldEnable: (selected) => true,
      // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor')  ,
      actionFn: (selected) => {
        // downloadFile();
        exportExcel(tableData)
      },
      customClass: "",
    },
    

  ]

  const primaryActions = [
    {
      name: "Submit Data",
      authOperation: "submit",
      shouldEnable: () => true,
      actionFn: handleOpenFormModal,
    }

  ]

  return (
    <>
      <div className={styles.container} style={{ boxSizing: "border-box" }}>
        <div>
          <div className={styles.backButtonLastRefreshedDateWrapper}  style={{ marginTop: '10px' }}>
            <BackButton
              action="Back"
              handleClick={redirectToApplicationLandingPage}
              style={{ marginBottom: '0px' }}
            />
              <div
              style={{ width: '100%' }}
              // style={{ display: 'flex', flexDirection: 'row-reverse' }}
            >
              <div className={styles.topDiv}>
            <div className={styles.processHeader} style={{ display: 'flex', height: '3em' }}>
              <div style={{ width: "100%" }}>
                <CustomTab title="AFS and MPFD" withOutCount isSelected />
              </div>
              {/* <StepBar1 enable={5} /> */}

            </div>
          </div>
            </div>
              
              
          </div>
          
          
        </div>
        {/* <div>
          <div className={styles.formContainer}>
            <CustomFormGroup  body={renderHTML()} />
          </div>
        </div> */}
        {/* {flag && ( */}
          <div
            className={styles.tableView}
            style={{ height: "86%", padding: "0 2em" }}
          >
            <Table
              // columns={[...baseDefaultColumns]}
              // defaultColumns={[...baseDefaultColumns]}
              columns={baseDefaultColumns}
              defaultColumns={baseDefaultColumns}
              rowHeight={38}
              rows={tableData}
              primaryAction={[]}
              secondaryActions={secondaryActions}
              setAsnCount={setCount}
            //   isDataLoading={loader}
              actionButtons
              onRowSelect={(e) => {}}
              removeRowSelection
            />
          </div>
        {/* )} */}
      </div>
      {/* <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            // onClick={onDelete}
          >
            Yes
          </Button> */}
               <ResetModalF
        isPopupOpen={isPopupOpenFormModal}
        handleCloseFormModal={handleCloseFormModal}
        goNextForm={goNextForm}
      />

      <ResetModal
        isPopupOpen={isPopupOpen}
        handleClose={handleClose}
        goNext={goNext}
      />
      
    </>
  );
};
function ResetModalF({ isPopupOpen, handleCloseFormModal, goNextForm }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>Confirm Submit</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation, data will be submitted</p>

            <p>Are you sure ?</p>

          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleCloseFormModal}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleCloseFormModal()
              goNextForm()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function ResetModal({ isPopupOpen, handleClose, goNext }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: "18px", width: "18px" }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>
              Confirm file Upload
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p>
              {" "}
             
            On confirmation ,Records will be appended.
            </p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleClose();
              goNext();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}
ResetModalF.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleCloseFormModal: PropTypes.func.isRequired,
  goNextForm: PropTypes.func.isRequired,
};

ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
};

export default withAllowedOperationsProvider(MPFDMaster, RESOURCE_TYPE.PROJECT);
